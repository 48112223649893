import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../../components/Sidebar';
import Header from '../../../components/Header';
import HCForm from '../../../components/hc/HCForm';
import ScenariosTable from '../../../components/hc/ScenariosTable'; // Import de la table

const HCScenario = () => {
    const [scenarios, setScenarios] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        budget: '',
        reuseOpenPositions: false,
    });
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchScenarios = async () => {
            const apiUrl = process.env.REACT_APP_API_URL;
            const whatsNewUrl = `${apiUrl}/graphql/`;
            const token = localStorage.getItem('token');
            const userData = JSON.parse(localStorage.getItem('userData'));
            const entrepriseId = userData?.entreprise_id;

            if (!entrepriseId) {
                console.error('Entreprise ID not found in userData');
                return;
            }

            const query = `
                query ($entrepriseId: Int!) {
                    scenarios(entrepriseId: $entrepriseId) {
                        id
                        name
                        futureOpenPositionsCount
                        budget
                    }
                }
            `;

            const variables = {
                entrepriseId: entrepriseId,
            };

            try {
                const response = await fetch(whatsNewUrl, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Token ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ query, variables }),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const responseData = await response.json();

                if (responseData.errors) {
                    console.error('GraphQL errors:', responseData.errors);
                    return;
                }

                setScenarios(responseData.data.scenarios);
            } catch (error) {
                console.error('Error fetching scenarios:', error);
            }
        };

        fetchScenarios();
    }, []);

    const handleCreateScenario = async (e) => {
        e.preventDefault();

        const apiUrl = process.env.REACT_APP_API_URL;
        const graphqlUrl = `${apiUrl}/graphql/`;
        const token = localStorage.getItem('token');
        const userData = JSON.parse(localStorage.getItem('userData'));
        const entrepriseId = userData?.entreprise_id;

        if (!entrepriseId) {
            setMessage('Entreprise ID is missing.');
            return;
        }

        const mutation = `
            mutation CreateScenario($name: String!, $budget: Decimal!, $entrepriseId: Int!) {
                createScenario(entrepriseId: $entrepriseId, name: $name, budget: $budget) {
                    scenario {
                        id
                        name
                        budget
                        entreprise {
                            id
                        }
                    }
                }
            }
        `;

        const variables = {
            name: formData.name,
            budget: parseFloat(formData.budget), // Assurez-vous que le budget est un nombre valide
            entrepriseId: entrepriseId,
        };

        try {
            const response = await fetch(graphqlUrl, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ query: mutation, variables }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const responseData = await response.json();

            if (responseData.errors) {
                console.error('GraphQL errors:', responseData.errors);
                setMessage('Error creating scenario.');
                return;
            }

            const newScenario = responseData.data.createScenario.scenario;

            setScenarios((prevScenarios) => [...prevScenarios, newScenario]);
            setMessage('Scenario created successfully!');
            setIsModalOpen(false); // Fermer le modal après la création
            setFormData({
                name: '',
                budget: '',
                reuseOpenPositions: false,
            });

            // Redirection vers le nouveau scénario
            navigate(`/headcount/${newScenario.id}`);
        } catch (error) {
            console.error('Error creating scenario:', error);
            setMessage('An error occurred while creating the scenario.');
        }
    };

    const handleScenarioDelete = (ids) => {
        setScenarios((prevScenarios) =>
            prevScenarios.filter((scenario) => !ids.includes(scenario.id))
        );

        // Ajouter une suppression côté serveur ici
        console.log('Scenarios to delete:', ids);
    };

    return (
        <div className="container-home">
            <Sidebar />
            <div className="container-right">
                <Header />
                <div className="line-container-10">
                    <div className="hc-display-line">
                        <h5>Headcount Scenarios</h5>
                        <button
                            type="submit"
                            className="create-scenario-button"
                            onClick={() => setIsModalOpen(true)}
                        >
                            Create Scenario
                        </button>
                    </div>

                    <ScenariosTable
                        scenarios={scenarios}
                        onDeleteScenarios={handleScenarioDelete}
                    />
                </div>
            </div>
            {isModalOpen && (
                <HCForm
                    name={formData.name}
                    budget={formData.budget}
                    reuseOpenPositions={formData.reuseOpenPositions}
                    onInputChange={(e) =>
                        setFormData({ ...formData, [e.target.name]: e.target.value })
                    }
                    onCheckboxChange={(e) =>
                        setFormData({ ...formData, [e.target.name]: e.target.checked })
                    }
                    onSubmit={handleCreateScenario}
                    message={message}
                    onClose={() => setIsModalOpen(false)}
                />
            )}
        </div>
    );
};

export default HCScenario;
