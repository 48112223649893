import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './pages/App';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import Modal from 'react-modal';

// Importations spécifiques à Chart.js
import { Chart, ArcElement, LineElement, BarElement, DoughnutController, LinearScale, CategoryScale, PointElement } from 'chart.js';

// Enregistrement global des éléments et échelles utilisés
Chart.register(ArcElement, LineElement, BarElement, DoughnutController, LinearScale, CategoryScale, PointElement);

Sentry.init({
  dsn: 'https://a964fd5c7dc0e35024520ef4fa6864ef@o4506694348636160.ingest.sentry.io/4506694893633536',
  integrations: [
    new Integrations.BrowserTracing(),
  ],
  tracesSampleRate: 1.0,
});

// Assurez-vous que Modal est lié à l'élément root pour l'accessibilité
Modal.setAppElement('#root');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //<React.StrictMode>
    <App />
  //</React.StrictMode>
);

// Log personnalisé pour capturer une exception
try {
  // Votre code susceptible de générer une erreur
} catch (error) {
  Sentry.captureException(error);
}
