import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
const CreateAccount = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [companyId, setCompanyId] = useState(''); // Ajout pour stocker l'ID de l'entreprise
  const location = useLocation();

  useEffect(() => {
    // Extraire et valider le token de l'URL
    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        if (decodedToken && decodedToken.company_id) {
          setCompanyId(decodedToken.company_id);
          setEmail(decodedToken.email); // Optionnel : Préremplir l'email si présent dans le token
        }
      } catch (error) {
        console.error('Erreur de décodage du token:', error);
        alert('Le lien d\'invitation est invalide ou a expiré.');
      }
    }
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const data = {
      username,
      email,
      password,
      entreprise: companyId, // Inclure l'ID de l'entreprise dans la requête
    };

    try {
      const response = await fetch('https://back.polare.fr/api/signup/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      });
  
      if (response.ok) {
        alert('Compte créé avec succès.');
        // Redirection ou mise à jour de l'état ici
      } else {
        alert('Erreur lors de la création du compte.');
      }
    } catch (error) {
      console.error('Erreur lors de la création du compte:', error);
      alert('Une erreur est survenue lors de la création du compte.');
    }
  };

  return (
<div className='container-center padding-100'>
      <h4>Créer un Compte</h4>
      <form className='form-column' onSubmit={handleSubmit}>
        <label className='label-column'>
          Nom d'utilisateur
          <input className='form-input' type="text" value={username} onChange={(e) => setUsername(e.target.value)} required />
        </label>
        <label className='label-column'>
          Email
          <input className='form-input' type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        </label>
        <label className='label-column'>
          Mot de passe
          <input className='form-input' type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
        </label>
        <button type="submit">Créer le Compte</button>
      </form>
    </div>
  );
}

export default CreateAccount;
