import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'; // Import de l'icône
import '../../styles/ButtonSidebar.css';

const ButtonReset = ({ isCollapsed }) => {
  const handleReset = () => {
    const token = localStorage.getItem('token');
    localStorage.clear();
    if (token) {
      localStorage.setItem('token', token);
    }
    alert('Local data has been reset. Please refresh the page.');
    window.location.reload();
  };

  return (
    <button onClick={handleReset} className="btn-secondary">
      {isCollapsed ? (
        <FontAwesomeIcon icon={faSyncAlt} />
      ) : (
        <>
          <FontAwesomeIcon icon={faSyncAlt} /> Reset Data
        </>
      )}
    </button>
  );
};

export default ButtonReset;
