import React from 'react';
import { Routes, Route } from 'react-router-dom';
import CreateEmployes from '../../pages/creation_compte/CreateEmployes';
import ReloadData from '../../pages/creation_compte/ReloadData';


const RoutesCreateCSV = () => (
  <Routes>



        <Route path="company" element={<CreateEmployes/>}/>
        <Route path="company/reload" element={<ReloadData/>}/>

  </Routes>
);

export default RoutesCreateCSV;
