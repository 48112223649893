import React, { useState } from 'react';
import '../../styles/Hover.css';

const HoverInfoCard = ({ children, infoText, icon, color = '#000' }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="hover-info-container"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Contenu de l'enfant */}
      {children}

      {/* Carte flottante avec effet d'apparition */}
      <div
        className={`fade-in ${isHovered ? 'show' : ''}`}
        style={{ color: color }} // Couleur de l'icône
      >

        <span className="hover-text">{infoText}</span>
      </div>
    </div>
  );
};

export default HoverInfoCard;
