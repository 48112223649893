import React , { useState } from 'react';
import colors from '../../assets/colors.js';


const PasswordChange = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleFormSubmit = (e) => {
        e.preventDefault();
        // Logique pour mettre à jour le mot de passe
    };

    return (
        <form onSubmit={handleFormSubmit}>
            <label className="label-container">
                <span className="label-title">Mot de passe</span>
                <input className="form-input" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </label>
            <br />
            <label className="label-container">
                <span className="label-title">Confirmer le mot de passe</span>
                <input className="form-input" type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
            </label>
            <br />
            <button type="submit">Valider</button>
        </form>
    );
}

export default PasswordChange;
