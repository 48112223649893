import React, { useState, useEffect } from 'react';
import Sidebar from "../components/Sidebar";
import colors from '../assets/colors';
import Header from "../components/Header";
import "../styles/Home.css";




const Unauthorized = () => {


    return (
        <div style={{ backgroundColor: colors.background }} className='container-home'>
            <Sidebar />
            <div className="container-right">
                <Header />
                <div className='line-container-10'>
                <h5>Unauthorized</h5>
                
            </div>
           
                
        </div> 
        </div>
    );
};

export default Unauthorized;

