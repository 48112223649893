import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import "../../styles/Home.css";
import "../../styles/Card.css";
import { useParams } from 'react-router-dom';
const ImportPerf = () => {
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccessful, setIsSuccessful] = useState(false);
    const { reviewId } = useParams();
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
        setIsSuccessful(false); // Reset success state on file change
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (!file) {
            return;
        }

        setIsLoading(true);

        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append('file', file);

            const response = await fetch(`${apiUrl}/api/perf/upload/${reviewId}/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${token}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            setIsSuccessful(true);
        } catch (error) {
            console.error('There was an error uploading the file:', error);
            setIsSuccessful(false);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className='card-full'>
            <h5>Ajoutez vos données pour la review : </h5>
            <form className="form-column" onSubmit={handleFormSubmit}>
                <div className="form-group">
                    {isLoading ? null :
                        isSuccessful ? null : (
                            <input 
                                type='file' 
                                accept='.csv' 
                                onChange={handleFileChange} 
                                disabled={isLoading}
                            />
                        )}
                    {isLoading ? (
                        <FontAwesomeIcon icon={faSpinner} className="spinner-icon" spin />
                    ) : isSuccessful ? (
                        <FontAwesomeIcon icon={faCheckCircle} className="success-icon" />
                    ) : null}
                </div>
                {isLoading ? null :
                    isSuccessful ? null : (
                        <button type='submit' disabled={isLoading}>Upload</button>
                    )}
            </form>
        </div>
    );
};

export default ImportPerf;
