import React, { useState, useEffect } from 'react';
import Sidebar from "../../components/Sidebar";
import colors from '../../assets/colors';
import Header from "../../components/Header";
import "../../styles/Home.css";
import "../../styles/Card.css";
import Tableau from "../../components/recrutement/Tableau";

const DBEmploye = () => {
    const [db, setDb] = useState([]);
    const [visibleColumns, setVisibleColumns] = useState([
        'first_name', 'last_name', 'equipe', 'anciennete', 'manager_name'
    ]);
    const [sortConfig, setSortConfig] = useState(null);

    useEffect(() => {
        fetchDB();
    }, []);

    const fetchDB = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            const response = await fetch(`${apiUrl}/api/allemploye/`, {
                headers: { 'Authorization': `Token ${token}` },
            });

            if (!response.ok) {
                throw new Error('Erreur de réseau ou réponse non valide');
            }

            const data = await response.json();
            // Adaptation des données pour correspondre aux colonnes du tableau
            const formattedData = data.map((employe) => ({
                id: employe.id,
                first_name: employe.first_name,
                last_name: employe.last_name,
                equipe: employe.equipe ? employe.equipe : "Non spécifié",
                anciennete: employe.anciennete ? employe.anciennete : "0",
                manager_name: employe.manager ? `${employe.manager.first_name} ${employe.manager.last_name}` : "Non spécifié"
            }));
            setDb(formattedData);
        } catch (error) {
            console.error("Erreur lors de la récupération des données:", error);
        }
    };

    const allColumns = [
        { key: 'first_name', label: 'Prénom' },
        { key: 'last_name', label: 'Nom' },
        { key: 'equipe', label: 'Équipe' },
        { key: 'anciennete', label: 'Ancienneté (mois)' },
        { key: 'manager_name', label: 'Manager' }
    ];

    const handleVisibleColumnsChange = (newVisibleColumns) => {
        setVisibleColumns(newVisibleColumns);
    };

    const handleSort = (sortConfig) => {
        setSortConfig(sortConfig);
        // Ici vous pouvez ajouter une logique de tri basée sur `sortConfig` si nécessaire
    };

    const handleRowClick = (rowData) => {
        console.log('Row clicked:', rowData);
        // Ajoutez ici la logique pour gérer le clic sur une ligne, par exemple afficher les détails d'un employé
    };

    return (
        <div style={{ backgroundColor: colors.background }} className='container-home'>
            <Sidebar />
            <div className="container-right">
                <Header />
                <div className='line-container-10'></div>
                <div className='cards-container'>
                    {db.length === 0 ? (
                        <p>Aucun employé trouvé.</p>
                    ) : (
                        <Tableau
                            data={db}
                            columns={allColumns}
                            visibleColumns={visibleColumns}
                            setVisibleColumns={handleVisibleColumnsChange}
                            sortConfig={sortConfig}
                            onSort={handleSort}
                            onRowClick={handleRowClick}
                        />

                    )}
                </div>
            </div>
        </div>
    );
};

export default DBEmploye;
