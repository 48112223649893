// UpdatePositionForm.js

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import amplitude from 'amplitude-js';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import HoverInfoCard from '../cards/HoverInfoCard';

Modal.setAppElement('#root'); // Assurez-vous de lier le modal à votre élément racine

const UpdatePositionForm = ({
    selectedPost,
    updateInfo,
    setUpdateInfo,
    handleSelectChange,
    handleFormSubmit,
    handleBackClick,
    handleDelete
}) => {
    let { id } = useParams();
    const [expandedSections, setExpandedSections] = useState({
        general: true,
        salary: false,
        dates: false,
        additional: false,
        employee: false,
    });

    const [managers, setManagers] = useState([]);
    const [recruiters, setRecruiters] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(!updateInfo.employe_hired_name); // Initialiser en fonction de employe_hired_name

    const storedUserData = localStorage.getItem('userData');
    const ampData = storedUserData ? JSON.parse(storedUserData) : {};

    useEffect(() => {
        // Si employe_hired_name existe, on n'est pas en mode édition
        if (updateInfo.employe_hired_name) {
            setIsEditing(false);
        } else {
            setIsEditing(true);
        }
    }, [updateInfo.employe_hired_name]);

    useEffect(() => {
        // Vérifier si l'ID est disponible, sinon utiliser selectedPost
        let teamId = id;
        if (!teamId && selectedPost) {
            console.log('ID manquant, récupération des données depuis selectedPost.');
            teamId = selectedPost.team_id;
        }

        if (!teamId) {
            console.warn('ID manquant, impossible de procéder à la récupération des données.');
            return;
        }

        const fetchManagersRecruiters = async () => {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(`${apiUrl}/api/managers_recruiters/`, {
                    headers: {
                        'Authorization': `Token ${token}`,
                    },
                });
                const data = await response.json();
                if (response.ok) {
                    const team = data.teams.find(team => team.id === parseInt(teamId));
                    if (team) {
                        setManagers(team.managers);
                        setRecruiters(team.recruiters);


                        if (selectedPost) {
                            setUpdateInfo({
                                job: selectedPost.job,
                                status: selectedPost.status,
                                salary_min: selectedPost.salary_min,
                                salary_max: selectedPost.salary_max,
                                arrival_month: selectedPost.arrival_month,
                                location: selectedPost.location,
                                contact_manager: selectedPost.contact_manager,
                                recruiter: selectedPost.recruiter,
                                job_opening_date: selectedPost.job_opening_date,
                                contract: selectedPost.contract,
                                agency: selectedPost.agency,
                                priority: selectedPost.priority,
                                comments: selectedPost.comments,
                                FORECAST_job_opening_date: selectedPost.FORECAST_job_opening_date,
                                FORECAST_arrival_month: selectedPost.FORECAST_arrival_month,
                                exact_arrival_date: selectedPost.exact_arrival_date,
                                employe_hired: selectedPost.employe_hired,
                                future_employee_email: selectedPost.future_employee_email,
                                employe_hired_name: selectedPost.employe_hired_name,
                            });
                        }
                    } else {
                        console.warn('No team found with the given ID:', teamId);
                    }
                } else {
                    console.error('Error fetching data:', data.error);
                }
            } catch (error) {
                console.error('Failed to fetch managers and recruiters:', error);
            }
        };

        const fetchEmployees = async () => {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            try {
                const response = await fetch(`${apiUrl}/api/allemploye/`, {
                    headers: {
                        'Authorization': `Token ${token}`,
                    },
                });
                const data = await response.json();
                if (response.ok) {
                    setEmployees(data);
                } else {
                    console.error('Error fetching employees:', data.error);
                }
            } catch (error) {
                console.error('Failed to fetch employees:', error);
            }
        };

        if (teamId) {
            fetchManagersRecruiters();
            fetchEmployees();
        }
    }, [id, selectedPost, setUpdateInfo]);

    const toggleSection = (section) => {
        setExpandedSections({
            ...expandedSections,
            [section]: !expandedSections[section]
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleFormSubmit(e);
        amplitude.getInstance().logEvent('OpenPositionUpdated', {
            succeed: true,
            team: id || 'unknown',
            job: updateInfo.job,
            status: updateInfo.status,
            username: ampData.username || 'unknown',
        });
    };

    // Fonction pour basculer en mode édition
    const handleEditClick = () => {
        setIsEditing(true);
    };

    // Fonction de gestion du changement de sélection
    const handleChange = (event) => {
        handleSelectChange(event);
        const employeeId = parseInt(event.target.value, 10);
        const employee = employees.find(e => e.id === employeeId);

        if (employee) {
            setUpdateInfo(prevInfo => ({
                ...prevInfo,
                employe_hired_name: `${employee.first_name} ${employee.last_name}`,
            }));
            setIsEditing(false);
        } else {
            setUpdateInfo(prevInfo => ({
                ...prevInfo,
                employe_hired_name: '',
            }));
            setIsEditing(true);
        }
    };

    const handleDeleteClick = () => {
        setIsModalOpen(true);
        amplitude.getInstance().logEvent('OpenPositionDelete ModalOpened', {
            team: id || 'unknown',
            job: updateInfo.job,
            status: updateInfo.status,
            username: ampData.username || 'unknown',
        });
    };

    const handleConfirmDelete = () => {
        handleDelete(selectedPost.id);
        setIsModalOpen(false);
        amplitude.getInstance().logEvent('OpenPositionDeleted', {
            team: id || 'unknown',
            job: updateInfo.job,
            status: updateInfo.status,
            username: ampData.username || 'unknown',
        });
    };

    const handleCancelDelete = () => {
        setIsModalOpen(false);
    };

    return (
        <div className='card-half-full'>
            <div className='header-left'>
                <button onClick={handleBackClick} className="icon-button">
                    <FontAwesomeIcon icon={faArrowLeft} /> Retour
                </button>
            </div>
            <h4>Update Position</h4>
            <form className="perf-form-body" onSubmit={handleSubmit}>
                {/* Section Général */}
                <div className={expandedSections.general ? 'perf-form-section' : 'perf-form-section clickable'} onClick={!expandedSections.general ? () => toggleSection('general') : null}>
                    <h5 
                        className={expandedSections.general ? 'clickable' : ''} 
                        onClick={expandedSections.general ? () => toggleSection('general') : null}
                    >
                        General Information
                    </h5>
                    {expandedSections.general && (
                        <>
                            <div className='perf-form-element'>
                                <label className='perf-form-element-label'>Intitulé du poste</label>
                                <input
                                    className='perf-form-input'
                                    type="text"
                                    name="job"
                                    value={updateInfo.job || ''}
                                    onChange={handleSelectChange}
                                    required
                                />
                            </div>
                            <div className='perf-form-element'>
                                <label className='perf-form-element-label'>Statut</label>
                                <select
                                    className='perf-form-input'
                                    name="status"
                                    value={updateInfo.status || ''}
                                    onChange={handleSelectChange}
                                    required
                                >
                                    <option value="0.Not Started">0.Not Started</option>
                                    <option value="1.Job Opened">1.Job Opened</option>
                                    <option value="6.Offer Accepted">6.Offer Accepted</option>
                                    <option value="7.Arrived">7.Arrived</option>
                                </select>
                            </div>
                            <div className='perf-form-element'>
                                <label className='perf-form-element-label'>Lieu</label>
                                <input
                                    className='perf-form-input'
                                    type="text"
                                    name="location"
                                    value={updateInfo.location || ''}
                                    onChange={handleSelectChange}
                                />
                            </div>
                            <div className='perf-form-element'>
                                <label className='perf-form-element-label'>Manager</label>
                                <select
                                    className='perf-form-input'
                                    name="contact_manager"
                                    value={updateInfo.contact_manager || ''}
                                    onChange={handleSelectChange}
                                >
                                    <option value="">Select a hiring manager</option>
                                    {managers.map(manager => (
                                        <option key={manager.id} value={manager.id}>{manager.name || 'Manager Sans Nom'}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='perf-form-element'>
                                <label className='perf-form-element-label'>Recruiter</label>
                                <select
                                    className='perf-form-input'
                                    name="recruiter"
                                    value={updateInfo.recruiter || ''}
                                    onChange={handleSelectChange}
                                >
                                    <option value="">Select a recruiter</option>
                                    {recruiters.map(recruiter => (
                                        <option key={recruiter.id} value={recruiter.id}>{recruiter.name || 'Recruteur Sans Nom'}</option>
                                    ))}
                                </select>
                            </div>
                        </>
                    )}
                </div>

                {/* Section Salaire */}
                <div className='perf-form-separator'></div>
                <div className={expandedSections.salary ? 'perf-form-section' : 'perf-form-section clickable'} onClick={!expandedSections.salary ? () => toggleSection('salary') : null}>
                    <h5 
                        className={expandedSections.salary ? 'clickable' : ''} 
                        onClick={expandedSections.salary ? () => toggleSection('salary') : null}
                    >
                        Salary Information
                    </h5>
                    {expandedSections.salary && (
                        <>
                            <div className='perf-form-element'>
                                <label className='perf-form-element-label'>Minimum Salary</label>
                                <input
                                    className='perf-form-input'
                                    type="number"
                                    name="salary_min"
                                    value={updateInfo.salary_min || ''}
                                    onChange={handleSelectChange}
                                />
                            </div>
                            <div className='perf-form-element'>
                                <label className='perf-form-element-label'>Maximum Salary</label>
                                <input
                                    className='perf-form-input'
                                    type="number"
                                    name="salary_max"
                                    value={updateInfo.salary_max || ''}
                                    onChange={handleSelectChange}
                                />
                            </div>
                        </>
                    )}
                </div>

                {/* Section Dates */}
                <div className='perf-form-separator'></div>
                <div className={expandedSections.dates ? 'perf-form-section' : 'perf-form-section clickable'} onClick={!expandedSections.dates ? () => toggleSection('dates') : null}>
                    <h5 
                        className={expandedSections.dates ? 'clickable' : ''} 
                        onClick={expandedSections.dates ? () => toggleSection('dates') : null}
                    >
                        Dates
                    </h5>
                    {expandedSections.dates && (
                        <>
                                                        <div className='perf-form-element'>
                                <label className='perf-form-element-label'>Month of arrival</label>
                                <input
                                    className='perf-form-input'
                                    type="date"
                                    name="arrival_month"
                                    value={updateInfo.arrival_month || ''}
                                    onChange={handleSelectChange}
                                />
                            </div>
                            <div className='perf-form-element'>
                                <label className='perf-form-element-label'>Exact Arrival Date</label>
                                <input
                                    className='perf-form-input'
                                    type="date"
                                    name="exact_arrival_date"
                                    value={updateInfo.exact_arrival_date || ''}
                                    onChange={handleSelectChange}
                                />
                            </div>
                            <div className='perf-form-element'>
                                <label className='perf-form-element-label'>Job Opening Date</label>
                                <input
                                    className='perf-form-input'
                                    type="date"
                                    name="job_opening_date"
                                    value={updateInfo.job_opening_date || ''}
                                    onChange={handleSelectChange}
                                />
                            </div>
                            <div className='perf-form-separator'></div>
                            <div className='perf-form-element'>
                                <label className='perf-form-element-label'>FORECAST - Month of arrival</label>
                                <input
                                    className='perf-form-input'
                                    type="date"
                                    name="FORECAST_arrival_month"
                                    value={updateInfo.FORECAST_arrival_month || ''}
                                    onChange={handleSelectChange}
                                />
                            </div>
                            <div className='perf-form-element'>
                                <label className='perf-form-element-label'>FORECAST - Job Opening Date</label>
                                <input
                                    className='perf-form-input'
                                    type="date"
                                    name="FORECAST_job_opening_date"
                                    value={updateInfo.FORECAST_job_opening_date || ''}
                                    onChange={handleSelectChange}
                                />
                            </div>
                        </>
                    )}
                </div>

                {/* Section Informations Additionnelles */}
                <div className='perf-form-separator'></div>
                <div className={expandedSections.additional ? 'perf-form-section' : 'perf-form-section clickable'} onClick={!expandedSections.additional ? () => toggleSection('additional') : null}>
                    <h5 
                        className={expandedSections.additional ? 'clickable' : ''} 
                        onClick={expandedSections.additional ? () => toggleSection('additional') : null}
                    >
                        Additional Information
                    </h5>
                    {expandedSections.additional && (
                        <>
                                                   <div className='perf-form-element'>
                                <label className='perf-form-element-label'>Contract</label>
                                <input
                                    className='perf-form-input'
                                    type="text"
                                    name="contract"
                                    value={updateInfo.contract || ''}
                                    onChange={handleSelectChange}
                                />
                            </div>
                            <div className='perf-form-element'>
                                <label className='perf-form-element-label'>Agency Involved</label>
                                <input
                                    className='perf-form-input'
                                    type="checkbox"
                                    name="agency"
                                    checked={updateInfo.agency}
                                    onChange={handleSelectChange}
                                />
                            </div>
                            <div className='perf-form-element'>
                                <label className='perf-form-element-label'>Priority</label>
                                <input
                                    className='perf-form-input'
                                    type="number"
                                    name="priority"
                                    value={updateInfo.priority || ''}
                                    onChange={handleSelectChange}
                                />
                            </div>
                            <div className='perf-form-element'>
                                <label className='perf-form-element-label'>Comments</label>
                                <input
                                    className='perf-form-input'
                                    type="text"
                                    name="comments"
                                    value={updateInfo.comments || ''}
                                    onChange={handleSelectChange}
                                />
                            </div>
                        </>
                    )}
                </div>

                {/* Section pour assigner un employé */}
                <div className='perf-form-separator'></div>
               
                <div className={expandedSections.employee ? 'perf-form-section' : 'perf-form-section clickable'} onClick={!expandedSections.employee ? () => toggleSection('employee') : null}>



  
                            <h5 
                                className={expandedSections.employee ? 'clickable' : ''} 
                                onClick={expandedSections.employee ? () => toggleSection('employee') : null}
                            >
                                Who is your new employee ?
                            </h5>

                    {expandedSections.employee && (
                        <>
                            <div className='perf-form-element'>
                                <label className='perf-form-element-label'>Already in  your SIRH ?</label>
                                {isEditing ? (
                                    <select
                                        className='perf-form-input'
                                        name="employe_hired"
                                        value={updateInfo.employe_hired || ''}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select an employee</option>
                                        {Array.isArray(employees) && employees.length > 0 ? (
                                            employees.map(employee => (
                                                <option key={employee.id} value={employee.id}>
                                                    {`${employee.first_name} ${employee.last_name}` || 'Employé Sans Nom'}
                                                </option>
                                            ))
                                        ) : (
                                            <option value="">No employees available</option>
                                        )}
                                    </select>
                                ) : (
                                    <div onClick={handleEditClick} className="clickable-text">
                                        {updateInfo.employe_hired_name || 'Sélectionnez un employé'}
                                    </div>
                                )}
                            </div>
                            <div className='perf-form-element'>
                                <label className='perf-form-element-label'>Or write the futur email here</label>
                                <input
                                    className='perf-form-input'
                                    type="email"
                                    name="future_employee_email"
                                    value={updateInfo.future_employee_email || ''}
                                    onChange={handleSelectChange}
                                />
                            </div>
                        </>
                    )}
                </div>

                {/* Boutons du formulaire */}
                <div className='perf-form-btn'>
                    <button type="submit">Update</button>
                    <button type="button" className='btn-danger' onClick={handleDeleteClick}>Delete</button>
                </div>
            </form>

            {/* Modal de confirmation de suppression */}
            <Modal
                isOpen={isModalOpen}
                onRequestClose={handleCancelDelete}
                contentLabel="Confirmer la suppression"
                className="modal-form"
                overlayClassName="overlay"
            >
                <div className='form-column'>
                    <h4>Confirm Delete</h4>
                    <p>Are you sure you want to delete this position?</p>
                    <div className='display-line'>
                        <button type="submit" onClick={handleConfirmDelete} className="btn-danger">Supprimer</button>
                        <button onClick={handleCancelDelete} className="btn-secondary">Annuler</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default UpdatePositionForm;
