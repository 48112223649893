import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, Filler } from 'chart.js';
import HomeCards from '../cards/HomeCards'; // Assurez-vous que le chemin est correct
import { useNavigate } from 'react-router-dom';
// Configuration nécessaire pour ChartJS
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, Filler);

const EffectifNumber = ({ showRightHeader }) => {
    const [effectifData, setEffectifData] = useState([]);
    const [lastRefreshDate, setLastRefreshDate] = useState('');
    const [latestEffectif, setLatestEffectif] = useState('Chargement...');
    const navigate = useNavigate();

    const handleOnClick = () => {
      navigate('/graph/effectifvolume'); 
     };
    const sampleData = (data, samplesCount) => {
        if (data.length <= samplesCount) return data;

        const sampledData = [];
        const segmentSize = Math.ceil(data.length / samplesCount);

        for (let i = 0; i < samplesCount; i++) {
            const index = i * segmentSize;
            if (index < data.length) {
                sampledData.push(data[index]);
            }
        }

        return sampledData;
    };

    const fetchData = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');

            const response = await fetch(`${apiUrl}/api/effectif-entreprise/`, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la récupération des données d\'effectif');
            }

            const data = await response.json();
            const now = new Date().toISOString();
            const dataToStore = {
                data: data,
                lastUpdated: now
            };

            localStorage.setItem('effectifData', JSON.stringify(dataToStore));
            setEffectifData(data);
            setLastRefreshDate(now);
            // Mettre à jour l'effectif le plus récent
            if (data.length > 0) {
                setLatestEffectif(`${data[data.length - 1].effectif} salariés`);
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des données d\'effectif', error);
        }
    };

    useEffect(() => {
        const storedData = localStorage.getItem('effectifData');
        if (storedData) {
            const { data, lastUpdated } = JSON.parse(storedData);
            setEffectifData(data);
            setLastRefreshDate(lastUpdated);
            // Mettre à jour l'effectif le plus récent lors de l'utilisation des données stockées
            if (data.length > 0) {
                setLatestEffectif(`${data[data.length - 1].effectif} salariés`);
            }
        } else {
            fetchData();
        }
    }, []);

    const chartData = {
        labels: sampleData(effectifData, 20).map(item => item.date),
        datasets: [{
            label: 'Effectif',
            data: sampleData(effectifData, 20).map(item => item.effectif),
            fill: true,
            backgroundColor: '#EBA49B40', 
            borderColor: '#EBA49B',
            tension: 0.1
        }]
    };
    const chartOptions = {
        scales: {
          y: {
            display: false,
            grid: {
              drawBorder: false,
              display: false,
            },
            ticks: {
              display: false,
            }
          },
          x: {
            display: false,
            grid: {
              drawBorder: false,
              display: false,
            },
            ticks: {
              display: false,
            }
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        elements: {
          point: {
            radius: 0, // Toujours masquer les points
            hoverRadius: 0, // Masquer les points même lors du survol
          },
          line: {
            borderWidth: 3, // Ajustez si nécessaire
          }
        },
        maintainAspectRatio: false,
      
      };
      
      return (
        <HomeCards 
          text={`Nombre de salariés sur le dernier mois`}
          kpi={latestEffectif}
          chart={chartData && <Line data={chartData} options={chartOptions} height={65} width={100} />}
          onClick={handleOnClick}
        />
      );
    };

export default EffectifNumber;
