import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from "../../../components/Sidebar";
import colors from '../../../assets/colors';
import Header from "../../../components/Header";
import FiltersComponent from '../../../components/recrutement/chartv2/FiltersComponent';
import ChartWithKPIsComponent from '../../../components/recrutement/chartv2/ChartWithKPIsComponent';
import TableComponent from '../../../components/recrutement/chartv2/TableComponent';
import { faClipboard, faPeopleGroup } from '@fortawesome/free-solid-svg-icons';

const InsightPagePopulationLocation = () => {
    const [populationData, setPopulationData] = useState([]);
    const [historicalData, setHistoricalData] = useState([]);
    const [filterOptions, setFilterOptions] = useState({ teams: [], locations: [] });
    const [filters, setFilters] = useState([]);
    const [currentFilter, setCurrentFilter] = useState({ column: '', value: '', displayValue: '' });
    const [chartData, setChartData] = useState(null);
    const [kpiDataArray, setKpiDataArray] = useState([]);
    const [filteredPopulationData, setFilteredPopulationData] = useState([]);
    const [filteredHistoricalData, setFilteredHistoricalData] = useState([]);
    const [historiqueTableData, setHistoriqueTableData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
    const navigate = useNavigate();

    // Fetch data from GraphQL API
    const fetchGraphQLData = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            const userData = JSON.parse(localStorage.getItem('userData'));
            const entrepriseId = userData?.entreprise_id;

            if (!entrepriseId) {
                setError('Entreprise ID non trouvé dans les données utilisateur.');
                return;
            }

            const query = `
                query ($dateToCheck: Date!, $entrepriseId: Int!) {
                    populationLocationByPeriod(entrepriseId: $entrepriseId, dateToCheck: $dateToCheck) {
                        location
                        teams {

                            name
                            population
                            openPositionsSigned
                        }
                    }
                    historiquesEmploi(entrepriseId: $entrepriseId) {
                        employe {
                            firstName
                            lastName
                        }
                        equipe {
                            nomEquipe
                        }
                        metier {
                            titreMetier
                        }
                        localisation
                        dateDebut
                    }
                    equipes(entrepriseId: $entrepriseId) {
                        id
                        nomEquipe
                    }
                }
            `;
            const response = await fetch(`${apiUrl}/graphql/`, {
                method: 'POST',
                headers: {
                    Authorization: `Token ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ query, variables: { dateToCheck: startDate, entrepriseId } }),
            });

            if (!response.ok) throw new Error('Erreur lors de la récupération des données.');

            const { data } = await response.json();
            if (data) {
                setPopulationData(data.populationLocationByPeriod || []);
                setHistoricalData(data.historiquesEmploi || []);
               
                setFilterOptions({
                    teams: data.equipes.map((team) => ({
                        nom_equipe: team.nomEquipe,
                        id: team.id,
                    })),
                    locations: [...new Set(data.populationLocationByPeriod.map((item) => item.location))].filter(Boolean),
                });
                
            }
        } catch (err) {
            setError('Erreur lors de la récupération des données.');
        } finally {
            setLoading(false);
        }
    };
    console.log("filters", filterOptions);
    // Apply filters to the data
    const applyFilters = (data, filters) => {
        if (!filters.length) return data;
    
        return filters.reduce((acc, filter) => {
            if (filter.column === 'team') {
                const filterValue = parseInt(filter.value, 10);
                return acc.filter((item) =>
                    Array.isArray(item.teams) && item.teams.some((team) => team.id === filterValue)
                );
            } else if (filter.column === 'location') {
                return acc.filter((item) => item.location === filter.value);
            }
            return acc;
        }, data);
    };
    
    
    

    // Aggregate data for charts and KPIs
    const aggregateChartDataByLocation = (data) => {
        if (!data.length) return { labels: [], datasets: [] };

        const aggregated = data.map((item) => ({
            location: item.location || 'Localisation inconnue',
            population: item.teams.reduce((sum, team) => sum + (team.population || 0), 0),
            openPositionsSigned: item.teams.reduce((sum, team) => sum + (team.openPositionsSigned || 0), 0),
        }));

        setChartData({
            labels: aggregated.map((item) => item.location),
            datasets: [
                {
                    label: 'Current Workforce',
                    data: aggregated.map((item) => item.population),
                    backgroundColor: 'rgba(54, 162, 235, 0.6)',
                },
                {
                    label: 'Open Positions Signed',
                    data: aggregated.map((item) => item.openPositionsSigned),
                    backgroundColor: 'rgba(255, 99, 132, 0.6)',
                },
            ],
        });

        setKpiDataArray([
            {
                title: 'Total Workforce',
                value: aggregated.reduce((sum, item) => sum + item.population, 0),
                icon: faPeopleGroup,
                color: 'rgba(54, 162, 235, 1)',
            },
            {
                title: 'Open Positions Signed',
                value: aggregated.reduce((sum, item) => sum + item.openPositionsSigned, 0),
                icon: faClipboard,
                color: 'rgba(255, 99, 132, 1)',
            },
        ]);
    };

    // Effects
    useEffect(() => {
        fetchGraphQLData();
    }, [startDate]);

    useEffect(() => {
        const filteredPopulation = applyFilters(populationData, filters);
        setFilteredPopulationData(filteredPopulation);
        setFilteredHistoricalData(applyFilters(historicalData, filters));
        aggregateChartDataByLocation(filteredPopulation);
    }, [populationData, historicalData, filters]);

    useEffect(() => {
        setHistoriqueTableData(
            filteredHistoricalData.map((item) => ({
                employe: `${item.employe?.firstName || 'Prénom inconnu'} `,
                equipe: item.equipe?.nomEquipe || 'Équipe inconnue',
                metier: item.metier?.titreMetier || 'Métier inconnu',
                location: item.localisation || 'Localisation inconnue',
            }))
        );
    }, [filteredHistoricalData]);

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setCurrentFilter((prev) => {
            if (name === 'column') {
                // Reset value and displayValue when column changes
                return {
                    ...prev,
                    column: value,
                    value: '',
                    displayValue: '',
                };
            } else if (name === 'value') {
                if (currentFilter.column === 'team') {
                    const selectedTeam = filterOptions.teams.find((team) => team.id.toString() === value);
                    const displayValue = selectedTeam ? selectedTeam.nom_equipe : value;
                    return {
                        ...prev,
                        value,
                        displayValue,
                    };
                } else if (currentFilter.column === 'location') {
                    return {
                        ...prev,
                        value,
                        displayValue: value,
                    };
                } else {
                    return prev;
                }
            } else {
                return prev;
            }
        });
    };
    
    
    
    

    const handleAddFilter = () => {
        if (currentFilter.column && currentFilter.value) {
            setFilters((prev) => [...prev, currentFilter]);
            setCurrentFilter({ column: '', value: '', displayValue: '' });
        }
    };

    const handleRemoveFilter = (index) => {
        setFilters((prev) => prev.filter((_, i) => i !== index));
    };

    // Render
    if (loading) return <p>Chargement des données...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div style={{ backgroundColor: colors.background }} className="container-home">
            <Sidebar initiallyCollapsed={true} />
            <div className="container-right">
                <Header />
                <div className="content-container">
                    <FiltersComponent
                        showDateRange={false}
                        showGroupOption={false}
                        filterOptions={filterOptions}
                        currentFilter={currentFilter}
                        handleFilterChange={handleFilterChange}
                        handleAddFilter={handleAddFilter}
                        filters={filters}
                        handleRemoveFilter={handleRemoveFilter}
                        startDate={startDate}
                        handleStartDateChange={(e) => setStartDate(e.target.value)}
                    />
                    <div className='container-data'>
                    <ChartWithKPIsComponent
                        title="Workforce Overview by Location"
                        kpiDataArray={kpiDataArray}
                        chartType="bar"
                        showToggle={true}
                        chartOptions={{
                            maintainAspectRatio: false,
                            scales: {
                                x: { stacked: true, title: { display: true } },
                                y: { beginAtZero: true, stacked: true, title: { display: true, text: 'Staff Count & Signed Positions' } },
                            },
                        }}
                        chartData={chartData}
                        columnstable={[
                            { label: 'Employé', key: 'employe' },
                            { label: 'Équipe', key: 'equipe' },

                            { label: 'Location', key: 'location' },
                        ]}
                        titletable="Employés Overview by Location"
                        datatable={historiqueTableData}
                    />
                    <div className="card-full">
                        <TableComponent
                            title="Population Data Table"
                            columns={[
                                { label: 'Location', key: 'location' },
                                { label: 'Workforce', key: 'population' },
                                { label: 'Open Positions Signed', key: 'openPositionsSigned' },
                            ]}
                            data={filteredPopulationData.map((item) => ({
                                location: item.location || 'Localisation inconnue',
                                population: item.teams.reduce((sum, team) => sum + (team.population || 0), 0),
                                openPositionsSigned: item.teams.reduce((sum, team) => sum + (team.openPositionsSigned || 0), 0),
                            }))}
                        />
</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InsightPagePopulationLocation;
