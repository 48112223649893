import React from 'react';
import '../../styles/Sidebar.css'
import logo_txt from "../../assets/logo_txt.jpg";
import colors from '../../assets/colors.js';
import ButtonSidebar from '../buttons/ButtonSidebar';

import ButtonLogout from '../buttons/ButtonLogout';
import {  faHouse, faUser, faLightbulb, faRoad, faQuestionCircle, faComment, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';


const SidebarUser = () => {
    return (
        <div style={{ backgroundColor: colors.background }} className='sidebar'>
            <img src={logo_txt} className='logo' alt="Description du SVG"></img>
            <ButtonSidebar text="Accueil" icon={faHouse} redirectTo="/" />
            
            <ButtonSidebar text="Sécurité" icon={faLightbulb} redirectTo="/user/securite" />
            <ButtonLogout />
        </div>
    );
}

export default SidebarUser;


