import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "../../styles/Chart.css";

const ChartKPICard = ({ icon, color, title, value, change, period, groupOption }) => {
    const [displayedValue, setDisplayedValue] = useState(0);


    useEffect(() => {
        let startValue = 0;
        const duration = 1000; // 1 seconde
        const increment = (value - startValue) / 60; // Basé sur 60 FPS
        let currentFrame = 0;
        const totalFrames = 60; // 60 frames pour 1 seconde
    
        const animate = () => {
            currentFrame++;
            startValue += increment;
            setDisplayedValue(Math.min(Math.floor(startValue), value)); // Limiter la valeur à la valeur finale
    
            if (currentFrame < totalFrames) {
                requestAnimationFrame(animate);
            } else {
                // S'assurer que la valeur finale est correcte
                setDisplayedValue(value);
            }
        };
    
        animate();
    
        return () => {
            cancelAnimationFrame(animate); // Nettoyage en cas de démontage du composant
        };
    }, [value]);
    

    return (
        <div className='kpi-box'>
            <div className='kpi-header'>
                <FontAwesomeIcon icon={icon} style={{ color }} />
                <span>{title}</span>
            </div>
            <div className='kpi-data'>
                <span className='kpi-value'>{displayedValue}</span>
                {/* N'affiche la section de changement que si 'change' est une valeur valide */}
                {!isNaN(change) && (
                    <span className='kpi-change'>
                        <span className='kpi-arrow'>{change >= 0 ? '▲' : '▼'}</span> {Math.abs(change)}%
                    </span>
                )}
            </div>
            <div className='kpi-subtext'>
                compared to previous {groupOption === 'month' ? 'month' : 'quarter'}
            </div>
        </div>
    );
};

export default ChartKPICard;
