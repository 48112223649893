import React, { useState, useEffect } from 'react';
import Sidebar from "../../../components/Sidebar";
import colors from '../../../assets/colors';
import Header from "../../../components/Header";
import { Line, Bar } from 'react-chartjs-2'; // Importer Bar ici
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Tooltip, Legend } from 'chart.js';
import "../../../styles/Home.css";
import "../../../styles/Card.css";
import "../../../styles/Graph.css";
import Tableau from '../../../components/recrutement/Tableau';

// Configuration nécessaire pour ChartJS
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Tooltip, Legend);

// Fonction pour formater les dates en 'YYYY-MM-DD'
const formatDate = (date) => {
    const d = new Date(date);
    const month = `${d.getMonth() + 1}`.padStart(2, '0');
    const day = `${d.getDate()}`.padStart(2, '0');
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
};

const GraphStaffMasseSalariale = () => {
    const today = new Date();
    const lastYear = new Date();
    const nextYear = new Date();
    lastYear.setFullYear(today.getFullYear() - 1);
    nextYear.setFullYear(today.getFullYear() + 1);

    const [chartData, setChartData] = useState(null);
    const [groupOption, setGroupOption] = useState('month');
    const [groupBy, setGroupBy] = useState('none'); // Ajout de l'option 'none'
    const [startDate, setStartDate] = useState(formatDate(lastYear));
    const [endDate, setEndDate] = useState(formatDate(nextYear));

    const processSalaryData = (salaryData) => {
        const labels = salaryData.map(item => item.periode);
        let salaryDataset;

        if (groupBy === 'team') {
            const teams = ['produit', 'tech', 'ops', 'marketing', 'sales'];
            salaryDataset = teams.map((team, index) => ({
                label: `Masse Salariale - ${team}`,
                data: salaryData.map(item => Object.values(item.teams[team]).reduce((a, b) => a + b, 0)),
                backgroundColor: `rgba(${50 + index * 100}, ${100 + index * 30}, 200, 0.8)`,
            }));
        } else if (groupBy === 'location') {
            const locations = ['paris', 'marseille', 'barcelone', 'berlin'];
            salaryDataset = locations.map((location, index) => ({
                label: `Masse Salariale - ${location}`,
                data: salaryData.map(item => Object.values(item.teams).reduce((sum, team) => sum + team[location], 0)),
                backgroundColor: `rgba(${100 + index * 100}, ${50 + index * 40}, 150, 0.8)`,
            }));
        } else {
            salaryDataset = [{
                label: 'Masse Salariale',
                data: salaryData.map(item => Object.values(item.teams).reduce((sum, team) => sum + Object.values(team).reduce((a, b) => a + b, 0), 0)),
                backgroundColor: 'rgba(147, 194, 219, 0.8)',
            }];
        }

        setChartData({
            labels: labels,
            datasets: [...salaryDataset],
        });
    };


    const salaryData = [
        { periode: '01/2024', teams: {
            produit: { paris: 300000, marseille: 150000, barcelone: 100000, berlin: 80000 },
            tech: { paris: 400000, marseille: 200000, barcelone: 150000, berlin: 120000 },
            ops: { paris: 200000, marseille: 100000, barcelone: 70000, berlin: 60000 },
            marketing: { paris: 250000, marseille: 120000, barcelone: 80000, berlin: 70000 },
            sales: { paris: 250000, marseille: 120000, barcelone: 90000, berlin: 60000 }
        }},
        { periode: '02/2024', teams: {
            produit: { paris: 320000, marseille: 160000, barcelone: 10500, berlin: 8500 },
            tech: { paris: 410000, marseille: 190000, barcelone: 15500, berlin: 12500 },
            ops: { paris: 210000, marseille: 120000, barcelone: 6500, berlin: 6500 },
            marketing: { paris: 260000, marseille: 130000, barcelone: 8500, berlin: 7500 },
            sales: { paris: 270000, marseille: 110000, barcelone: 9500, berlin: 60000 }
        }},
        { periode: '03/2024', teams: {
            produit: { paris: 340000, marseille: 170000, barcelone: 9500, berlin: 90000 },
            tech: { paris: 440000, marseille: 220000, barcelone: 160000, berlin: 13500 },
            ops: { paris: 240000, marseille: 110000, barcelone: 7500, berlin: 6500 },
            marketing: { paris: 270000, marseille: 140000, barcelone: 100000, berlin: 80000 },
            sales: { paris: 290000, marseille: 130000, barcelone: 10500, berlin: 70000 }
        }},
        { periode: '04/2024', teams: {
            produit: { paris: 360000, marseille: 180000, barcelone: 110000, berlin: 9500 },
            tech: { paris: 470000, marseille: 230000, barcelone: 16500, berlin: 150000 },
            ops: { paris: 260000, marseille: 120000, barcelone: 90000, berlin: 70000 },
            marketing: { paris: 280000, marseille: 150000, barcelone: 90000, berlin: 8500 },
            sales: { paris: 310000, marseille: 180000, barcelone: 11500, berlin: 7500 }
        }},
        { periode: '05/2024', teams: {
            produit: { paris: 380000, marseille: 190000, barcelone: 120000, berlin: 100000 },
            tech: { paris: 480000, marseille: 240000, barcelone: 170000, berlin: 140000 },
            ops: { paris: 250000, marseille: 130000, barcelone: 9500, berlin: 90000 },
            marketing: { paris: 290000, marseille: 160000, barcelone: 100000, berlin: 90000 },
            sales: { paris: 330000, marseille: 160000, barcelone: 110000, berlin: 80000 }
        }},
        { periode: '06/2024', teams: {
            produit: { paris: 420000, marseille: 200000, barcelone: 12500, berlin: 10500 },
            tech: { paris: 500000, marseille: 250000, barcelone: 180000, berlin: 150000 },
            ops: { paris: 290000, marseille: 160000, barcelone: 90000, berlin: 8500 },
            marketing: { paris: 310000, marseille: 170000, barcelone: 10500, berlin: 9500 },
            sales: { paris: 350000, marseille: 170000, barcelone: 11500, berlin: 8500 }
        }},
        { periode: '07/2024', teams: {
            produit: { paris: 400000, marseille: 210000, barcelone: 130000, berlin: 110000 },
            tech: { paris: 520000, marseille: 260000, barcelone: 18500, berlin: 15500 },
            ops: { paris: 320000, marseille: 180000, barcelone: 100000, berlin: 90000 },
            marketing: { paris: 330000, marseille: 180000, barcelone: 110000, berlin: 100000 },
            sales: { paris: 370000, marseille: 180000, barcelone: 120000, berlin: 90000 }
        }},
        { periode: '08/2024', teams: {
            produit: { paris: 440000, marseille: 220000, barcelone: 13500, berlin: 11500 },
            tech: { paris: 550000, marseille: 270000, barcelone: 190000, berlin: 160000 },
            ops: { paris: 340000, marseille: 170000, barcelone: 11500, berlin: 9500 },
            marketing: { paris: 350000, marseille: 190000, barcelone: 11500, berlin: 10500 },
            sales: { paris: 390000, marseille: 200000, barcelone: 12500, berlin: 9500 }
        }},
        { periode: '09/2024', teams: {
            produit: { paris: 480000, marseille: 230000, barcelone: 140000, berlin: 120000 },
            tech: { paris: 570000, marseille: 280000, barcelone: 19500, berlin: 16500 },
            ops: { paris: 360000, marseille: 180000, barcelone: 120000, berlin: 9500 },
            marketing: { paris: 370000, marseille: 210000, barcelone: 120000, berlin: 110000 },
            sales: { paris: 410000, marseille: 200000, barcelone: 130000, berlin: 100000 }
        }},
        { periode: '10/2024', teams: {
            produit: { paris: 500000, marseille: 240000, barcelone: 14500, berlin: 12500 },
            tech: { paris: 590000, marseille: 290000, barcelone: 200000, berlin: 170000 },
            ops: { paris: 380000, marseille: 190000, barcelone: 130000, berlin: 10500 },
            marketing: { paris: 390000, marseille: 210000, barcelone: 12500, berlin: 11500 },
            sales: { paris: 430000, marseille: 230000, barcelone: 13500, berlin: 110000 }
        }},
        { periode: '11/2024', teams: {
            produit: { paris: 510000, marseille: 250000, barcelone: 150000, berlin: 130000 },
            tech: { paris: 620000, marseille: 300000, barcelone: 20500, berlin: 17500 },
            ops: { paris: 400000, marseille: 200000, barcelone: 12500, berlin: 110000 },
            marketing: { paris: 430000, marseille: 220000, barcelone: 13500, berlin: 120000 },
            sales: { paris: 450000, marseille: 220000, barcelone: 140000, berlin: 110000 }
        }},
        { periode: '12/2024', teams: {
            produit: { paris: 520000, marseille: 260000, barcelone: 160000, berlin: 13500 },
            tech: { paris: 640000, marseille: 310000, barcelone: 210000, berlin: 180000 },
            ops: { paris: 430000, marseille: 220000, barcelone: 130000, berlin: 11500 },
            marketing: { paris: 440000, marseille: 230000, barcelone: 140000, berlin: 12500 },
            sales: { paris: 470000, marseille: 230000, barcelone: 14500, berlin: 11500 }
        }}
    ];
    

    const columns = [
        { key: 'periode', label: 'Period' },
        { key: 'headcount', label: 'Headcount' },

    ];

    useEffect(() => {
        processSalaryData(salaryData);
    }, [groupBy]);

    const handleGroupChange = (event) => {
        setGroupOption(event.target.value);
    };

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };

    const handleGroupByChange = (event) => {
        setGroupBy(event.target.value);
    };

    return (
        <div style={{ backgroundColor: colors.background }} className="container-home">
            <Sidebar />
            <div className="container-right">
                <Header />

                <div className="card-full">
                    <div className="display-line">
                        <div className="display-line">
                            Start Date
                            <input type="date" className="form-input" value={startDate} onChange={handleStartDateChange} />
                        </div>
                        <div className="display-line">
                            End Date
                            <input type="date" className="form-input" value={endDate} onChange={handleEndDateChange} />
                        </div>
                        <div className="display-line">
                            Group By
                            <select value={groupOption} onChange={handleGroupChange}>
                                <option value="month">Month</option>
                                <option value="quarter">Quarter</option>
                            </select>
                        </div>
                        <div className="display-line">
                            Group Data By
                            <select value={groupBy} onChange={handleGroupByChange}>
                                <option value="none">None</option>
                                <option value="team">Team</option>
                                <option value="location">Location</option>
                            </select>
                        </div>
                        <button type="button" onClick={() => processSalaryData(salaryData)}>Validate</button>
                    </div>
                </div>

                <div className="card-full margin-b-40">
                    {chartData && (groupBy === 'team' || groupBy === 'location' ? (
                        <Bar
                            data={chartData}
                            options={{
                                maintainAspectRatio: false,
                                scales: {
                                    x: {
                                        title: {
                                            display: true,
                                            text: 'Period',
                                        },
                                    },
                                    y: {
                                        beginAtZero: true,
                                        title: {
                                            display: true,
                                            text: 'Masse Salariale (€)',
                                        },
                                    },
                                },
                                plugins: {
                                    legend: {
                                        display: true,
                                        position: 'bottom',
                                    },
                                    title: {
                                        display: true,
                                        text: 'Evolution de la Masse Salariale',
                                    },
                                },
                            }}
                        />
                    ) : (
                        <Line
                            data={chartData}
                            options={{
                                maintainAspectRatio: false,
                                scales: {
                                    x: {
                                        title: {
                                            display: true,
                                            text: 'Period',
                                        },
                                    },
                                    y: {
                                        beginAtZero: true,
                                        title: {
                                            display: true,
                                            text: 'Masse Salariale (€)',
                                        },
                                    },
                                },
                                plugins: {
                                    legend: {
                                        display: true,
                                        position: 'bottom',
                                    },
                                    title: {
                                        display: true,
                                        text: 'Evolution de la Masse Salariale',
                                    },
                                },
                            }}
                        />
                    ))}
                </div>

            </div>
        </div>
    );
};

export default GraphStaffMasseSalariale;
