import React , { useState, useEffect } from 'react';
import colors from '../../assets/colors.js';


const InfoPerso = () => {
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const token = localStorage.getItem('token');
      const response = await fetch(`${apiUrl}/api/employe-log/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Token ${token}`
        },
        body: JSON.stringify({
          username,
          first_name: firstName,
          last_name: lastName,
          email
        })
      });

      if (!response.ok) {
        throw new Error('Erreur de réseau ou réponse non valide');
      }

      const data = await response.json();
      setUsername(data.username);
      setFirstName(data.first_name);
      setLastName(data.last_name);
      setEmail(data.email);
      localStorage.setItem('userData', JSON.stringify(data));
    } catch (error) {
      console.error("Erreur lors de la mise à jour des données de l'utilisateur:", error);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        let data = localStorage.getItem('userData');
        if (data) {
          data = JSON.parse(data);
          setUsername(data.username);
          setFirstName(data.first_name);
          setLastName(data.last_name);
          setEmail(data.email);
        } else {
          const apiUrl = process.env.REACT_APP_API_URL;
          const token = localStorage.getItem('token');
          const response = await fetch(`${apiUrl}/api/employe-log/`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${token}`
            }
          });

          if (!response.ok) {
            throw new Error('Erreur de réseau ou réponse non valide');
          }

          data = await response.json();
          setUsername(data.username);
          setFirstName(data.first_name);
          setLastName(data.last_name);
          setEmail(data.email);
          localStorage.setItem('userData', JSON.stringify(data));
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données de l'utilisateur:", error);
      }
    };

    fetchUserData();
  }, []);

  return (
    <form onSubmit={handleFormSubmit}>
      <label className="label-container">
        <span className="label-title">Nom d'utilisateur</span>
        <input className="form-input" type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
      </label>
      <br />
      <label className="label-container">
        <span className="label-title">Prénom</span>
        <input className="form-input" type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
      </label>
      <br />
      <label className="label-container">
        <span className="label-title">Nom</span>
        <input className="form-input" type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
      </label>
      <br />
      <label className="label-container">
        <span className="label-title">Email</span>
        <input className="form-input" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
      </label>
      <br />

      <button type="submit">Enregistrer</button>
    </form>
  );
}

export default InfoPerso;
