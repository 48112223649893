const KPISection = ({ masseSalarialeChargee, nombrePositions, budget }) => {
    // Calcul de la différence en pourcentage
    const percentageDiff = Math.round(((masseSalarialeChargee - budget) / budget) * 100);

    return (
        <div className="display-line-flex">
            {/* Card pour le Forecast */}
            <div className="card-kpi-staff">
                <h5>Recruitment Forecast</h5>
                <p>
                    {parseFloat(masseSalarialeChargee).toLocaleString('fr-FR', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                    })}{' €'}
                    <span
                        style={{
                            marginLeft: '10px',
                            color: percentageDiff > 0 ? 'red' : 'green',
                            backgroundColor: percentageDiff > 0 ? '#ffe6e6' : '#e6ffe6', // Rouge clair si positif, vert clair si négatif
                            padding: '2px 6px',
                            borderRadius: '10px',
                            fontWeight: 'bold',
                        }}
                    >
                        {percentageDiff > 0 ? `+${percentageDiff}%` : `${percentageDiff}%`}
                    </span>
                </p>


            </div>

            {/* Card pour le Budget */}
            <div className="card-kpi-staff">
                <h5>Recruitment Budget</h5>
                <p>
                    {parseFloat(budget).toLocaleString('fr-FR', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                    })}{' €'}
                </p>
            </div>

            {/* Card pour le nombre de positions ouvertes */}
            <div className="card-kpi-staff">
                <h5>Open Position Number</h5>
                <p>{nombrePositions}</p>
            </div>
        </div>
    );
};

export default KPISection;
