import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'; // Import de l'icône
import '../../styles/ButtonLogout.css';

const ButtonLogout = ({ isCollapsed }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userData');
    navigate('/login');
  };

  return (
    <button onClick={handleLogout} className="logout-button">
      {isCollapsed ? (
        <FontAwesomeIcon icon={faSignOutAlt} />
      ) : (
        <>
          <FontAwesomeIcon icon={faSignOutAlt} /> Log Out
        </>
      )}
    </button>
  );
};

export default ButtonLogout;
