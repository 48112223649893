import React from 'react';
import { Routes, Route } from 'react-router-dom';
import GraphTurnoverRate from '../../pages/graph_pages/GraphTurnoverRate';
import GraphEffectifVolume from '../../pages/graph_pages/GraphEffectifVolume';
import GraphIndexHF from '../../pages/graph_pages/diversity/GraphIndexHF';
import GraphEffectifNouveaux from '../../pages/graph_pages/effectif/GraphEffectifNouveaux';

const RoutesGraph = () => (
  <Routes>
        <Route path="turnoverrate" element={<GraphTurnoverRate/>}/>
        <Route path="effectifvolume" element={<GraphEffectifVolume/>}/>
        <Route path="effectifnouveaux" element={<GraphEffectifNouveaux/>}/>
        <Route path="indexhf" element={<GraphIndexHF/>}/>
  </Routes>
);

export default RoutesGraph;
