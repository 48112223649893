import React, { useState, useCallback } from 'react';
import "../../styles/Tableau.css";
import Tableau from '../recrutement/Tableau';

const GroupCompensationReview = () => {
    // Define columns for the table
    const columns = [
        { key: 'group_review', label: 'Group Review' },
        { key: 'annual_salary', label: 'Annual Salary' },
        { key: 'compensation', label: 'Compensation' },
    ];


    const data = [
        { group_review: 'Talent Risk', annual_salary: '50000', compensation: '0%' },
        { group_review: 'Inconsistent Player', annual_salary: '55000', compensation: '0%' },
        { group_review: 'Rough Diamond', annual_salary: '60000', compensation: '0%' },
        { group_review: 'Solid Pro', annual_salary: '70000', compensation: '1%' },
        { group_review: 'Key Player', annual_salary: '80000', compensation: '1%' },
        { group_review: 'Future Star', annual_salary: '90000', compensation: '4%' },
        { group_review: 'High Pro', annual_salary: '100000', compensation: '4%' },
        { group_review: 'Current Star', annual_salary: '110000', compensation: '4%' },
        { group_review: 'Consistent Star', annual_salary: '120000', compensation: '6%' },
    ];
    

    // State for sorting configuration with default value
    const [sortConfig, setSortConfig] = useState({ key: 'group_review', direction: 'ascending' });

    // Callback to handle sorting
    const handleSort = useCallback((columnKey) => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === columnKey && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key: columnKey, direction });
    }, [sortConfig]);

    return (
            <div className='line-container-10'>
                <h4>Le budget de l'augmentation est de 145 000 euros</h4>
                <Tableau
                    data={data}
                    columns={columns}
                    visibleColumns={columns.map(col => col.key)}
                    sortConfig={sortConfig}
                    onSort={handleSort}
                    hideFilter={true}
                    hideSelect={true}
                />
            </div>
    );
};

export default GroupCompensationReview;
