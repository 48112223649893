import React from "react";
import '../../styles/Card.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';

const InsightCard = ({ text, dataType, data, subtext, onClick }) => {
  // Fonction pour changer le fond en fonction de la position de la souris
  const handleMouseMove = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left; // Position X de la souris relative à l'élément
    const y = e.clientY - rect.top;  // Position Y de la souris relative à l'élément
    const radialGradient = `radial-gradient(circle at ${x}px ${y}px, #EBA49B50, #EDF4F5)`;
    e.currentTarget.style.backgroundImage = radialGradient;
  };

  // Fonction pour réinitialiser le fond lorsque la souris quitte l'élément
  const handleMouseLeave = (e) => {
    e.currentTarget.style.backgroundImage = 'linear-gradient(to right, #EDF4F5, #EBA49B50, #EDF4F5)';
  };

  return (
    <div 
      className="card-insight" 
      onClick={onClick} 
      onMouseMove={handleMouseMove} 
      onMouseLeave={handleMouseLeave}
    >
      <div className="display-line">
          <div className="card-insight-titre">{text}</div>
          <FontAwesomeIcon 
                    icon={faArrowRightLong} 
                    className="card-insight-icon"
                />
      </div>

      <div className="card-insight-subtitre">{subtext}</div>
      <div className="card-insight-separator"> </div>
      <div>{data}</div>
    </div>
  );
};

export default InsightCard;
