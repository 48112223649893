import React, { useState } from 'react';
import '../../styles/ToggleBtn.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { iconleft, iconright } from '@fortawesome/free-solid-svg-icons';


const ToggleButton = ({ optionLeft = 'Gauche', optionRight = 'Droite', selectedOption, onToggle, iconleft, iconright }) => {
    return (
      <div className="toggle-button">
        <div
          className={`toggle-option ${selectedOption === optionLeft ? 'active' : ''}`}
          onClick={() => onToggle(optionLeft)}
        >
        <FontAwesomeIcon icon={iconleft} />
          {optionLeft}
        </div>
        <div
          className={`toggle-option ${selectedOption === optionRight ? 'active' : ''}`}
          onClick={() => onToggle(optionRight)}
        >
            <FontAwesomeIcon icon={iconright} />
          {optionRight}
        </div>
      </div>
    );
  };
  
  export default ToggleButton;
  
