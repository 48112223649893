import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, Filler } from 'chart.js';
import HomeCards from '../../cards/HomeCards'; // Assurez-vous que le chemin est correct
import { useNavigate } from 'react-router-dom';

// Configuration nécessaire pour ChartJS
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, Filler);

const IndexHF = () => {
    const [womenRate, setWomenRate] = useState('Chargement...');
    const [graphData, setChartData] = useState(null); // Ajout de l'état pour le chartData
    const navigate = useNavigate();

    const handleOnClick = () => {
      navigate('/graph/indexhf'); 
    };
    const sampleData = (data, samplesCount) => {
        if (data.length <= samplesCount) return data;

        const sampledData = [];
        const segmentSize = Math.ceil(data.length / samplesCount);

        for (let i = 0; i < samplesCount; i++) {
            const index = i * segmentSize;
            if (index < data.length) {
                sampledData.push(data[index]);
            }
        }

        return sampledData;
    };
    const processChartData = (data) => {
        if (data && data.Sexe) {
            const womenData = data.Sexe["F"].map(item => parseFloat(item.effectif));
            const menData = data.Sexe["M"].map(item => parseFloat(item.effectif));
            const labels = data.Sexe["M"].map(item => item.periode);
            const womenRates = womenData.map((item, index) => {
                const total = item + menData[index];
                return total ? ((item / total) * 100).toFixed(2) : 0; // Assurez-vous que c'est un nombre
            });
    
            setWomenRate(`${womenRates[womenRates.length - 1]}%`);
    
            // Convertir les chaînes en nombres pour Chart.js
            const numericRates = womenRates.map(rate => parseFloat(rate));
    
            const graphData = {
                labels: labels,
                datasets: [{
                    label: 'Taux de femmes (%)',
                    data: numericRates,
                    fill: true,
                    backgroundColor: '#EBA49B40',
                    borderColor: '#EBA49B',
                    tension: 0.1,
                }]
            };
            setChartData(graphData);
        }
    };

    

    // Fonction pour récupérer les données de turnover
    const fetchData = async () => {
        const storageKey = 'GraphEffectifVolumeData';
        const cachedData = localStorage.getItem(storageKey);
        const oneDay = 24 * 60 * 60 * 1000;
        if (cachedData) {
            const { data, timestamp } = JSON.parse(cachedData);
            if (new Date() - new Date(timestamp) < oneDay) {
                processChartData(data);
                return;
            }
        }
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`${apiUrl}/api/graph/effectif`, {
                headers: {
                    'Authorization': `Token ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la récupération des données d\'effectif');
            }

            const data = await response.json();
            localStorage.setItem(storageKey, JSON.stringify({ data, timestamp: new Date() }));
            processChartData(data);
        } catch (error) {
            console.error('Erreur lors de la récupération des données d\'effectif', error);
            setWomenRate('Erreur de chargement');
        }
    };

    // Appel à fetchData lors du montage du composant
    useEffect(() => {
      fetchData();
    }, []);

    // Options pour le graphique
    const chartOptions = {
        scales: {
          y: {
            display: false,
            grid: {
              drawBorder: false,
              display: false,
            },
            ticks: {
              display: false,
            }
          },
          x: {
            display: false,
            grid: {
              drawBorder: false,
              display: false,
            },
            ticks: {
              display: false,
            }
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        elements: {
          point: {
            radius: 0, // Toujours masquer les points
            hoverRadius: 0, // Masquer les points même lors du survol
          },
          line: {
            borderWidth: 3, // Ajustez si nécessaire
          }
        },
        maintainAspectRatio: false,
      
      };

    return (
      <HomeCards 
        text={`Female/Male ratio for the last semester`}
        kpi={womenRate}
        chart={graphData && <Line data={graphData} options={chartOptions} height={65} width={1000} />}
        
        onClick={handleOnClick}
      />
    );
};

export default IndexHF;
