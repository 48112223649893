// ButtonLinkEmployees.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';

const ButtonLinkEmployes = ({ isCollapsed }) => {

  const handleClick = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');
    fetch(`${apiUrl}/api/link-employees/`, {
      //credentials: 'include',
      method: 'GET',
      headers: {
        'Authorization': `Token ${token}`,
    },
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Failed to link employees.');
        }
      })
      .then(data => {
        alert(`Successfully linked ${data.linked} positions. ${data.not_found} positions could not be linked.`);
      })
      .catch(error => {
        console.error('Error:', error);
        alert('An error occurred while linking employees.');
      });
  };

  return (
    <button type='submit' onClick={handleClick}>
      <FontAwesomeIcon icon={faLink} />
      {!isCollapsed && <span>Link Employees</span>}
    </button>
  );
};

export default ButtonLinkEmployes;
