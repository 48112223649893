import React, { useState } from 'react';
import Lottie from 'react-lottie'; // Import de Lottie depuis react-lottie
import animationData from '../../assets/api.json';

const CreateSIRHConnector = ({ onConnectorCreated, setLoaderMessage }) => {
  const [sirhDetails, setSirhDetails] = useState({
    sirhName: 'Lucca',
    apiKey: '',
    apiEndpoint: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false); // Pour contrôler l'affichage de l'animation

  // Gestion des changements de champ du formulaire
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSirhDetails(prevDetails => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  // Fonction de soumission du formulaire
  const createSIRHConnector = async () => {
    setIsSubmitting(true); // Active l'animation
    setLoaderMessage('Création du connecteur SIRH en cours...');
    
    const apiUrl = process.env.REACT_APP_API_URL; // Ajustez selon votre environnement
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Token ${localStorage.getItem('token')}`, // Ajustez votre système d'authentification si nécessaire
    };

    const body = JSON.stringify({
      sirh_name: sirhDetails.sirhName,
      api_key: sirhDetails.apiKey,
      api_endpoint: sirhDetails.apiEndpoint,
    });

    try {
      const response = await fetch(`${apiUrl}/api-connectors/sirh-connector/`, {
        method: 'POST',
        headers,
        body,
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la création du connecteur SIRH');
      }

      setTimeout(() => {
        setIsSubmitting(false); // Cache l'animation après 4000 ms
        onConnectorCreated(sirhDetails.apiKey, sirhDetails.apiEndpoint);
      }, 4000);
    } catch (error) {
      console.error(error);
      setLoaderMessage('');
      setIsSubmitting(false); // Cache l'animation en cas d'erreur
      alert(error.message);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createSIRHConnector();
  };

  // Options pour le composant Lottie
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <>
      {isSubmitting ? (
        // Animation Lottie pendant la soumission
        <Lottie options={defaultOptions} height={200} width={200} />
      ) : (
        // Formulaire de création du connecteur SIRH
        <form className='display-column' onSubmit={handleSubmit}>
          <div>
            <label htmlFor="apiKey">API Key:</label>
            <input
              id="apiKey"
              name="apiKey"
              type="text"
              placeholder="Entrez votre API Key"
              value={sirhDetails.apiKey}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label htmlFor="apiEndpoint">API Endpoint:</label>
            <input
              id="apiEndpoint"
              name="apiEndpoint"
              type="text"
              placeholder="Entrez votre API Endpoint"
              value={sirhDetails.apiEndpoint}
              onChange={handleChange}
              required
            />
            <p> En général l'URL se présente sous cette forme : https://NomDeVotrEnetreprise.ilucca.net</p>
          </div>
          {!isSubmitting && (
          <button type="submit">Importer mes données</button>
        )}
        </form>
      )}
    </>
  );
};

export default CreateSIRHConnector;
