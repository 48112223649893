import React, { useState } from 'react';
import Sidebar from "../../../components/Sidebar";
import colors from '../../../assets/colors';
import Header from "../../../components/Header";
import "../../../styles/Home.css";
import "../../../styles/Card.css";
import "../../../styles/ScenariHR.css";

const ScenarioHR = () => {
    const [forecastData] = useState({
        budget: 475000,
        totalStaffCosts: 28900000,
        positions: [
            { id: 1000, title: 'Sales Associate', department: 'Sales', status: 'Needs Review', manager: 'Evelyn Thompson', budget: 102000, startDate: '2025-08-09', experienceLevel: 'Junior' },
            { id: 1001, title: 'HR Specialist', department: 'HR', status: 'Requested', manager: 'Charlie Brown', budget: 47000, startDate: '2025-08-22', experienceLevel: 'Mid-Level' },
            { id: 1002, title: 'Software Engineer', department: 'Engineering', status: 'Requested', manager: 'Bob Smith', budget: 97000, startDate: '2025-01-04', experienceLevel: 'Senior' },
            { id: 1003, title: 'Marketing Manager', department: 'Marketing', status: 'Needs Review', manager: 'Alice Johnson', budget: 84000, startDate: '2025-02-15', experienceLevel: 'Senior' },
            { id: 1004, title: 'Financial Analyst', department: 'Finance', status: 'Requested', manager: 'David Wilson', budget: 64000, startDate: '2025-03-30', experienceLevel: 'Mid-Level' },
            { id: 1005, title: 'Product Manager', department: 'Product', status: 'Requested', manager: 'Megan Lee', budget: 94000, startDate: '2025-04-12', experienceLevel: 'Senior' },
            { id: 1006, title: 'Data Scientist', department: 'Data', status: 'Needs Review', manager: 'Sophia Davis', budget: 102000, startDate: '2025-05-25', experienceLevel: 'Senior' },
            { id: 1007, title: 'UX Designer', department: 'Design', status: 'Requested', manager: 'Liam Martinez', budget: 72000, startDate: '2025-06-10', experienceLevel: 'Mid-Level' },
            { id: 1008, title: 'Operations Manager', department: 'Operations', status: 'Needs Review', manager: 'Olivia Garcia', budget: 80000, startDate: '2025-07-05', experienceLevel: 'Senior' },
            { id: 1009, title: 'Customer Service Rep', department: 'Customer Service', status: 'Requested', manager: 'Noah Rodriguez', budget: 38000, startDate: '2025-08-18', experienceLevel: 'Junior' },
            { id: 1010, title: 'Logistics Coordinator', department: 'Logistics', status: 'Needs Review', manager: 'Emma Hernandez', budget: 51000, startDate: '2025-09-02', experienceLevel: 'Mid-Level' },
            { id: 1011, title: 'QA Engineer', department: 'Quality Assurance', status: 'Requested', manager: 'William Lopez', budget: 66000, startDate: '2025-10-20', experienceLevel: 'Mid-Level' },
            { id: 1012, title: 'IT Support Specialist', department: 'IT', status: 'Needs Review', manager: 'James Gonzalez', budget: 55000, startDate: '2025-11-11', experienceLevel: 'Mid-Level' },
            { id: 1013, title: 'Business Analyst', department: 'Business', status: 'Requested', manager: 'Charlotte Clark', budget: 76000, startDate: '2025-12-01', experienceLevel: 'Mid-Level' },
            { id: 1014, title: 'Content Writer', department: 'Content', status: 'Needs Review', manager: 'Lucas Lewis', budget: 47000, startDate: '2026-01-14', experienceLevel: 'Junior' },
            { id: 1015, title: 'Legal Counsel', department: 'Legal', status: 'Requested', manager: 'Amelia Young', budget: 98000, startDate: '2026-02-28', experienceLevel: 'Senior' },
            { id: 1016, title: 'Procurement Specialist', department: 'Procurement', status: 'Needs Review', manager: 'Mason Hall', budget: 60000, startDate: '2026-03-22', experienceLevel: 'Mid-Level' },
            { id: 1017, title: 'Digital Marketer', department: 'Marketing', status: 'Requested', manager: 'Harper Allen', budget: 70000, startDate: '2026-04-08', experienceLevel: 'Mid-Level' },
            { id: 1018, title: 'Recruiter', department: 'HR', status: 'Needs Review', manager: 'Ethan King', budget: 55000, startDate: '2026-05-16', experienceLevel: 'Mid-Level' },
            { id: 1019, title: 'Training Coordinator', department: 'HR', status: 'Requested', manager: 'Isabella Scott', budget: 51000, startDate: '2026-06-29', experienceLevel: 'Mid-Level' },
            { id: 1020, title: 'Network Engineer', department: 'IT', status: 'Needs Review', manager: 'Alexander Adams', budget: 80000, startDate: '2026-07-12', experienceLevel: 'Senior' },
        ]
    });

    const [previousYearData] = useState({
        forecast: 750000,
        totalStaffCosts: 28000000,
    });

    const [selectedRows, setSelectedRows] = useState([]);
    const [updatedPositions, setUpdatedPositions] = useState(forecastData.positions);
    const [savings, setSavings] = useState(null);
    const [impact, setImpact] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const positionsPerPage = 10;

    const handleCheckboxChange = (id) => {
        setSelectedRows((prevSelectedRows) =>
            prevSelectedRows.includes(id)
                ? prevSelectedRows.filter((rowId) => rowId !== id)
                : [...prevSelectedRows, id]
        );
    };

    const handleApprove = () => {
        let totalImpact = 0;

        const newPositions = updatedPositions.map((position) => {
            if (selectedRows.includes(position.id) && position.status !== 'Approved') {
                const monthsIn2025 = getMonthsInYear(position.startDate, 2025);
                const proratedBudget = (position.budget || 0) * (monthsIn2025 / 12);
                totalImpact += proratedBudget;

                return { ...position, status: 'Approved' };
            }
            return position;
        });

        setUpdatedPositions(newPositions);
        setSelectedRows([]);

        if (totalImpact !== 0) {
            setImpact(totalImpact);
            setTimeout(() => setImpact(null), 2500);
        }
    };

    const handleDecline = () => {
        let totalSavings = 0;

        const newPositions = updatedPositions.map((position) => {
            if (selectedRows.includes(position.id)) {
                const monthsIn2025 = getMonthsInYear(position.startDate, 2025);
                const proratedBudget = (position.budget || 0) * (monthsIn2025 / 12);
                totalSavings += proratedBudget;

                return { ...position, status: 'Declined' };
            }
            return position;
        });

        setUpdatedPositions(newPositions);
        setSelectedRows([]);

        if (totalSavings > 0) {
            setSavings(totalSavings);
            setTimeout(() => setSavings(null), 2500);
        }
    };

    const [editingStartDateId, setEditingStartDateId] = useState(null);

    const handleStartDateChange = (e, id) => {
        const newDate = e.target.value;
        const positionToUpdate = updatedPositions.find(position => position.id === id);

        if (positionToUpdate) {
            const oldMonthsIn2025 = getMonthsInYear(positionToUpdate.startDate, 2025);
            const oldProratedBudget = (positionToUpdate.budget || 0) * (oldMonthsIn2025 / 12);

            const newMonthsIn2025 = getMonthsInYear(newDate, 2025);
            const newProratedBudget = (positionToUpdate.budget || 0) * (newMonthsIn2025 / 12);

            const impactAmount = newProratedBudget - oldProratedBudget;

            const newPositions = updatedPositions.map((position) =>
                position.id === id ? { ...position, startDate: newDate } : position
            );

            setUpdatedPositions(newPositions);

            if (impactAmount > 0) {
                setImpact(impactAmount);
                setTimeout(() => setImpact(null), 1500);
            }
            if (impactAmount < 0) {
                setSavings(Math.abs(impactAmount));
                setTimeout(() => setSavings(null), 1500);
            }
        }
    };

    const getMonthsInYear = (startDate, year) => {
        const start = new Date(startDate);
        const endOfYear = new Date(year, 11, 31);

        if (start > endOfYear) {
            return 0;
        }

        const startMonth = start.getFullYear() === year ? start.getMonth() : 0;
        const months = (12 - startMonth);

        return months;
    };

    const calculateForecast = (positions) => {
        return positions
            .filter(position => position.status !== 'Declined')
            .reduce((sum, position) => {
                const monthsIn2025 = getMonthsInYear(position.startDate, 2025);
                const proratedBudget = (position.budget || 0) * (monthsIn2025 / 12);
                return sum + proratedBudget;
            }, 0);
    };

    const forecast = calculateForecast(updatedPositions);

    const budget = forecastData.budget;
    const variancePercentage = ((forecast / budget) * 100).toFixed(2);

    const currentStaffCosts = forecastData.totalStaffCosts;
    const totalStaffCosts = currentStaffCosts + forecast;

    const previousForecast = previousYearData.forecast;
    const forecastChangePercentage = (((forecast - previousForecast) / previousForecast) * 100).toFixed(2);

    const previousTotalStaffCosts = previousYearData.totalStaffCosts;
    const staffCostsChangePercentage = (((totalStaffCosts - previousTotalStaffCosts) / previousTotalStaffCosts) * 100).toFixed(2);

    const formattedBudget = budget.toLocaleString('en-US', { style: 'currency', currency: 'EUR' });
    const formattedForecast = forecast.toLocaleString('en-US', { style: 'currency', currency: 'EUR' });
    const formattedTotalStaffCosts = totalStaffCosts.toLocaleString('en-US', { style: 'currency', currency: 'EUR' });

    const indexOfLastPosition = currentPage * positionsPerPage;
    const indexOfFirstPosition = indexOfLastPosition - positionsPerPage;
    const currentPositions = updatedPositions.slice(indexOfFirstPosition, indexOfLastPosition);

    const totalPages = Math.ceil(updatedPositions.length / positionsPerPage);

    const handleNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    const handlePrevPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    return (
        <div style={{ backgroundColor: colors.background }} className='container-home'>
            <Sidebar initiallyCollapsed={true} />
            <div className="container-right">
                <Header />
                <div className='display-line-flex'>
                    {/* Fixed Budget Card */}
                    <div>
                        <div className='card-kpi-staff'>
                            <h5>Recruitment Forecast</h5>
                            <p>{formattedForecast}</p>
                        </div>

                        {/* Notifications display here */}
                        <div className='notification-container'>
                            {savings !== null && (
                                <div className="notification savings">
                                    -{savings.toLocaleString('en-US', { style: 'currency', currency: 'EUR' })}
                                </div>
                            )}
                            {impact !== null && (
                                <div className="notification impact">
                                    + {impact.toLocaleString('en-US', { style: 'currency', currency: 'EUR' })}
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Dynamic Forecast */}

                    <div className='card-kpi-staff'>
                        <h5>Recruitment Budget</h5>
                        <p>{formattedBudget} <span>+5% YoY</span></p>
                    </div>

                    {/* Calculated Variance */}
                    <div className='card-kpi-staff'>
                        <h5>Recruitment Variance</h5>
                        <p>{variancePercentage}%</p>
                    </div>
                    {/* Updated Total Staff Costs */}
                    <div className='card-kpi-staff'>
                        <h5>Total Staff Costs</h5>
                        <p>{formattedTotalStaffCosts} <span>{staffCostsChangePercentage}% YoY</span></p>
                    </div>
                </div>

                {/* Toolbar */}
                {selectedRows.length > 0 && (
                    <div className="toolbar">
                        <span>{selectedRows.length} row(s) selected</span>
                        <button onClick={handleApprove}>Approve</button>
                        <button onClick={handleDecline}>Decline</button>
                    </div>
                )}

                {/* Positions Table */}
                <div className="div-positions-table">
                    <table className="positions-table">
                        <thead>
                            <tr>
                                <th>Select</th>
                                <th>Start Date</th>
                                <th>Position Title</th>
                                <th>Department</th>
                                <th>Experience Level</th>
                                <th>Status</th>
                                <th>Manager</th>
                                <th>Estimated Budget</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentPositions.map((position) => (
                                <tr
                                    key={position.id}
                                    className={`${selectedRows.includes(position.id) ? 'selected' : ''} ${position.status === 'Declined' ? 'declined' : ''}`}
                                >
                                    <td>
                                        <input
                                            className='clickable'
                                            type="checkbox"
                                            checked={selectedRows.includes(position.id)}
                                            onChange={() => handleCheckboxChange(position.id)}
                                        />
                                    </td>
                                    {/* Start Date Cell with Date Picker */}
                                    <td
                                        className='clickable' onClick={() => editingStartDateId !== position.id && setEditingStartDateId(position.id)}
                                    >
                                        {editingStartDateId === position.id ? (
                                            <input
                                                type="date"
                                                value={position.startDate}
                                                onChange={(e) => handleStartDateChange(e, position.id)}
                                                onBlur={() => setEditingStartDateId(null)}
                                                autoFocus
                                                className='clickable'
                                            />
                                        ) : (
                                            position.startDate
                                        )}
                                    </td>
                                    <td>{position.title}</td>
                                    <td>{position.department}</td>
                                    <td>{position.experienceLevel}</td>
                                    <td><div className={`status ${position.status.replace(/\s+/g, '')}`}>{position.status}</div></td>
                                    <td>{position.manager}</td>
                                    <td>{position.budget.toLocaleString('en-US', { style: 'currency', currency: 'EUR' })}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Pagination Controls */}
                <div className="display-line">
                    <button className="btn-secondary" onClick={handlePrevPage} disabled={currentPage === 1}>Previous</button>
                    <div className='nav-btn-new'>Page {currentPage} of {totalPages}</div>
                    <button className="btn-secondary" onClick={handleNextPage} disabled={currentPage === totalPages}>Next</button>
                </div>
            </div>
        </div>
    );
};

export default ScenarioHR;
