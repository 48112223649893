import React from "react";
import '../../styles/Card.css'


const  HomeCards = ({ text,time, chart, kpi, onClick }) => {
  return (
    <div className="card-home" onClick={onClick}>
        
        <div className="card-home-kpi">{kpi}</div>
        <div className="card-home-subtitles">
            <div className="card-home-titre">{text}</div>
            <div className="card-home-time">{time}</div>
        </div>
        <div  className="card-home-chart">
        {chart}</div>
    </div>
  );
};
export default HomeCards;