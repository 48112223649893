import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, Filler } from 'chart.js';
import HomeCards from '../../cards/HomeCards';
import { useNavigate } from 'react-router-dom';
import amplitude from 'amplitude-js';

// Enregistre les composants pour Chart.js
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, Filler);

const CompareRecruit = ({ team }) => {
    const [chartData, setChartData] = useState(null);
    const [remainingRecruitment, setRemainingRecruitment] = useState('Chargement...');
    const navigate = useNavigate();
    const storedUserData = localStorage.getItem('userData');
    const ampData = storedUserData ? JSON.parse(storedUserData) : {};

    const handleOnClick = () => {
        amplitude.getInstance().logEvent('OverviewCardClicked', {
            username: ampData.username || 'unknown',
            graph: 'Compare Recruit',
            description: 'Remaining recruitment until the end of the year',
        });

        navigate('/staffingplan/insight/prevision');
    };

    const processChartData = (data) => {
        const labels = data.map(item => item.periode);
        const actualData = data.map(item => item.op_historique_real);
        const estimatedData = data.map(item => item.op_historique_reforecast);

        setChartData({
            labels,
            datasets: [
                {
                    label: 'Current workforce',
                    data: actualData,
                    borderColor: '#EBA49B',
                    backgroundColor: '#EBA49B40',
                    fill: true,
                    tension: 0.1
                },
                {
                    label: 'Forecast workforce',
                    data: estimatedData,
                    borderColor: '#93c2db',
                    backgroundColor: '#93c2db40',
                    fill: true,
                    tension: 0.1
                },
            ],
        });

        // Calculer le nombre de recrutements restants pour les mois restants
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1;
        const currentYear = currentDate.getFullYear();
        const remainingData = data.filter(item => {
            const [month, year] = item.periode.split('/').map(Number);
            return year === currentYear && month >= currentMonth;
        });

        // Calculer la somme des recrutements restants (prévisions - réels)
        const remainingRecruitments = remainingData.reduce((total, item) => {
            return total + (item.op_historique_reforecast - item.op_historique_real);
        }, 0);

        setRemainingRecruitment(`${remainingRecruitments}`);
    };

    const fetchData = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        const storageKey = `CompareRecruit_${team}`;
        const oneDay = 24 * 60 * 60 * 1000;

        const cachedData = localStorage.getItem(storageKey);

        const isCacheValid = (timestamp) => {
            return new Date() - new Date(timestamp) < oneDay;
        };

        if (cachedData) {
            const { data, timestamp } = JSON.parse(cachedData);

            if (isCacheValid(timestamp)) {
                processChartData(data);
                return;
            }
        }

        try {
            const currentYear = new Date().getFullYear();
            const startDate = new Date(`${currentYear}-01-01`);
            const endDate = new Date(`${currentYear}-12-31`);
            const formattedStartDate = startDate.toISOString().split('T')[0];
            const formattedEndDate = endDate.toISOString().split('T')[0];

            const url = `${apiUrl}/openposition/compare_recrutement?start_date=${formattedStartDate}&end_date=${formattedEndDate}&period=month&team=${team}`;
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Token ${token}`,
                },
            });
            if (!response.ok) {
                throw new Error('Erreur lors de la récupération des données d\'effectif');
            }
            const responseData = await response.json();
            const { data } = responseData;

            if (Array.isArray(data)) {
                localStorage.setItem(storageKey, JSON.stringify({ data, timestamp: new Date() }));
                processChartData(data);
            } else {
                throw new Error('Data fetched is not an array');
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des données', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [team]);

    const chartOptions = {
        scales: {
            y: {
                display: false,
                grid: {
                    drawBorder: false,
                    display: false,
                },
                ticks: {
                    display: false,
                }
            },
            x: {
                display: false,
                grid: {
                    drawBorder: false,
                    display: false,
                },
                ticks: {
                    display: false,
                }
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
        elements: {
            point: {
                radius: 0,
                hoverRadius: 0,
            },
            line: {
                borderWidth: 3,
            }
        },
        maintainAspectRatio: false,
    };

    return (
        <HomeCards
            text="Remaining recruitment until the end of the year."
            kpi={remainingRecruitment}
            chart={chartData && <Line data={chartData} options={chartOptions} height={65} width={1000} />}
            onClick={handleOnClick}
        />
    );
};

export default CompareRecruit;
