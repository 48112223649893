import React, { useState, useEffect } from 'react';
import Sidebar from "../../components/Sidebar";
import colors from '../../assets/colors';
import Header from "../../components/Header";
import "../../styles/Home.css";
import "../../styles/Card.css";
import IndexHF from '../../components/kpi/diversity/IndexHF';
import InsightAgeDistribution from '../../components/insights/Diversity/InsightAgeDistribution';
import InsightSalaryDistribution from '../../components/insights/Diversity/InsightSalaryDistribution';



const DiversityPage = () => {


    return (
        <div style={{ backgroundColor: colors.background }} className='container-home'>
            <Sidebar />
            <div className="container-right">
                <Header />
                <div className='line-container-10'>
                <h5>Vue d'ensemble</h5>
                
            </div>
            <div className='cards-container'>
                <IndexHF/>
            </div>
            <div className='line-container-10'>
                <h5>Analyses</h5>
                <div className='cards-container'>
                    <InsightAgeDistribution/>
                    <InsightSalaryDistribution/>
                </div>
            </div>      
        </div> 
        </div>
    );
};

export default DiversityPage;

