import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CreateSIRHConnector from './CreateSIRHConnector';
import '../../styles/Container.css';

const Lucca = () => {
  const [userData, setUserData] = useState(null);
  const [loaderMessage, setLoaderMessage] = useState('');
  const [error, setError] = useState('');
  const [createStatus, setCreateStatus] = useState('');

  const navigate = useNavigate();

  const fetchUserData = async (apiKey, apiEndpoint) => {
    setLoaderMessage('Récupération des données utilisateur depuis Lucca en cours...');
    const apiUrl = process.env.REACT_APP_API_URL;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Token ${localStorage.getItem('token')}`,
    };

    try {
      const luccaDataUrl = `${apiUrl}/api-connectors/data-lucca/?api_key=${encodeURIComponent(apiKey)}&api_endpoint=${encodeURIComponent(apiEndpoint)}`;
      const luccaResponse = await fetch(luccaDataUrl, { method: 'GET', headers });

      if (!luccaResponse.ok) {
        throw new Error(`Erreur lors de la récupération des données de Lucca: ${luccaResponse.statusText}`);
      }

      const luccaData = await luccaResponse.json();
      setUserData(luccaData);
      createEmployes(luccaData); // Créez les employés juste après avoir récupéré les données
      setLoaderMessage('');
    } catch (error) {
      console.error(error);
      setLoaderMessage('');
      setError("Erreur lors de la récupération des données.");
    }
  };

  const createEmployes = async (luccaData) => {
    setLoaderMessage('Création des employés en cours...');
    const apiUrl = process.env.REACT_APP_API_URL;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Token ${localStorage.getItem('token')}`,
    };

    try {
      const createEmployeUrl = `${apiUrl}/api-connectors/data-lucca/create/`;
      const response = await fetch(createEmployeUrl, {
        method: 'POST',
        headers,
        body: JSON.stringify({ lucca_data: luccaData }),
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la création des employés');
      }

      const result = await response.json();
      setCreateStatus(`Employés créés/mis à jour avec succès. Employés créés: ${result.employes_created}, Employés mis à jour: ${result.employes_updated}`);
      setError('');
    } catch (error) {
      console.error(error);
      setError("Erreur lors de la création des employés.");
      setCreateStatus('');
    } finally {
      setLoaderMessage('');
    }
  };

  const updateLuccaData = async () => {
    setLoaderMessage('Mise à jour des données depuis Lucca en cours...');
    const apiUrl = process.env.REACT_APP_API_URL;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Token ${localStorage.getItem('token')}`,
    };

    try {
      const updateUrl = `${apiUrl}/api-connectors/data-lucca/update/`;
      const updateResponse = await fetch(updateUrl, { method: 'POST', headers });

      if (!updateResponse.ok) {
        throw new Error(`Erreur lors de la mise à jour des données de Lucca: ${updateResponse.statusText}`);
      }

      const updateResult = await updateResponse.json();
      setCreateStatus(`Données Lucca mises à jour avec succès.`);
      setError('');
    } catch (error) {
      console.error(error);
      setError("Erreur lors de la mise à jour des données.");
      setCreateStatus('');
    } finally {
      setLoaderMessage('');
    }
  };

  const handleSuccessfulCreation = (apiKey, apiEndpoint) => {
    console.log('Connecteur SIRH créé avec succès.');
    setCreateStatus('Connecteur SIRH créé avec succès.');
    fetchUserData(apiKey, apiEndpoint);
  };

  return (
    <div className='padding-100'>
      <div className='display-column'>
        <h4>Import des données Lucca</h4>
        <CreateSIRHConnector onConnectorCreated={handleSuccessfulCreation} setLoaderMessage={setLoaderMessage} />
        {createStatus && (
          <div className='display-column'>
            <p>Super les données sont importées </p>
            <button type='submit' className='home-button' onClick={() => navigate('/')}>Retour à l'accueil</button>
          </div>
        )}
         {!createStatus && (
        <button type='submit' className='update-button' onClick={updateLuccaData}>Mise à jour des données</button>
      )}
        {error && <p className="error-message">{error}</p>}
      </div>
    </div>
  );
};

export default Lucca;
