import React, { useState, useEffect } from 'react';
import Sidebar from "../../../components/Sidebar";
import colors from '../../../assets/colors';
import Header from "../../../components/Header";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, Legend } from 'chart.js';
import { faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "../../../styles/Home.css";
import "../../../styles/Card.css";
import "../../../styles/Graph.css";
import OverviewCard from '../../../components/cards/OverviewCard';
// Configuration nécessaire pour ChartJS
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const GraphIndexHF = () => {
    const [chartData, setChartData] = useState(null);
    const [groupOption, setGroupOption] = useState('Global'); // Commencez par une vision globale
    const promptContext = "Tu vas recevoir des données qui représentent les populations masculines et féminines, dans l'objectif de comprendre l'égalité homme femme. Elles sont group by différentes propriétés.";
    const processChartData = (data) => {
        let labels = [];
        let effectifMaleData = [];
        let effectifFemaleData = [];
    
        if (groupOption === 'Global') {
            // Traitement spécial pour "Global" qui n'a pas de sous-catégories
            labels.push('Global'); // Ajouter un label global
            effectifMaleData.push(data[groupOption].employe_male);
            effectifFemaleData.push(data[groupOption].employe_female);
        } else {
            // Pour les autres groupes qui contiennent des sous-catégories
            let groupData = data[groupOption];
            if (!groupData) {
                console.error('Group data is undefined for option:', groupOption);
                return;
            }
    
            labels = Object.keys(groupData);
            labels.forEach(label => {
                const groupe = groupData[label];
                effectifMaleData.push(groupe.employe_male || 0); // Utiliser 0 comme valeur par défaut
                effectifFemaleData.push(groupe.employe_female || 0); // Utiliser 0 comme valeur par défaut
            });
        }
    
        setChartData({
            labels,
            datasets: [
                {
                    label: 'Homme',
                    data: effectifMaleData,
                    backgroundColor: 'rgba(75, 192, 192, 0.5)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1,
                },
                {
                    label: 'Femme',
                    data: effectifFemaleData,
                    backgroundColor: 'rgba(255, 206, 86, 0.5)',
                    borderColor: 'rgba(255, 206, 86, 1)',
                    borderWidth: 1,
                },
            ]
        });
    };
    
    

    useEffect(() => {
        const storageKey = 'GraphIndexHF';
        const savedData = localStorage.getItem(storageKey);
        
        if (savedData) {
            const { data, timestamp } = JSON.parse(savedData);
            const oneDay = 24 * 60 * 60 * 1000;

            if (new Date() - new Date(timestamp) < oneDay) {
                processChartData(data);
                return;
            }
        }

        const fetchData = async () => {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            const url = `${apiUrl}/api/graph/indexhf`;

            try {
                const response = await fetch(url, {
                    headers: { 'Authorization': `Token ${token}` },
                });

                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données');
                }

                const data = await response.json();
                localStorage.setItem(storageKey, JSON.stringify({ data: data, timestamp: new Date() }));
                processChartData(data);

            } catch (error) {
                console.error('Erreur lors de la récupération des données', error);
            }
        };

        fetchData();
    }, [groupOption]);

    const handleSelectChange = (event) => {
        setGroupOption(event.target.value);
    };



    return (
        <div style={{ backgroundColor: colors.background }} className='container-home'>
            <Sidebar />
            <div className="container-right">
                <Header />
                   
                <OverviewCard overviewKey={'OverviewGraphIndexHF'}  storageKey={'GraphIndexHF'} promptContext={promptContext}/>

                <div className='card-full'>
                    <div className='display-line'>
                        <FontAwesomeIcon icon={faPeopleGroup} />
                        <h5>Grouper par :</h5>
                        <select className="custom-select" onChange={handleSelectChange} value={groupOption}>
                            <option value="Global">Vue Globale</option>
                            <option value="Âge">Âge</option>
                            <option value="Ancienneté">Ancienneté</option>
                            <option value="Equipe">Equipe</option>
                        </select>
                    </div>
                </div>
                <div className='card-full margin-b-40'>
                    {chartData && <Bar data={chartData} options={{
                        maintainAspectRatio: false,
                        plugins: {
                            legend: {
                                display: true,
                                position: 'bottom',
                            },
                        },
                    }} />}
                </div>
            </div>
        </div>
    );
};

export default GraphIndexHF;
