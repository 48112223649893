import React, { useState } from 'react';
import SidebarUser from "../../components/user_space/SidebarUser";
import PasswordChange from "../../components/user_space/PasswordChange";
import colors from '../../assets/colors';
import Header from "../../components/Header";
import "../../styles/Card.css";


const UserParamSecu = () => {

  return (
    <div style={{ backgroundColor: colors.background }} className='container-home'>
      <SidebarUser />
  
      <div className="container-right">
        <Header />
        <h3>Gérer son mot de passe</h3>
        <div className='card'>
            <PasswordChange/>
        </div>

      </div> 
    </div>
  );
}

export default UserParamSecu;