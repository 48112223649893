import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Sidebar from '../../../components/Sidebar';
import Header from '../../../components/Header';
import KPISection from '../../../components/hc/KPISection';
import FuturePositionForm from '../../../components/hc/FuturePositionForm';
import FuturePositionsTable from '../../../components/hc/FuturePositionsTable';

const HCScenarioFin = () => {
    const { scenarioId } = useParams();
    const [futurePositions, setFuturePositions] = useState([]);
    const [equipes, setEquipes] = useState([]);
    const [masseSalarialeChargee, setMasseSalarialeChargee] = useState(0);
    const [nombrePositions, setNombrePositions] = useState(0);
    const [budget, setBudget] = useState(0); // Ajout pour stocker le budget
    const [error, setError] = useState('');
    const [formData, setFormData] = useState({
        id: '',
        job: '',
        salaryCharged: '',
        arrivalMonth: '',
        teamId: '',
    });
    const [successMessage, setSuccessMessage] = useState('');
    const [isEditMode, setIsEditMode] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);

    const userData = JSON.parse(localStorage.getItem('userData'));
    const entrepriseId = userData?.entreprise_id;
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    // Fetch future positions
    useEffect(() => {
        const fetchFuturePositions = async () => {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            const query = `
                query ($scenarioId: Int!) {
                    futureOpenPosition(scenarioId: $scenarioId) {
                        id
                        job
                        salaryCharged
                        arrivalMonth
                        team {
                            id
                            nomEquipe
                        }
                        entreprise {
                            id
                            nomEntreprise
                        }
                    }
                }
            `;
            const variables = { scenarioId: parseInt(scenarioId, 10) };

            try {
                const response = await fetch(`${apiUrl}/graphql/`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Token ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ query, variables }),
                });

                const responseData = await response.json();
                if (responseData.errors) {
                    setError(`Error: ${responseData.errors[0].message}`);
                    return;
                }
                setFuturePositions(responseData.data.futureOpenPosition || []);
            } catch (error) {
                setError(`Error fetching future open positions: ${error.message}`);
            }
        };

        fetchFuturePositions();
    }, [scenarioId]);

    // Fetch teams
    useEffect(() => {
        const fetchEquipes = async () => {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            const query = `
                query equipes($entrepriseId: Int!) {
                    equipes(entrepriseId: $entrepriseId) {
                        id
                        nomEquipe
                    }
                }
            `;
            const variables = { entrepriseId: parseInt(entrepriseId, 10) };

            try {
                const response = await fetch(`${apiUrl}/graphql/`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Token ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ query, variables }),
                });

                const responseData = await response.json();
                if (responseData.errors) {
                    setError(`Error: ${responseData.errors[0].message}`);
                    return;
                }
                setEquipes(responseData.data.equipes || []);
            } catch (error) {
                setError(`Error fetching teams: ${error.message}`);
            }
        };

        fetchEquipes();
    }, [entrepriseId]);
    const fetchMasseSalarialeChargee = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        const query = `
             query masseSalarialeChargee($scenarioId: Int!, $entrepriseId: Int!, $startDate : String!, $endDate : String!) {
                masseSalarialeChargee(scenarioId: $scenarioId, entrepriseId: $entrepriseId, startDate:$startDate, endDate: $endDate) {
                    masseSalarialeChargee
                    nombrePositions
                }
            }
        `;
        const variables = {
            scenarioId: parseInt(scenarioId, 10),
            entrepriseId: entrepriseId,
            startDate: "2025-01-01",
            endDate: "2025-12-31",
        };

        try {
            const response = await fetch(`${apiUrl}/graphql/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ query, variables }),
            });

            const responseData = await response.json();
            if (responseData.errors) {
                setError(`Error: ${responseData.errors[0].message}`);
                return;
            }

            const masseData = responseData.data?.masseSalarialeChargee;
            if (masseData) {
                setMasseSalarialeChargee(masseData.masseSalarialeChargee);
                setNombrePositions(masseData.nombrePositions);
            }
        } catch (error) {
            setError(`Error fetching masse salariale chargee: ${error.message}`);
        }
    };

    // Fetch salary and position metrics
    useEffect(() => {

        fetchMasseSalarialeChargee();
    }, [scenarioId]);

    // Fetch budget for the scenario
    useEffect(() => {
        const fetchBudget = async () => {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            const query = `
                query scenarios($scenarioId: Int!) {
                    scenarios(scenarioId: $scenarioId) {
                        budget
                    }
                }
            `;
            const variables = { scenarioId: parseInt(scenarioId, 10) };

            try {
                const response = await fetch(`${apiUrl}/graphql/`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Token ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ query, variables }),
                });

                const responseData = await response.json();
                if (responseData.errors) {
                    setError(`Error: ${responseData.errors[0].message}`);
                    return;
                }


                const budgetData = responseData.data?.scenarios?.[0]?.budget;
                if (budgetData) {
                    setBudget(budgetData);
                }
            } catch (error) {
                setError(`Error fetching budget: ${error.message}`);
            }
        };

        fetchBudget();
    }, [scenarioId]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'salaryCharged' && parseFloat(value) < 0) {
            setError('Le salaire ne peut pas être négatif.');
            return;
        }
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleCheckboxChange = (id) => {
        setSelectedRows((prev) =>
            prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
        );
    };
    const handleRowClick = (position) => {
        setFormData({
            id: position.id,
            job: position.job,
            salaryCharged: position.salaryCharged,
            arrivalMonth: position.arrivalMonth,
            teamId: position.team?.id || '',
        });
        setIsEditMode(true); // Passe en mode édition
        setIsModalOpen(true); // Ouvre la modale
    };
    const handleDeleteSelected = async () => {
        if (selectedRows.length === 0) {
            setError("Aucune ligne sélectionnée pour la suppression.");
            return;
        }
    
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        const mutation = `
            mutation DeleteFutureOpenPositions($ids: [Int!]!) {
                deleteFutureOpenPositions(ids: $ids) {
                    success
                    message
                }
            }
        `;

        const variables = {
            ids: selectedRows.map(id => parseInt(id, 10)) // Assurez-vous que les IDs sont des entiers
        };
    
        try {
            const response = await fetch(`${apiUrl}/graphql/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ query: mutation, variables }),
            });
    
            const responseData = await response.json();
            if (responseData.errors) {
                setError(`Erreur: ${responseData.errors[0].message}`);
                return;
            }
    
            const success = responseData.data?.deleteFutureOpenPositions?.success;
            if (success) {
                setSuccessMessage("Les positions sélectionnées ont été supprimées avec succès !");
                setFuturePositions((prev) =>
                    prev.filter((position) => !selectedRows.includes(position.id))
                );
                setSelectedRows([]); // Réinitialise les lignes sélectionnées
                fetchMasseSalarialeChargee(); 
            } else {
                setError(responseData.data?.deleteFutureOpenPositions?.message || "Erreur inconnue.");
            }
        } catch (error) {
            setError(`Erreur lors de la suppression: ${error.message}`);
        }
    };
    
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');

        const mutation = isEditMode
            ? `
            mutation UpdateFutureOpenPosition($id: Int!, $job: String!, $salaryCharged: Decimal!, $arrivalMonth: Date!, $teamId: Int!) {
                updateFutureOpenPosition(id: $id, job: $job, salaryCharged: $salaryCharged, arrivalMonth: $arrivalMonth, teamId: $teamId) {
                    futureOpenPosition {
                        id
                        job
                        salaryCharged
                        arrivalMonth
                        team {
                            id
                            nomEquipe
                        }
                    }
                }
            }`
            : `
            mutation CreateFutureOpenPosition($scenarioId: Int!, $job: String!, $entrepriseId: Int!, $salaryCharged: Decimal!, $arrivalMonth: Date!, $teamId: Int!) {
                createFutureOpenPosition(scenarioId: $scenarioId, job: $job, entrepriseId: $entrepriseId, salaryCharged: $salaryCharged, arrivalMonth: $arrivalMonth, teamId: $teamId) {
                    futureOpenPosition {
                        id
                        job
                        salaryCharged
                        arrivalMonth
                        team {
                            id
                            nomEquipe
                        }
                    }
                }
            }`;

        const variables = {
            ...(isEditMode ? { id: parseInt(formData.id, 10) } : { scenarioId: parseInt(scenarioId, 10) }),
            job: formData.job,
            entrepriseId: entrepriseId,
            salaryCharged: parseFloat(formData.salaryCharged),
            arrivalMonth: formData.arrivalMonth,
            teamId: parseInt(formData.teamId, 10),
        };

        try {
            const response = await fetch(`${apiUrl}/graphql/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ query: mutation, variables }),
            });

            const responseData = await response.json();
            if (responseData.errors) {
                setError(`Error: ${responseData.errors[0].message}`);
                return;
            }

            setSuccessMessage(isEditMode ? 'Position updated successfully!' : 'Position created successfully!');
            setFuturePositions((prev) => {
                const updatedPosition = responseData.data[
                    isEditMode ? 'updateFutureOpenPosition' : 'createFutureOpenPosition'
                ].futureOpenPosition;
                return isEditMode
                    ? prev.map((position) => (position.id === updatedPosition.id ? updatedPosition : position))
                    : [...prev, updatedPosition];
            });
            setIsEditMode(false);
            setFormData({ id: '', job: '', salaryCharged: '', arrivalMonth: '', teamId: '' });
            handleCloseModal();
            fetchMasseSalarialeChargee();
        } catch (error) {
            setError(`Error saving position: ${error.message}`);
        }
    };
    return (
        <div className="container-home">

            <Sidebar initiallyCollapsed={true} />
            <div className="container-right">
                <Header />
                <div className="line-container-10">
                    <KPISection
                        masseSalarialeChargee={masseSalarialeChargee}
                        nombrePositions={nombrePositions}
                        budget={budget}
                    />
                    {error && <p className="error-message">{error}</p>}
                    {successMessage && <p className="success-message">{successMessage}</p>}
                    <div className="actions-container">
                    
                    {selectedRows.length === 0 ? (
                            <button
                                type="submit"
                                onClick={() => {
                                    setFormData({
                                        id: '',
                                        job: '',
                                        salaryCharged: '',
                                        arrivalMonth: '',
                                        teamId: '',
                                    });
                                    setIsEditMode(false); // Mode création
                                    handleOpenModal(); // Ouvre la modale
                                }}
                            >
                                Add open position
                            </button>
                        ) : (
                            <div className='toolbar'>
                                <span>{selectedRows.length} row(s) selected</span>
                                <button
                                onClick={handleDeleteSelected}>
                                Delete
                            </button>
                             </div>

                        )}

                    </div>
                    <FuturePositionsTable
                        futurePositions={futurePositions}
                        selectedRows={selectedRows}
                        onRowClick={handleRowClick}
                        onCheckboxChange={handleCheckboxChange}
                    />
                    {isModalOpen && (
                        <FuturePositionForm
                            isEditMode={isEditMode}
                            formData={formData}
                            equipes={equipes}
                            onInputChange={handleInputChange}
                            onSubmit={handleFormSubmit}
                            onClose={handleCloseModal} // Fermer la modale
                        />
                    )}

                </div>
            </div>
        </div>
    );
};

export default HCScenarioFin;
