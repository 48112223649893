import React, { useState, useEffect } from 'react';
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import colors from '../../assets/colors';
import "../../styles/Home.css";
import "../../styles/Tree.css";

const Hibob = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');

    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = useState(false); // Initialiser à false
    const [error, setError] = useState(null);
    const [allDataLoading, setAllDataLoading] = useState(false); // État de chargement pour l'appel à tous les employés
    const  [loadingemploi, setLoadingemploi] = useState(false); // Initialiser à false
    useEffect(() => {
        // Vérifier si l'ID de l'entreprise est stocké dans le localStorage
        const storedUserData = localStorage.getItem('userData');
        let entrepriseId = null;

        if (storedUserData) {
            const userData = JSON.parse(storedUserData);
            entrepriseId = userData.entreprise_id;  // Récupérer l'ID de l'entreprise depuis userData
        }

        if (!entrepriseId) {
            setError(new Error('L\'ID de l\'entreprise est manquant dans les données utilisateur.'));
            return;
        }

        // Charger les employés depuis le localStorage si disponibles
        const storedEmployees = localStorage.getItem('Employes');

        if (storedEmployees) {
            const parsedData = JSON.parse(storedEmployees);
            setEmployees(parsedData.clean_data); // Utiliser 'clean_data' comme vous l'avez fait
        }
    }, []);

    const handleFetchEmployees = async () => {
        setLoading(true); // Début du chargement
        setError(null); // Réinitialiser les erreurs

        // Récupérer l'ID de l'entreprise depuis le localStorage
        const storedUserData = localStorage.getItem('userData');
        let entrepriseId = null;

        if (storedUserData) {
            const userData = JSON.parse(storedUserData);
            entrepriseId = userData.entreprise_id;
        }

        if (!entrepriseId) {
            setError(new Error('L\'ID de l\'entreprise est manquant dans les données utilisateur.'));
            setLoading(false);
            return;
        }

        try {
            const response = await fetch(`${apiUrl}/api/hibob/octo/employes/?entreprise_id=${entrepriseId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error('Erreur lors de la récupération des données');
            }
            const jsonData = await response.json();

            setEmployees(jsonData.clean_data); // Utiliser 'clean_data'

            // Stocker les employés dans le localStorage
            localStorage.setItem('Employes', JSON.stringify(jsonData));
        } catch (error) {
            console.error('Erreur lors du fetch des données :', error);
            setError(error);
        } finally {
            setLoading(false); // Fin du chargement
        }
    };

    const handleFetchHistoEmplois = async () => {
        setLoading(true); // Début du chargement
        setError(null); // Réinitialiser les erreurs

        // Récupérer l'ID de l'entreprise depuis le localStorage
        const storedUserData = localStorage.getItem('userData');
        let entrepriseId = null;

        if (storedUserData) {
            const userData = JSON.parse(storedUserData);
            entrepriseId = userData.entreprise_id;
        }

        if (!entrepriseId) {
            setError(new Error('L\'ID de l\'entreprise est manquant dans les données utilisateur.'));
            setLoadingemploi(false);
            return;
        }

        try {
            const response = await fetch(`${apiUrl}/api/hibob/octo/histoemploi/?entreprise_id=${entrepriseId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
                throw new Error('Erreur lors de la récupération des données');
            }
            const jsonData = await response.json();

            setEmployees(jsonData.clean_data); // Utiliser 'clean_data'

            // Stocker les employés dans le localStorage
            localStorage.setItem('HistoEmploi', JSON.stringify(jsonData));
        } catch (error) {
            console.error('Erreur lors du fetch des données :', error);
            setError(error);
        } finally {
            setLoading(false); // Fin du chargement
        }
    };

    return (
        <div style={{ backgroundColor: colors.background }} className='container-home'>
            <Sidebar />
            <div className="container-right">
                <Header />
                <div style={{ padding: '20px' }}>
                    <button 
                        onClick={handleFetchEmployees} 
                        style={{
                            padding: '10px 20px',
                            backgroundColor: colors.primary, 
                            color: '#fff', 
                            border: 'none', 
                            borderRadius: '5px',
                            cursor: 'pointer'
                        }}
                        disabled={loading} // Désactiver le bouton pendant le chargement
                    >
                        {loading ? 'Chargement...' : 'Charger les employés'}
                    </button>

                    <button 
                        onClick={handleFetchHistoEmplois} 
                      
                        type='submit'
                    >
                        {loadingemploi ? 'Chargement...' : 'Historique emplois'}
                    </button>
                </div>

                {error && (
                    <p style={{ color: 'red', marginTop: '10px' }}>Erreur : {error.message}</p>
                )}
                {!loading && employees && employees.length > 0 && (
                    <div>
                        <h2>Liste des employés :</h2>
                        <table>
                            <thead>
                                <tr>
                                    <th>Nom</th>
                                    <th>Département</th>
                                    <th>Début</th>
                                    <th>Titre</th>
                                    <th>Équipe</th>
                                </tr>
                            </thead>
                            <tbody>
                                {employees.map((employee, index) => (
                                    <tr key={index}>
                                        <td>{employee.firstname}</td>
                                        <td>{employee.department}</td>
                                        <td>{employee.originalStartDate}</td>
                                        <td>{employee.title}</td>
                                        <td>{employee.team}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
                {!loading && (!employees || employees.length === 0) && !error && (
                    <p>Aucun employé disponible.</p>
                )}
            </div>
        </div>
    );
};

export default Hibob;
