import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';

import '../../styles/Card.css';
import '../../styles/Forms.css';
import Sidebar from "../../components/Sidebar";
import colors from '../../assets/colors';
import Header from "../../components/Header";
import ImportComponent from '../../components/ImportComponent';

const CreateEmployes = () => {
  const [csvFile, setCsvFile] = useState(null);
  const [message, setMessage] = useState('');
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (event) => {
    setCsvFile(event.target.files[0]);
    setMessage('');
    setIsSuccessful(false);

  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (!csvFile) {
      setMessage('Veuillez sélectionner un fichier CSV avant de soumettre.');
      return;
    }

    const userData = JSON.parse(localStorage.getItem('userData'));
    const entrepriseId = userData ? userData.entreprise_id : null;
    if (!entrepriseId) {
      setMessage("ID de l'entreprise manquant dans les données stockées.");
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append('csv_file', csvFile);
    formData.append('entreprise_id', entrepriseId);

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const token = localStorage.getItem('token');
      const response = await fetch(`${apiUrl}/api/load-data/`, {
        method: 'POST',
        headers: { 'Authorization': `Token ${token}` },
        body: formData,
      });

      const result = await response.json();

      setTimeout(() => {
        if (response.ok) {
          setMessage('Employés créés avec succès.');
          setIsSuccessful(true);
        } else {
          const errorMessage = result.message || result.error || 'Une erreur inattendue est survenue.';
          setMessage(`Erreur lors de la création des employés: ${errorMessage}`);
          setIsSuccessful(false);
        }
        setIsLoading(false);
      }, 2500); // Minimum 2 secondes de chargement

    } catch (error) {
      setTimeout(() => {
        setMessage(`Erreur de communication avec le serveur: ${error.message}`);
        setIsSuccessful(false);
        setIsLoading(false);
      }, 2500);
    }
  };

  return (
    <div style={{ backgroundColor: colors.background }} className='container-home'>
      <Sidebar />
      <div className='container-right'>
      
        <Header />
        <div className="card-full">
          {isLoading ? (
            <h4>Nous mettons à jour vos données...</h4>
          ) : isSuccessful ? (
            <h4>{message}</h4>
          ):
          <h4>Mettez vos données à jour avec un fichier csv</h4>}
          <form className="form-column" onSubmit={handleFormSubmit}>
            <div className="form-group">
              {isLoading ? null : 
              isSuccessful ? null : (
                <input 
                  type='file' 
                  accept='.csv' 
                  onChange={handleFileChange} 
                  disabled={isLoading}
                />
              )}
              {isLoading ? (
                <FontAwesomeIcon icon={faSpinner} className="spinner-icon" spin />
              ) : isSuccessful ? (
                <FontAwesomeIcon icon={faCheckCircle} className="success-icon" />
              ) : null}
            </div>
            {isLoading ? null : 
            isSuccessful ? null : (
              <button type='submit' disabled={isLoading}>Envoyer</button>
            )}
          
          </form>
        </div>
      
        <div className="csv-format-explanation">

          
        </div>
        {isSuccessful && <ImportComponent />}
      </div>
      
    </div>
  );
};

export default CreateEmployes;
