import React, { useState, useEffect } from 'react';
import Sidebar from "../../components/Sidebar";
import colors from '../../assets/colors';
import Header from "../../components/Header";
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js';
import { faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "../../styles/Home.css";
import "../../styles/Card.css";
import "../../styles/Graph.css";
import OverviewCard from '../../components/cards/OverviewCard';

// Configuration pour ChartJS
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);


const GraphTurnoverRate = () => {
    const [chartData, setChartData] = useState(null);
    const [groupOption, setGroupOption] = useState('Equipe'); // Option de regroupement initial
    const promptContext = "Tu vas recevoir des données qui représentent le taux de turnover de l'entreprise. Elles sont group by différentes propriétés.";
    
    // Fonction pour traiter les données et mettre à jour le graphique
    const processChartData = (data) => {
        let groupData = {};
    
        if (groupOption === 'Global') {
            if (data.Sexe && data.Sexe["Non spécifié"]) {
                groupData['Global'] = data.Sexe["Non spécifié"];
            } else {
                console.error("Données pour 'Global' (Non spécifié) sont manquantes");
                return;
            }
        } else {
            if (data[groupOption]) {
                groupData = data[groupOption];
            } else {
                console.error(`Les données pour l'option ${groupOption} sont manquantes`);
                return;
            }
        }
    
        let labels = [];
        let datasets = [];

        if (groupOption === 'Global') {
            labels = groupData['Global'].map(item => item.periode);
            datasets.push({
                label: 'Effectif',
                data: groupData['Global'].map(item => parseFloat(item.taux_turnover)),
                fill: false,
                borderColor: generateColor(),
                tension: 0.1
            });
        } else {
            const allData = Object.values(groupData).flat();
            labels = allData.map(item => item.periode)
                            .filter((value, index, self) => self.indexOf(value) === index)
                            .sort((a, b) => new Date(a) - new Date(b));
            
            Object.keys(groupData).forEach(category => {
                datasets.push({
                    label: category,
                    data: labels.map(label => {
                        const item = groupData[category].find(item => item.periode === label);
                        return item ? parseFloat(item.taux_turnover) : 0;
                    }),
                    fill: false,
                    borderColor: generateColor(),
                    tension: 0.1
                });
            });
        }
    
        setChartData({
            labels: labels,
            datasets: datasets
        });
    };
    
    // Générateur de couleur aléatoire
    const generateColor = () => {
        const colorPalette = [
            '#CC3C28', '#d74633', '#9c7ca5', '#3e92cc', '#13293d', '#a40e4c',
            '#412234', '#ff7d00', '#e3655b', '#960200', '#5ab1bb', '#7d83ff', '#d62828', '#093824'
        ];
        const randomIndex = Math.floor(Math.random() * colorPalette.length);
        return colorPalette[randomIndex];
    };
    
    useEffect(() => {
        const fetchData = async () => {
            const storageKey = 'GraphTurnoverRate';
            const cachedData = localStorage.getItem(storageKey);
            const oneDay = 24 * 60 * 60 * 1000;
            if (cachedData) {
                const { data, timestamp } = JSON.parse(cachedData);
                if (new Date() - new Date(timestamp) < oneDay) {

                    processChartData(data);
                    return;
                }
            }
    
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            let url = `${apiUrl}/api/turnover/all`;
    
            try {
                const response = await fetch(url, {
                    headers: {
                        'Authorization': `Token ${token}`,
                    },
                });
    
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données d\'effectif');
                }
    
                const data = await response.json();

                localStorage.setItem(storageKey, JSON.stringify({ data, timestamp: new Date() }));
                processChartData(data);
            } catch (error) {
                console.error('Erreur lors de la récupération des données d\'effectif', error);
            }
        };
    
        fetchData();
    }, [groupOption]);
    
    const handleGroupChange = (event) => {
        setGroupOption(event.target.value);
    };

    return (
        <div style={{ backgroundColor: colors.background }} className='container-home'>
            <Sidebar />
            <div className="container-right">
                <Header />
                <OverviewCard overviewKey={'OverviewGraphTurnoverRate'}  storageKey={'GraphTurnoverRate'} promptContext={promptContext}/>
                <div className='card-full'>
                    <div className='display-line'>
                        <FontAwesomeIcon icon={faPeopleGroup} />
                        <h5>Grouper par :</h5>
                        <select className="custom-select" onChange={handleGroupChange} value={groupOption}>
                            <option value="Equipe">Équipe</option>
                            <option value="Global">Vue Globale</option>
                            <option value="Âge">Âge</option>
                            <option value="Ancienneté">Ancienneté</option>
                            <option value="Sexe">Sexe</option>
                        </select>
                    </div>
                </div>
                <div className='card-full margin-b-40'>
                    {chartData && <Line data={chartData} options={{
                        maintainAspectRatio: false,
                        plugins: {
                            legend: {
                                display: true,
                                position: 'bottom',
                            },
                        },
                    }} />}
                </div>
            </div>
        </div>
    );
};

export default GraphTurnoverRate;
