import React, { useState } from 'react';

const IncreaseSelector = ({ initialValue = 0, onValueChange }) => {
    const [value, setValue] = useState(initialValue);

    const handleIncrease = () => {
        const newValue = value + 1;
        setValue(newValue);
        onValueChange(newValue);
    };
    
    const handleDecrease = () => {
        const newValue = value > 0 ? value - 1 : 0;
        setValue(newValue);
        onValueChange(newValue);
    };

    return (
        <div className="increase-selector">
            <button onClick={handleDecrease}>-</button>
            <span>{value}</span>
            <button onClick={handleIncrease}>+</button>
        </div>
    );
};

export default IncreaseSelector;
