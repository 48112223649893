import React, { useState, useEffect } from 'react';
import '../../styles/Card.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';

const OverviewCard = ({ storageKey, overviewKey, promptContext }) => {
    const defaultContent = 'Analysez vos données ici !';
    const initialContent = localStorage.getItem(overviewKey) || defaultContent;
    const [overviewContent, setOverviewContent] = useState(initialContent);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isFetching, setIsFetching] = useState(false);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setIsFetching(false);
    }, [storageKey, overviewKey]);

    const fetchAnalysis = async () => {
        setIsFetching(true);
        const savedData = localStorage.getItem(storageKey);
        if (!savedData) {
            console.error('No saved data available');
            setOverviewContent('No data available');
            setIsFetching(false);
            return;
        }

        setOverviewContent('Nous analysons vos données...');
        localStorage.setItem(overviewKey, 'Chargement des données...');

        const analysisUrl = `${apiUrl}/api/overview/data/`;
        const dataToSend = {
            savedData: savedData,
            promptContext: promptContext
        };

        try {
            const analysisResponse = await fetch(analysisUrl, {
                credentials: 'include',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(dataToSend)
            });

            if (!analysisResponse.ok) {
                throw new Error('Erreur lors de la récupération de l\'analyse');
            }

            const analysisData = await analysisResponse.json();
            setOverviewContent(analysisData.response);
            localStorage.setItem(overviewKey, analysisData.response);
        } catch (error) {
            console.error('Erreur lors de la récupération de l\'analyse', error);
            setOverviewContent('Erreur de chargement');
        } finally {
            setIsFetching(false);
        }
    };

    const handleRefresh = () => {
        if (localStorage.getItem(storageKey)) {
            localStorage.removeItem(overviewKey);
            fetchAnalysis();
        } else {
            console.error('No saved data to refresh');
        }
    };

    const handleToggle = () => {
        setIsExpanded(!isExpanded);
    };

    const cardStyle = isFetching ? { animation: 'pulse 2s infinite' } : {};

    return (
        <div className="card-full ia clickable" onClick={handleToggle} style={cardStyle}>
            <div className='display-line'>
                {!isFetching && overviewContent.length < 50 && (
                    <button type='submit' onClick={handleRefresh}>
                        Déclencher l'analyse
                    </button>
                )}
                {overviewContent.length > 50 && (
                    <FontAwesomeIcon className='icon-edit' icon={faSync} onClick={handleRefresh}/>
                )}
                <p>{(isExpanded || overviewContent.length <= 80) ? overviewContent : overviewContent.substring(0, 80) + '...'}</p>
            </div>
        </div>
    );
};

export default OverviewCard;
