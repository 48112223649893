import React, { useState } from 'react';

const FuturePositionsTable = ({ futurePositions, selectedRows, onRowClick, onCheckboxChange }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const itemsPerPage = 15;

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedPositions = [...futurePositions].sort((a, b) => {
        if (sortConfig.key) {
            let aValue = a[sortConfig.key];
            let bValue = b[sortConfig.key];

            if (sortConfig.key === 'team') {
                aValue = a.team?.nomEquipe || '';
                bValue = b.team?.nomEquipe || '';
            }

            if (aValue < bValue) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (aValue > bValue) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
        }
        return 0;
    });

    const paginatedPositions = sortedPositions.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const totalPages = Math.ceil(futurePositions.length / itemsPerPage);

    const goToPreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const goToNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    return (
        <div className="table-container">
            <table className="positions-table">
                <thead>
                    <tr>
                        <th>Select</th>
                        <th onClick={() => handleSort('arrivalMonth')}>Start date</th>
                        <th onClick={() => handleSort('job')}>Position title</th>
                        <th onClick={() => handleSort('team')}>Team</th>
                        <th onClick={() => handleSort('salaryCharged')}>Salary Charged</th>
                    </tr>
                </thead>
                <tbody>
                    {paginatedPositions.map((position) => (
                        <tr
                            key={position.id}
                            className={`clickable ${selectedRows.includes(position.id) ? 'selected' : ''}`}
                            onClick={() => onRowClick(position)}
                        >
                            <td>
                                <input
                                    type="checkbox"
                                    checked={selectedRows.includes(position.id)}
                                    onChange={() => onCheckboxChange(position.id)}
                                    onClick={(e) => e.stopPropagation()}
                                />
                            </td>
                            <td>
                                {position.arrivalMonth
                                    ? new Date(position.arrivalMonth).toLocaleDateString('en-US', {
                                        month: '2-digit',
                                        year: '2-digit',
                                    })
                                    : 'N/A'}
                            </td>
                            <td>{position.job}</td>
                            <td>{position.team?.nomEquipe || 'N/A'}</td>
                            <td>
                                {parseFloat(position.salaryCharged).toLocaleString('fr-FR', {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                })}{' '}€
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="hc-pagination-control">
                <button onClick={goToPreviousPage} disabled={currentPage === 1}>
                    Previous
                </button>
                <span>
                    Page {currentPage} of {totalPages}
                </span>
                <button onClick={goToNextPage} disabled={currentPage === totalPages}>
                    Next
                </button>
            </div>
        </div>
    );
};

export default FuturePositionsTable;
