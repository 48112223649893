import React, { useState, useEffect } from 'react';
import InsightCard from '../../cards/InsightCard';
import { Bar } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import amplitude from 'amplitude-js';

const InsightProgressTA = () => {
    const currentYear = new Date().getFullYear();
    const defaultStartDate = `${currentYear}-01-01`;
    const defaultEndDate = `${currentYear}-12-31`;

    const [data, setData] = useState([]);
    const [lastRefreshDate, setLastRefreshDate] = useState('');
    const navigate = useNavigate();
    const storedUserData = localStorage.getItem('userData');
    const ampData = storedUserData ? JSON.parse(storedUserData) : {};

    const handleOnClick = () => {
        amplitude.getInstance().logEvent('OverviewCardClicked', {
            username: ampData.username || 'unknown',
            graph: 'Insight Progress TA',
        });
        navigate('/staffingplan/insight/groupbyta');
    };

    const fetchData = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            const userData = JSON.parse(localStorage.getItem('userData'));
            const entrepriseId = userData?.entreprise_id;

            if (!entrepriseId) {
                console.error('Entreprise ID not found in userData');
                return;
            }

            const query = `
                query ($entrepriseId: Int!, $startDateOpening: Date!, $endDateOpening: Date!) {
                    openPositions(
                        entrepriseId: $entrepriseId,
                        startDateOpening: $startDateOpening,
                        endDateOpening: $endDateOpening
                    ) {
                        recruiter {
                            firstName
                        }
                        status
                    }
                }
            `;

            const variables = {
                entrepriseId,
                startDateOpening: defaultStartDate,
                endDateOpening: defaultEndDate,
            };

            const response = await fetch(`${apiUrl}/graphql/`, {
                method: 'POST',
                headers: {
                    Authorization: `Token ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ query, variables }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const responseData = await response.json();

            if (responseData.errors) {
                console.error('GraphQL errors:', responseData.errors);
                return;
            }

            const now = new Date().toISOString();
            const dataToStore = {
                data: responseData.data.openPositions,
                lastUpdated: now,
            };

            localStorage.setItem('InsightProgressTA', JSON.stringify(dataToStore));
            setData(responseData.data.openPositions);
            setLastRefreshDate(now);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        try {
            const storedData = localStorage.getItem('InsightProgressTA');
            if (storedData) {
                const { data, lastUpdated } = JSON.parse(storedData);
                setData(data);
                setLastRefreshDate(lastUpdated);
            } else {
                fetchData();
            }
        } catch (error) {
            console.error('Error loading data from localStorage:', error);
            fetchData();
        }
    }, []);

    const processData = () => {
        const groupedData = data.reduce((acc, position) => {
            const recruiterName = position.recruiter?.firstName || 'Unknown';
            if (!acc[recruiterName]) {
                acc[recruiterName] = { signed: 0, toSign: 0 };
            }
            if (position.status?.includes('6') || position.status?.includes('7')) {
                acc[recruiterName].signed++;
            } else {
                acc[recruiterName].toSign++;
            }
            return acc;
        }, {});
    
        // Convertir les données groupées en un tableau triable
        const sortedRecruiters = Object.entries(groupedData)
            .map(([recruiter, counts]) => ({
                recruiter,
                signed: counts.signed,
                toSign: counts.toSign,
                total: counts.signed + counts.toSign,
            }))
            .sort((a, b) => b.total - a.total) // Trier par total décroissant
            .slice(0, 4); // Limiter à 4 recruteurs
    
        // Préparer les données pour le graphique
        const labels = sortedRecruiters.map(item => item.recruiter);
        const signedData = sortedRecruiters.map(item => item.signed);
        const toSignData = sortedRecruiters.map(item => item.toSign);
    
        return {
            labels,
            datasets: [
                {
                    label: 'Signed',
                    data: signedData,
                    backgroundColor: 'rgba(54, 162, 235, 0.6)',
                    borderColor: 'rgba(54, 162, 235, 1)',
                    borderWidth: 1,
                },
                {
                    label: 'To Sign',
                    data: toSignData,
                    backgroundColor: 'rgba(255, 99, 132, 0.6)',
                    borderColor: 'rgba(255, 99, 132, 1)',
                    borderWidth: 1,
                },
            ],
        };
    };
    
    const chartData = processData();
    const chartOptions = {
        indexAxis: 'y', // Passe le graphique en format horizontal
        scales: {
            x: {
                stacked: true, // Empiler les barres sur l'axe X
                display: false, // Cache les graduations et le fond de l'axe X
                grid: {
                    display: false, // Cache les lignes de grille sur l'axe X
                }
            },
            y: {
                stacked: true,
                beginAtZero: true,
                grid: {
                    display: false, // Cache les lignes de grille sur l'axe Y
                }
            }
        },
        responsive: true,
        plugins: {
            legend: {
                display: false // Affiche la légende pour distinguer les deux types de données
            }
        },
        elements: {
            bar: {
                borderWidth: 0, // Cache les bordures des barres si nécessaire
            }
        }
    };

    return (
        <div>
            <InsightCard
                text="Recruitment Progress by Recruiter"
                subtext={`Last Updated: ${new Date(lastRefreshDate).toLocaleString() || 'Never'}`}
                data={<Bar data={chartData} options={chartOptions} />}
                onClick={handleOnClick}
            />
        </div>
    );
};

export default InsightProgressTA;
