import React, { useState, useEffect } from 'react';



const CreationEnvoi = () => {
  const [email, setEmail] = useState('');
  const [companyId, setCompanyId] = useState(''); // Ajout d'un état pour l'ID de l'entreprise
  const [csrfToken, setCsrfToken] = useState('');

  useEffect(() => {
    fetchCsrfToken();
  }, []);

  const fetchCsrfToken = async () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    try {
      const response = await fetch(`${apiUrl}/set-csrf-token/`, {
        method: 'GET',
        credentials: 'include',
      });
      const token = extractCsrfTokenFromCookies();
      if (token) {
        setCsrfToken(token);
      }
    } catch (error) {
      console.error("Erreur lors de la définition du token CSRF:", error);
    }
  };

  const extractCsrfTokenFromCookies = () => {
    const csrfCookieName = 'csrftoken=';
    const csrfCookie = document.cookie.split(';').find(cookie => cookie.trim().startsWith(csrfCookieName));
    return csrfCookie ? decodeURIComponent(csrfCookie.split('=')[1]) : null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new URLSearchParams();
      formData.append('recipient_email', email);
      formData.append('company_id', companyId); // Ajouter l'ID de l'entreprise aux données du formulaire

      const response = await fetch('https://back.polare.fr/api/send-invitation/', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-CSRFToken': csrfToken
        },
        body: formData
      });

      if (response.ok) {
        alert('Email d\'invitation envoyé avec succès.');
      } else {
        alert('Erreur lors de l\'envoi de l\'email.');
      }
    } catch (error) {
      console.error('Erreur lors de l\'envoi:', error);
      alert('Une erreur est survenue lors de l\'envoi de l\'email.');
    }
  };

  return (
    <div className='container-center padding-100'>
      
      <h4>Envoyer une Invitation pour Création de Compte</h4>
      <form className='form-column' onSubmit={handleSubmit}>
        <label className='label-column'>
          Email du destinataire:
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className='form-input'
            required
          />
        </label>
        <label className='label-column'>
          ID de l'entreprise:
          <input
            type="text"
            value={companyId}
            onChange={(e) => setCompanyId(e.target.value)}
            className='form-input'
            required
          />
        </label>
        <button className='btn-primary'>Envoyer l'Invitation</button>
      </form>
    </div>
  );
}

export default CreationEnvoi;
