import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, Filler } from 'chart.js';
import HomeCards from '../../cards/HomeCards'; // Vérifiez le chemin d'accès
import { useNavigate } from 'react-router-dom';

// Configuration initiale de ChartJS
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend, Filler);

const NewArrival = () => {
    const [chartData, setChartData] = useState(null);
    const navigate = useNavigate();
    const [latestEffectif, setLatestEffectif] = useState('Chargement...');

    // Handler pour le clic qui navigue vers une autre page
    const handleOnClick = () => navigate('/graph/effectifnouveaux');



    // Fonction pour traiter et définir les données du graphique
    const processChartData = (data) => {
        if (data && data.Global && Array.isArray(data.Global)) {
            const labels = data.Global.map(item => item.periode);
            const datasets = [{
                label: 'Nouveaux',
                data: data.Global.map(item => parseFloat(item.nouveaux)),
                fill: true,
                backgroundColor: '#EBA49B40', 
                borderColor: '#EBA49B',
                tension: 0.1
            }];
    
            setChartData({
                labels: labels,
                datasets: datasets
            });
        } else {
            console.error('Données "Global" non disponibles ou mal formées');
            setChartData(null);
        }
    };
    

    

    // Effectuer une requête pour récupérer les données
    useEffect(() => {
        const fetchData = async () => {
            const storageKey = 'GraphEffectifVolumeData';
            const cachedData = localStorage.getItem(storageKey);
            const oneDay = 24 * 60 * 60 * 1000; // Cache expire après un jour

            if (cachedData) {
                const { data, timestamp } = JSON.parse(cachedData);
                if (new Date() - new Date(timestamp) < oneDay) {
                    processChartData(data);
                    setLatestEffectif(`${data.Global[data.Global.length - 1].nouveaux} nouveaux`);

                
                    return;
                }
            }

            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const token = localStorage.getItem('token');
                const url = `${apiUrl}/api/graph/effectif`;
                const response = await fetch(url, {
                    headers: { 'Authorization': `Token ${token}` },
                });

                if (!response.ok) throw new Error('Erreur lors de la récupération des données');

                const data = await response.json();
                localStorage.setItem(storageKey, JSON.stringify({ data, timestamp: new Date() }));
                processChartData(data);
                setLatestEffectif(`${data.Global[data.Global.length - 1].nouveaux} nouveaux`);
           
            } catch (error) {
                console.error('Erreur lors de la récupération des données', error);
            }
        };

        fetchData();
    }, []);

    // Options de configuration pour le graphique
    const chartOptions = {
        scales: {
          y: {
            display: false,
            grid: {
              drawBorder: false,
              display: false,
            },
            ticks: {
              display: false,
            }
          },
          x: {
            display: false,
            grid: {
              drawBorder: false,
              display: false,
            },
            ticks: {
              display: false,
            }
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
        elements: {
          point: {
            radius: 0, // Toujours masquer les points
            hoverRadius: 0, // Masquer les points même lors du survol
          },
          line: {
            borderWidth: 3, // Ajustez si nécessaire
          }
        },
        maintainAspectRatio: false,
      
      };
    return (
        <HomeCards
            text="Nombre de nouveaux salariés sur le dernier mois"
            kpi={latestEffectif}
            chart={chartData && <Line data={chartData} options={chartOptions} height={65} width={100} />}
            onClick={handleOnClick}
        />
    );
};

export default NewArrival;
