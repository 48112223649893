import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleGroup, faCheckCircle, faSpinner, faClock } from '@fortawesome/free-solid-svg-icons';


const ReviewStatusCount = () => {
    const [counts, setCounts] = useState({ open: 0, closed: 0, inProgress: 0, pending: 0 });
    const [error, setError] = useState(null);
    const { reviewId } = useParams();
    const token = localStorage.getItem('token');
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${apiUrl}/api/perf/employees_with_reviews/${reviewId}/`, {
                    credentials: 'include',
                    method: 'GET',
                    headers: {
                        'Authorization': `Token ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }

                const data = await response.json();
                const statusCounts = data.reduce((acc, review) => {
                    switch (review.review_status) {
                        case 'Open':
                            acc.open += 1;
                            break;
                        case 'Closed':
                            acc.closed += 1;
                            break;
                        case 'In Progress':
                            acc.inProgress += 1;
                            break;
                        case 'Pending':
                            acc.pending += 1;
                            break;
                        default:
                            break;
                    }
                    return acc;
                }, { open: 0, closed: 0, inProgress: 0, pending: 0 });

                setCounts(statusCounts);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError('Failed to load data. Please try again later.');
            }
        };

        fetchData();
    }, [apiUrl, token, reviewId]);

    return (
        <div className="review-status-count">
            {error ? (
                <div className="error">{error}</div>
            ) : (
                <div className='cards-container'>
                    <div className="status-card">
                        <div className="status-left">
                            <div className="status-icon status-open">
                                <FontAwesomeIcon icon={faPeopleGroup} />
                            </div>
                            <div className="status-label">Open</div>
                        </div>
                        <div className="status-count">{counts.open}</div>
                    </div>
                    <div className="status-card ">
                        <div className="status-left">
                            <div className="status-icon status-closed">
                                <FontAwesomeIcon icon={faCheckCircle} />
                            </div>
                            <div className="status-label">Closed</div>
                        </div>
                        <div className="status-count">{counts.closed}</div>
                    </div>
                    <div className="status-card">
                        <div className="status-left">
                            <div className="status-icon status-in-progress">
                                <FontAwesomeIcon icon={faSpinner} />
                            </div>
                            <div className="status-label">In Progress</div>
                        </div>
                        <div className="status-count">{counts.inProgress}</div>
                    </div>
                    <div className="status-card">
                        <div className="status-left">
                            <div className="status-icon status-pending">
                                <FontAwesomeIcon icon={faClock} />
                            </div>
                            <div className="status-label">Pending</div>
                        </div>
                        <div className="status-count">{counts.pending}</div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ReviewStatusCount;
