import React from 'react';
import { Routes, Route } from 'react-router-dom';
import UserInfos from '../../pages/user/UserInfos';


import UserParamSecu from '../../pages/user/UserParamSecu';


const RoutesUser = () => (
  <Routes>
    <Route path="infos" element={<UserInfos />} />


    <Route path="securite" element={<UserParamSecu />} />

  </Routes>
);

export default RoutesUser;
