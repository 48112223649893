import React, { useState, useEffect } from 'react';
import InsightCard from '../../cards/InsightCard'; // Assurez-vous du bon chemin d'importation
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { useNavigate } from 'react-router-dom';

const InsightAgeDistribution = () => {
    const [ageData, setAgeData] = useState({
        Global: { effectifMoins25: 0, effectif25_35: 0, effectif35_45: 0, effectif45_55: 0, effectifPlus55: 0 }
    });
    const [avgData, setAvgData] = useState({
        Average: { averageAge: 0 }
    });
    const [lastRefreshDate, setLastRefreshDate] = useState('');
    const navigate = useNavigate();

    const handleOnClick = () => {
      navigate('/insight/agedistribution'); 
     };
    const fetchData = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');

            const response = await fetch(`${apiUrl}/api/insight/agedistribution/`, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la récupération des données de distribution de risque');
            }

            const data = await response.json();
            const now = new Date().toISOString();
            const dataToStore = {
                data: data,
                lastUpdated: now
            };

            localStorage.setItem('InsightAgeDistribution', JSON.stringify(dataToStore));
            setAgeData(data);
            setLastRefreshDate(now);
        } catch (error) {
            console.error('Erreur lors de la récupération des données de distribution de risque', error);
        }
    };

    useEffect(() => {
        const storedData = localStorage.getItem('InsightAgeDistribution');
        if (storedData) {
            const { data, timestamp } = JSON.parse(storedData);
            setAgeData(data);
            setAvgData(data);
            setLastRefreshDate(timestamp);
        } else {
            fetchData();
        }
    }, []);

    const chartData = {
        labels: ['25-', '25-35', '35-45', '45-55', '55+'],
        datasets: [{
            label: 'Nombre d\'employés',
            data: [ageData.Global.employe_25moins, ageData.Global.employe_25_35, ageData.Global.employe_35_45, ageData.Global.employe_45_55, ageData.Global.employe_55plus],
            backgroundColor: [
                'rgba(75, 192, 192, 0.6)',
                'rgba(255, 206, 86, 0.6)',
                'rgba(255, 99, 132, 0.6)',
                'rgba(54, 162, 235, 0.6)',
                'rgba(153, 102, 255, 0.6)'
            ],
            borderColor: [
                'rgba(75, 192, 192, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(153, 102, 255, 1)'
            ],
            borderWidth: 1
        }]
    };

    const chartOptions = {
        indexAxis: 'y', // Passe le graphique en format horizontal
        scales: {
            x: {
                display: false, // Cache les graduations et le fond de l'axe X
                grid: {
                    display: false, // Cache les lignes de grille sur l'axe X
                }
            },
            y: {
                beginAtZero: true,
                grid: {
                    display: false, // Cache les lignes de grille sur l'axe Y
                }
            }
        },
        responsive: true,
        plugins: {
            legend: {
                display: false // Cache la légende
            }
        },
        elements: {
            bar: {
                borderWidth: 0, // Cache les bordures des barres si nécessaire
            }
        }
    };

    // Préparation du graphique en barres comme un élément React
    const barChart = (
        <Bar data={chartData} options={chartOptions} height={110} width={300} />
    );

    // Mise à jour du subtext pour afficher le nombre d'employés à risque élevé
    const updatedSubtext = `The average age is${avgData.Average.averageAge.toFixed(0)}ans`;


    return (
        <InsightCard
            text="Employee age distribution"
            subtext={updatedSubtext}
            data={barChart}
            onClick={handleOnClick}
        />
    );
};

export default InsightAgeDistribution;
