import React, { useState, useEffect } from 'react';
import Sidebar from "../../../components/Sidebar";
import colors from '../../../assets/colors';
import Header from "../../../components/Header";
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleGroup, faFileCsv } from '@fortawesome/free-solid-svg-icons';
import "../../../styles/Home.css";
import "../../../styles/Card.css";
import "../../../styles/Graph.css";
import "../../../styles/Chart.css";
import ChartKPICard from '../../../components/cards/ChartKPICard'; // Import du composant KPI
import FiltersComponent from '../../../components/recrutement/chartv2/FiltersComponent';
import ChartWithKPIsComponent from '../../../components/recrutement/chartv2/ChartWithKPIsComponent';
import TableComponent from '../../../components/recrutement/chartv2/TableComponent';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

const formatDate = (date) => {
    const d = new Date(date);
    const month = `${d.getMonth() + 1}`.padStart(2, '0');
    const day = `${d.getDate()}`.padStart(2, '0');
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
};

const GraphStaffCompare = () => {
    const currentYear = new Date().getFullYear();
    const endOfYear = new Date(`${currentYear}-12-31`);
    const lastYear = new Date(`${currentYear}-01-01`);
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [
            { label: 'Current workforce', data: [] },
            { label: 'Forecast workforce', data: [] }
        ]
    });
    const [groupOption, setGroupOption] = useState('month');
    const [startDate, setStartDate] = useState(formatDate(lastYear));
    const [endDate, setEndDate] = useState(formatDate(endOfYear));
    const [filters, setFilters] = useState([]);
    const [currentFilter, setCurrentFilter] = useState({ column: '', value: '', displayValue: '' });
    const [filterOptions, setFilterOptions] = useState({ teams: [], locations: [] });
    const [kpiData, setKpiData] = useState({ currentActual: 0, currentForecast: 0, actualChange: 0, forecastChange: 0, lastPeriod: '' });
    const [openPositions, setOpenPositions] = useState([]); // Ajout pour stocker les données pour le tableau
    const [selectedTeam, setSelectedTeam] = useState('All'); // Ajout pour la gestion des équipes sélectionnées

    const processChartData = (data) => {
        const labels = data.map(item => item.periode);
        const estimatedData = data.map(item => item.op_historique_reforecast);
        const ophistorique = data.map(item => item.op_historique_real);

        const lastActual = ophistorique[ophistorique.length - 1];
        const lastForecast = estimatedData[estimatedData.length - 1];
        const previousActual = ophistorique[ophistorique.length - 2] || 0;
        const previousForecast = estimatedData[estimatedData.length - 2] || 0;

        const actualChange = previousActual !== 0 ? ((lastActual - previousActual) / previousActual * 100).toFixed(2) : 0;
        const forecastChange = previousForecast !== 0 ? ((lastForecast - previousForecast) / previousForecast * 100).toFixed(2) : 0;

        const lastPeriod = labels[labels.length - 1];

        setKpiData({
            currentActual: lastActual,
            currentForecast: lastForecast,
            actualChange: actualChange,
            forecastChange: forecastChange,
            lastPeriod: lastPeriod
        });

        setChartData({
            labels,
            datasets: [
                {
                    label: 'Current workforce',
                    data: ophistorique,
                    borderColor: '#EBA49B',
                    backgroundColor: '#EBA49B40',
                    fill: true,
                    tension: 0.1
                },
                {
                    label: 'Forecast workforce',
                    data: estimatedData,
                    borderColor: '#93c2db',
                    backgroundColor: '#93c2db40',
                    fill: true,
                    tension: 0.1
                },
            ],
        });

    };

    const fetchFilterOptions = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        const url = `${apiUrl}/openposition/compare_recrutement/`;
        try {
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Token ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Error fetching filter options');
            }

            const data = await response.json();
            setFilterOptions(data.filters);
        } catch (error) {
            console.error('Error fetching filter options', error);
        }
    };

    const fetchData = async () => {
        const storageKey = `GraphStaffCompare_${startDate}_${endDate}_${groupOption}_${JSON.stringify(filters)}`;
        const cachedData = localStorage.getItem(storageKey);
        const oneDay = 24 * 60 * 60 * 1000;
        if (cachedData) {
            const { data, timestamp } = JSON.parse(cachedData);
            if (new Date() - new Date(timestamp) < oneDay) {
                processChartData(data);
                setOpenPositions(data); // Stocker les données pour le tableau
                return;
            }
        }

        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        const filterParams = filters.map(filter => `${filter.column}=${filter.value}`).join('&');
        const url = `${apiUrl}/openposition/compare_recrutement/?start_date=${startDate}&end_date=${endDate}&period=${groupOption}&${filterParams}`;
        try {
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Token ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Error fetching workforce data');
            }

            const { data } = await response.json();
            localStorage.setItem(storageKey, JSON.stringify({ data, timestamp: new Date() }));
            processChartData(data);
            setOpenPositions(data); // Stocker les données pour le tableau
        } catch (error) {
            console.error('Error fetching workforce data', error);
        }
    };

    useEffect(() => {
        fetchFilterOptions();
        fetchData();
    }, [startDate, endDate, groupOption, filters]);

    const handleGroupChange = (event) => {
        setGroupOption(event.target.value);
    };

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };

    const handleAddFilter = () => {
        if (currentFilter.column && currentFilter.value) {
            let displayValue;
            if (currentFilter.column === 'team') {
                const selectedTeam = filterOptions.teams.find(team => team.id === parseInt(currentFilter.value, 10));
                displayValue = selectedTeam ? selectedTeam.nom_equipe : '';
            } else if (currentFilter.column === 'location') {
                displayValue = currentFilter.value;
            }

            setFilters([...filters, { ...currentFilter, displayValue }]);
            setCurrentFilter({ column: '', value: '', displayValue: '' });
        }
    };


        // Fonction pour exporter les données en CSV
    const exportToCSV = () => {
        let csvContent = "data:text/csv;charset=utf-8,";
        csvContent += selectedTeam === 'All'
            ? "Team,Current Staff,Open Positions Signed\n"
            : "Team,Location,Current Staff,Open Positions Signed\n";

        openPositions.forEach(item => {
            const row = selectedTeam === 'All'
                ? `${item.team},${item.population},${item.open_positions_signed}`
                : `${item.team},${item.location},${item.population},${item.open_positions_signed}`;
            csvContent += row + "\n";
        });

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "team_population_data.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleRemoveFilter = (index) => {
        const newFilters = filters.filter((_, i) => i !== index);
        setFilters(newFilters);
    };

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setCurrentFilter({ ...currentFilter, [name]: value });
    };

    // Dummy data for table and chart options

    const kpiDataArray = [
        {
            title: 'Current Actual',
            value: kpiData.currentActual,
            change: `${kpiData.actualChange}`,
            period: kpiData.lastPeriod,
            icon: faPeopleGroup,
            color: '#EBA49B',
            groupOption: groupOption
        },
        {
            title: 'Current Forecast',
            value: kpiData.currentForecast,
            change: `${kpiData.forecastChange}`,
            period: kpiData.lastPeriod,
            icon: faPeopleGroup ,
            color: '#93c2db',
            groupOption: groupOption
        }
    ];
    

    const chartOptions = {
        responsive: true,
        scales: {
            x: { title: { display: true, text: 'Period' } },
            y: { title: { display: true, text: 'Workforce' } },
        },
    };
    const tableColumns = [
        { label: 'Période', key: 'periode' },
        { label: 'Current Workforce', key: 'op_historique_real' },
        { label: 'Current Forecast', key: 'op_historique_reforecast' },
    ];

    const tableData = chartData.labels.map((label, index) => ({
        periode: label,
        op_historique_real: chartData.datasets[0].data[index],
        op_historique_reforecast: chartData.datasets[1].data[index],
    }));
    



    return (
        <div style={{ backgroundColor: colors.background }} className='container-home'>
            <Sidebar initiallyCollapsed={true} />
            <div className="container-right">
                <Header />
                <div className="content-container">

                    <FiltersComponent
                        showDateRange={true}
                        showGroupOption={true}
                        startDate={startDate}
                        endDate={endDate}
                        handleStartDateChange={handleStartDateChange}
                        handleEndDateChange={handleEndDateChange}
                        groupOption={groupOption}
                        handleGroupChange={handleGroupChange}
                        filterOptions={filterOptions}
                        currentFilter={currentFilter}
                        handleFilterChange={handleFilterChange}
                        handleAddFilter={handleAddFilter}
                        filters={filters}
                        handleRemoveFilter={handleRemoveFilter}
                    />

                    <div className='container-data'>
                        <ChartWithKPIsComponent
                            title="Actual and forecasted workforce over the year"
                            kpiDataArray={kpiDataArray}
                            chartType="line"
                            chartData={chartData}
                            chartOptions={chartOptions}
                        />

                        <>
                            <TableComponent
                                title="Workforce Data Table"
                                columns={tableColumns}
                                data={tableData}
                                exportToCSV={exportToCSV}
                            />
                        </>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GraphStaffCompare;
