import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ScenariosTable = ({ scenarios, onDeleteScenarios }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const itemsPerPage = 10;
    const navigate = useNavigate();
    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedScenarios = [...scenarios].sort((a, b) => {
        if (sortConfig.key) {
            let aValue = a[sortConfig.key];
            let bValue = b[sortConfig.key];

            if (aValue < bValue) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (aValue > bValue) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
        }
        return 0;
    });

    const paginatedScenarios = sortedScenarios.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const totalPages = Math.ceil(scenarios.length / itemsPerPage);

    const goToPreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const goToNextPage = () => {
        setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
    };

    const handleCheckboxChange = (id) => {
        setSelectedRows((prevSelected) =>
            prevSelected.includes(id)
                ? prevSelected.filter((rowId) => rowId !== id)
                : [...prevSelected, id]
        );
    };

    const handleDelete = () => {
        onDeleteScenarios(selectedRows);
        setSelectedRows([]); // Clear selection after deletion
    };

    return (
        <div className="table-container">
                        {selectedRows.length > 0 && (
                <div className="delete-controls">
                    <button onClick={handleDelete} type='submit' className="delete-button">
                        Delete Selected ({selectedRows.length})
                    </button>
                </div>
            )}
            <table className="positions-table">
                <thead>
                    <tr>
                        <th>Select</th>
                        <th onClick={() => handleSort('name')}>Scenario Name</th>
                        <th>Recrutement number</th>
                        <th>Budget</th>
                    </tr>
                </thead>
                <tbody>
                    {paginatedScenarios.map((scenario) => (
                        <tr
                            key={scenario.id}
                            className={`clickable ${selectedRows.includes(scenario.id) ? 'selected' : ''}`}
                            onClick={() => navigate(`/headcount/${scenario.id}`)}
                        >
                            <td onClick={(e) => e.stopPropagation()}>
                                <input
                                    type="checkbox"
                                    checked={selectedRows.includes(scenario.id)}
                                    onChange={() => handleCheckboxChange(scenario.id)}
                                />
                            </td>
                            <td>{scenario.name}</td>
                            <td>{scenario.futureOpenPositionsCount}</td>
                            <td>{scenario.budget}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            
            <div className="hc-pagination-control">
                <button onClick={goToPreviousPage} className="btn-secondary" disabled={currentPage === 1}>
                    Previous
                </button>
                <span>
                    Page {currentPage} of {totalPages}
                </span>
                <button onClick={goToNextPage} className="btn-secondary" disabled={currentPage === totalPages}>
                    Next
                </button>
            </div>

        </div>
    );
};

export default ScenariosTable;
