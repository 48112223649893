import React, { useState, useEffect } from 'react';
import Tableau from '../../components/recrutement/Tableau';
import UpdatePositionForm from '../../components/form/UpdatePositionForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const DataHealth = () => {
  const [positionsData, setPositionsData] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState(null); // Stocker la position sélectionnée
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updateInfo, setUpdateInfo] = useState({}); // Données pour le formulaire
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('token');

  // Définir les colonnes
  const allColumns = [
    { key: 'team', label: 'Team' },
    { key: 'job', label: 'Job title' },
    { key: 'status', label: 'Status' },
    { key: 'missing_fields', label: 'Missing fields' },
  ];

  const visibleColumns = allColumns.map(col => col.key);

  useEffect(() => {
    fetchDataHealth();
  }, []);

  // Fetch data health from the API
  
  const fetchDataHealth = async () => {
    const groupUrl = `${apiUrl}/api/staffingplan/datacompletion/`;

    try {
      const response = await fetch(groupUrl, {
        method: 'GET',
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }

      const data = await response.json();
      setPositionsData(data.positions);
    } catch (error) {
      setError(error.message || 'Failed to load data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour récupérer les détails d'une position au clic
  const fetchPositionDetails = async (positionId) => {
    const detailsUrl = `${apiUrl}/openposition/${positionId}/`;

    try {
      const response = await fetch(detailsUrl, {
        method: 'GET',
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch position details');
      }

      const data = await response.json();
      setUpdateInfo({
        job: data.job,
        status: data.status,
        salary_min: data.salary_min,
        salary_max: data.salary_max,
        arrival_month: data.arrival_month,
        location: data.location,
        contact_manager: data.contact_manager,
        recruiter: data.recruiter,
        job_opening_date: data.job_opening_date,
        contract: data.contract,
        agency: data.agency,
        priority: data.priority,
        comments: data.comments,
        FORECAST_arrival_month: data.FORECAST_arrival_month,
        FORECAST_job_opening_date: data.FORECAST_job_opening_date,
        exact_arrival_date: data.exact_arrival_date,
        employe_hired: data.employe_hired,
      });

      setSelectedPosition(data); // Stocker la position sélectionnée
    } catch (error) {
      console.error('Error fetching position details:', error);
      setError('Failed to load position details.');
    }
  };

  // Gestion du clic sur une ligne
  const handleRowClick = (row) => {
    fetchPositionDetails(row.id); // Récupérer les détails de la position cliquée
  };

  // Fonction pour nettoyer les données avant l'envoi
  const cleanData = (data) => {
    const cleanedData = { ...data };
    Object.keys(cleanedData).forEach(key => {
      if (cleanedData[key] === '') {
        cleanedData[key] = null;
      }
    });
    return cleanedData;
  };

  // Gestion du PUT pour mettre à jour les données d'une position
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!selectedPosition) return;

    const updateUrl = `${apiUrl}/openposition/${selectedPosition.id}/`;

    const updatedData = {
      status: updateInfo.status,
      job: updateInfo.job,
      salary_min: updateInfo.salary_min,
      salary_max: updateInfo.salary_max,
      location: updateInfo.location,
      contact_manager: updateInfo.contact_manager,
      arrival_month: updateInfo.arrival_month,
      contract: updateInfo.contract,
      recruiter: updateInfo.recruiter,
      agency: updateInfo.agency,
      priority: updateInfo.priority,
      comments: updateInfo.comments,
      FORECAST_arrival_month: updateInfo.FORECAST_arrival_month,
      FORECAST_job_opening_date: updateInfo.FORECAST_job_opening_date,
      exact_arrival_date: updateInfo.exact_arrival_date,
      employe_hired: updateInfo.employe_hired,
    };

    const cleanedData = cleanData(updatedData);

    try {
      const response = await fetch(updateUrl, {
        credentials: 'include',
        method: 'PUT',
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(cleanedData),
      });

      if (!response.ok) {
        throw new Error('Failed to update data');
      }

      const updatedPost = await response.json();
      setPositionsData(prevData => prevData.map(item => item.id === updatedPost.id ? updatedPost : item));
      setSelectedPosition(null); // Réinitialiser après la mise à jour
    } catch (error) {
      console.error('Error updating position:', error);
      setError('Failed to update data. Please try again later.');
    }
  };

  return (
    <div className="line-container-10">
      <h4>Data Health Check</h4>

      {loading ? (
        <div>
          <FontAwesomeIcon icon={faSpinner} spin /> Loading...
        </div>
      ) : error ? (
        <p style={{ color: 'red' }}>{error}</p>
      ) : (
        <>
          <Tableau
            data={positionsData}
            columns={allColumns}
            visibleColumns={visibleColumns}
            hideSelect={true}
            onRowClick={handleRowClick} // Appel API sur clic de ligne
          />

          {selectedPosition && (
            <UpdatePositionForm
              selectedPost={selectedPosition}
              updateInfo={updateInfo}
              setUpdateInfo={setUpdateInfo}
              handleBackClick={() => setSelectedPosition(null)} // Revenir en arrière
              handleFormSubmit={handleFormSubmit} // Logique PUT pour mise à jour // Logique de suppression
            />
          )}
        </>
      )}
    </div>
  );
};

export default DataHealth;
