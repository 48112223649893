import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "../../styles/Home.css";
import "../../styles/Card.css";

const SelectReview = () => {
    const [reviews, setReviews] = useState([]);
    const [selectedReview, setSelectedReview] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        fetchReviews();
    }, []);

    useEffect(() => {
        if (selectedReview) {
            navigate(`/performance/${selectedReview}`);
        }
    }, [selectedReview, navigate]);

    const fetchReviews = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            const response = await fetch(`${apiUrl}/reviews/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setReviews(data);
        } catch (error) {
            console.error('There was an error fetching the reviews:', error);
        }
    };

    const handleCreateNewReview = async (e) => {
        e.preventDefault();

        const now = new Date();
        const name = now.toISOString();
        const reviewDate = now.toISOString().split('T')[0];

        const userData = JSON.parse(localStorage.getItem('userData'));
        const review = {
            owner: userData.id,
            name: name,
            review_date: reviewDate,
        };
        console.log(review);
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            const response = await fetch(`${apiUrl}/reviews/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                },
                body: JSON.stringify(review),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const newReview = await response.json();
            setReviews([...reviews, newReview]);
            setSelectedReview(newReview.id);
        } catch (error) {
            console.error('There was an error creating the review:', error);
        }
    };

    return (
        <div className='cards-container'>
            <div className='dropdown'>
                <select
                    value={selectedReview}
                    onChange={(e) => setSelectedReview(e.target.value)}
                    className='dropdown'
                >
                    <option value=''>--Select Review--</option>
                    {reviews.map((review) => (
                        <option key={review.id} value={review.id}>
                            {review.name}
                        </option>
                    ))}
                </select>
            </div>
            <button onClick={handleCreateNewReview} type='submit'>
                Create New Review
            </button>
        </div>
    );
};

export default SelectReview;
