import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from "../../../components/Sidebar";
import colors from '../../../assets/colors';
import Header from "../../../components/Header";
import FiltersComponent from '../../../components/recrutement/chartv2/FiltersComponent';
import ChartWithKPIsComponent from '../../../components/recrutement/chartv2/ChartWithKPIsComponent';
import TableComponent from '../../../components/recrutement/chartv2/TableComponent';
import { faClipboard, faPencil, faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import ToggleBtnChart from '../../../components/buttons/ToggleBtnChart';

const InsightPagePopulationTeam = () => {
    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
    const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
    const [groupOption, setGroupOption] = useState('month');
    const [currentFilter, setCurrentFilter] = useState({ column: 'team', value: 'All' });
    const [filters, setFilters] = useState([]);
    const [itemsToShow, setItemsToShow] = useState(10);
    const [kpiDataArray, setKpiDataArray] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [chartData, setChartData] = useState(null);
    const [filterOptions, setFilterOptions] = useState({ teams: [], locations: [] });
    const [historiqueTableData, setHistoriqueTableData] = useState([]);
    const [selectedOption, setSelectedOption] = useState('Gauche');

    const navigate = useNavigate();

    // Fetching population data
    const fetchData = async (date) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');

            const response = await fetch(`${apiUrl}/openposition/population_team_by_period/?date=${date}`, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la récupération des données');
            }

            const responseData = await response.json();
            
            if (responseData) {
                setFilterOptions(responseData.filters || { teams: [], locations: [] });
                setData(responseData.data || []);
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des données', error);
        }
    };

    // Fetching employee data
    const fetchEmployeData = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');
            const userData = JSON.parse(localStorage.getItem('userData'));
            const entrepriseId = userData?.entreprise_id;

            if (!entrepriseId) {
                console.error('Entreprise ID not found in userData');
                return;
            }

            const query = `
                query ($entrepriseId: Int!, $startDate: Date!, $endDate: Date!, $equipeId: Int) {
                    historiquesEmploi(
                        entrepriseId: $entrepriseId,
                        startDate: $startDate,
                        endDate: $endDate,
                        equipeId: $equipeId
                    ) {
                        equipe {
                            nomEquipe
                        }
                        employe {
                            firstName
                            lastName
                        }
                        metier {
                            titreMetier
                        }
                    }
                }
            `;

            const variables = {
                entrepriseId,
                startDate,
                endDate: endDate || null,
            };

            const response = await fetch(`${apiUrl}/graphql/`, {
                method: 'POST',
                headers: {
                    Authorization: `Token ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ query, variables }),
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la récupération des données');
            }

            const { data } = await response.json();

            if (data?.historiquesEmploi) {
                const groupedData = data.historiquesEmploi.reduce((acc, item) => {
                    const team = item.equipe?.nomEquipe || 'Unknown Team';
                    if (!acc[team]) acc[team] = [];
                    acc[team].push({
                        employe: `${item.employe.firstName} ${item.employe.lastName}`,
                        metier: item.metier?.titreMetier || 'N/A',
                        equipe: team,
                    });
                    return acc;
                }, {});

                let tableData = Object.keys(groupedData).flatMap((team) => groupedData[team]);

                // Apply filters to historique data
                tableData = applyFilters(tableData);

                setHistoriqueTableData(tableData);
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des données', error);
        }
    };

    // UseEffect to fetch data
    useEffect(() => {
        fetchData(startDate);
        fetchEmployeData();
    }, [startDate, endDate]);

    // Processing chart data when data or filters change
    useEffect(() => {
        processChartData(data);
        processHistoriqueData();
    }, [data, filters]);

    const handleStartDateChange = (event) => setStartDate(event.target.value);
    const handleEndDateChange = (event) => setEndDate(event.target.value);
    const handleGroupChange = (event) => setGroupOption(event.target.value);

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setCurrentFilter(prevState => ({ ...prevState, [name]: value }));
    };

    const handleAddFilter = () => {
        if (currentFilter.column && currentFilter.value && currentFilter.value !== 'All') {
            const displayValue = currentFilter.column === 'team'
                ? filterOptions.teams.find(team => team.id === parseInt(currentFilter.value))?.nom_equipe
                : currentFilter.value;

            setFilters([...filters, { ...currentFilter, displayValue }]);
        }
    };

    const handleRemoveFilter = (index) => {
        setFilters(filters.filter((_, i) => i !== index));
    };

    console.log('filterOptions',filterOptions);
    const applyFilters = (data) => {
        let filteredData = data;

        filters.forEach((filter) => {
            if (filter.value === 'All' || !filter.value) {
                return;
            }

            if (filter.column === 'team') {
                filteredData = filteredData.filter((item) => item.team === filter.displayValue || item.equipe === filter.displayValue);
            } else if (filter.column === 'location') {
                filteredData = filteredData.map((item) => {
                    if (!item.locations || !Array.isArray(item.locations)) {
                        return { ...item, locations: [], population: 0, open_positions_signed: 0 };
                    }
                
                    const filteredLocations = item.locations.filter(
                        (loc) => loc.location === filter.value
                    );
                
                    return {
                        ...item,
                        locations: filteredLocations,
                        population: filteredLocations.reduce((sum, loc) => sum + loc.population, 0),
                        open_positions_signed: filteredLocations.reduce(
                            (sum, loc) => sum + loc.open_positions_signed,
                            0
                        ),
                    };
                });
                
            }
        });

        return filteredData;
    };

    const processChartData = (data) => {
        const filteredData = applyFilters(data);

        const teamFilters = filters.filter(f => f.column === 'team');
        const locationFilters = filters.filter(f => f.column === 'location');

        if (teamFilters.length === 1) {
            const teamName = teamFilters[0].displayValue;
            const teamData = filteredData.find(item => item.team === teamName);
            if (teamData) {
                const chartData = {
                    labels: teamData.locations.map(loc => loc.location),
                    datasets: [
                        {
                            label: 'Current Staff',
                            data: teamData.locations.map(loc => loc.population),
                            backgroundColor: 'rgba(54, 162, 235, 0.6)',
                            borderColor: 'rgba(54, 162, 235, 1)',
                            borderWidth: 1
                        },
                        {
                            label: 'Open Positions Signed',
                            data: teamData.locations.map(loc => loc.open_positions_signed),
                            backgroundColor: 'rgba(255, 99, 132, 0.6)',
                            borderColor: 'rgba(255, 99, 132, 1)',
                            borderWidth: 1
                        }
                    ]
                };

                const kpiDataArray = [
                    {
                        title: 'Current staff',
                        value: teamData.locations.reduce((sum, loc) => sum + loc.population, 0),
                        icon: faPeopleGroup,
                        color: 'rgba(54, 162, 235, 1)'
                    },
                    {
                        title: 'Open Positions Signed',
                        value: teamData.locations.reduce((sum, loc) => sum + loc.open_positions_signed, 0),
                        icon: faPencil,
                        color: 'rgba(255, 99, 132, 1)'
                    }
                ];

                const tableData = teamData.locations.map(loc => ({
                    location: loc.location,
                    population: loc.population,
                    open_positions_signed: loc.open_positions_signed
                }));

                setChartData(chartData);
                setKpiDataArray(kpiDataArray);
                setTableData(tableData);
            }
        } else {
            const sortedData = filteredData.sort((a, b) => (b.open_positions_signed + b.population) - (a.open_positions_signed + a.population));
            const displayedData = sortedData.slice(0, itemsToShow);

            const chartData = {
                labels: displayedData.map(item => item.team),
                datasets: [
                    {
                        label: 'Current Staff',
                        data: displayedData.map(item => item.population),
                        backgroundColor: 'rgba(54, 162, 235, 0.6)',
                        borderColor: 'rgba(54, 162, 235, 1)',
                        borderWidth: 1
                    },
                    {
                        label: 'Open Positions Signed',
                        data: displayedData.map(item => item.open_positions_signed),
                        backgroundColor: 'rgba(255, 99, 132, 0.6)',
                        borderColor: 'rgba(255, 99, 132, 1)',
                        borderWidth: 1
                    }
                ]
            };

            const kpiDataArray = [
                {
                    title: 'Total Current Staff',
                    value: sortedData.reduce((sum, item) => sum + item.population, 0),
                    icon: faPeopleGroup,
                    color: 'rgba(54, 162, 235, 1)'
                },
                {
                    title: 'Total Open Positions Signed',
                    value: sortedData.reduce((sum, item) => sum + item.open_positions_signed, 0),
                    icon: faClipboard,
                    color: 'rgba(255, 99, 132, 1)'
                }
            ];

            const tableData = [];
            displayedData.forEach(item => {
                item.locations.forEach(loc => {
                    tableData.push({
                        team: item.team,
                        location: loc.location,
                        population: loc.population,
                        open_positions_signed: loc.open_positions_signed
                    });
                });
            });

            setChartData(chartData);
            setKpiDataArray(kpiDataArray);
            setTableData(tableData);
        }
    };

    const processHistoriqueData = () => {
        const filteredHistoriqueData = applyFilters(historiqueTableData);
        setHistoriqueTableData(filteredHistoriqueData);
    };

    const teamFilters = filters.filter(f => f.column === 'team');
    const tableColumns = teamFilters.length === 1
        ? [
            { label: 'Location', key: 'location' },
            { label: 'Current Workforce', key: 'population' },
            { label: 'Open Positions Signed', key: 'open_positions_signed' }
        ]
        : [
            { label: 'Team', key: 'team' },
            { label: 'Location', key: 'location' },
            { label: 'Current Workforce', key: 'population' },
            { label: 'Open Positions Signed', key: 'open_positions_signed' }
        ];

    const xAxisTitle = teamFilters.length === 1 ? 'Locations' : 'Teams';

    const exportToCSV = () => {
        // Exportation des données en CSV ici
    };

    return (
        <div style={{ backgroundColor: colors.background }} className="container-home">
            <Sidebar initiallyCollapsed={true} />
            <div className="container-right">
                <Header />
                <div className="content-container">
                    <FiltersComponent
                        showDateRange={false}
                        showGroupOption={false}
                        startDate={startDate}
                        handleStartDateChange={handleStartDateChange}
                        groupOption={groupOption}
                        handleGroupChange={handleGroupChange}
                        filterOptions={filterOptions}
                        currentFilter={currentFilter}
                        handleFilterChange={handleFilterChange}
                        handleAddFilter={handleAddFilter}
                        filters={filters}
                        handleRemoveFilter={handleRemoveFilter}
                    />

                        <div className="container-data">

                                <ChartWithKPIsComponent
                                    title="Actual and forecasted workforce over the year (per team)"
                                    kpiDataArray={kpiDataArray}
                                    showChangeKpi={false}
                                    chartType="bar"
                                    chartData={chartData}
                                    showToggle = {true}
                                    chartOptions={{
                                        maintainAspectRatio: false,
                                        scales: {
                                            x: {
                                                stacked: true,
                                                title: { display: true, text: xAxisTitle }
                                            },
                                            y: {
                                                beginAtZero: true,
                                                stacked: true,
                                                title: { display: true, text: 'Staff Count & Signed Positions' }
                                            }
                                        }
                                    }}
                                    columnstable={[
                                        { label: 'Employé', key: 'employe' },
                                        { label: 'Équipe', key: 'equipe' },
                                        { label: 'Métier', key: 'metier' },
                                    ]}
                                    titletable="Historique des emplois (par équipe)"
                                    datatable={historiqueTableData}
                                />
                            <div className='card-full'>
                            <TableComponent
                            title="Workforce Data Table"
                            columns={tableColumns}
                            data={tableData}
                            exportToCSV={exportToCSV}
                        />
                                
                            </div>
                            
                        </div>

                </div>
            </div>
        </div>
    );
};

export default InsightPagePopulationTeam;