import React, { useState, useEffect } from 'react';
import "../../styles/Home.css";
import "../../styles/Card.css";
import ForecastEmployes from '../../components/kpi/Recrutement/ForecastEmployes';
import CompareRecruit from '../../components/kpi/Recrutement/CompareRecruit';
import TargetCompany from '../../components/kpi/Recrutement/TargetCompany';
import InsightPopulationEquipe from '../../components/insights/recrutement/InsightPopulationEquipe';
import InsightPopulationLocation from '../../components/insights/recrutement/InsightPopulationLocation';
import HoverInfoCard from '../../components/cards/HoverInfoCard';
import InsightProgressTA from '../../components/insights/recrutement/InsightProgressTA';

const OverviewRecrutement = () => {
    const [teams, setTeams] = useState([]); // Pour stocker la liste des équipes
    const [selectedTeam, setSelectedTeam] = useState(''); // Pour stocker l'équipe sélectionnée

    // Fonction pour récupérer les équipes depuis l'API
    const fetchTeams = async () => {
        const apiUrl = process.env.REACT_APP_API_URL;
        const token = localStorage.getItem('token');
        try {
            const response = await fetch(`${apiUrl}/api/equipe/`, {
                headers: {
                    'Authorization': `Token ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la récupération des équipes');
            }

            const data = await response.json();
            setTeams(data);
        } catch (error) {
            console.error('Erreur lors de la récupération des équipes', error);
        }
    };

    // Récupérer les équipes au montage du composant
    useEffect(() => {
        fetchTeams();
    }, []);

    // Gestion du changement d'équipe
    const handleTeamChange = (event) => {
        setSelectedTeam(event.target.value);
    };

    return (
        <div className='line-container-10'>
            

            {/* Ajout du sélecteur d'équipes */}
            <div className="dropdown display-line">
    <h5>
        Overview of{" "}
        <select id="team" value={selectedTeam} onChange={handleTeamChange} style={{ display: 'inline', marginLeft: '5px' }}>
            <option value="">All Teams</option>
            {teams.map((team) => (
                <option key={team.id} value={team.id}>
                    {team.nom_equipe}
                </option>
            ))}
        </select>
    </h5>
</div>


            <div className='cards-container'>
                <ForecastEmployes team={selectedTeam} />
                <HoverInfoCard infoText="The remaining number shows the projected hires by the end of the year, based on the forecasted arrival dates.">
                    <CompareRecruit team={selectedTeam} />
                </HoverInfoCard>
            </div>

            <div className='line-container-10'>
                <h5>Analytics</h5>
                <div className='cards-container'>
                    <InsightPopulationEquipe />
                    <InsightPopulationLocation />
                    <InsightProgressTA/>
                </div>
            </div>
        </div>
    );
};

export default OverviewRecrutement;
