import React, { useState, useEffect, useCallback } from 'react';
import SidebarUser from "../../components/user_space/SidebarUser";
import colors from '../../assets/colors';
import Header from "../../components/Header";
import "../../styles/Home.css";
import "../../styles/Card.css";
import InfoPerso from '../../components/user_space/InfoPerso';





const UserInfos = () => {


  return (
    <div style={{ backgroundColor: colors.background }} className='container-home'>
      <SidebarUser />

      <div className="container-right" >
        <Header />

        <h3>Gestion des informations personnelles</h3>
        
        <div className='card'>
        <InfoPerso/>
        </div> 
      </div> 
    </div>
  );
}

export default UserInfos;
