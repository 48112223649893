import React, { useState, useEffect } from 'react';
import Sidebar from "../../components/Sidebar";
import colors from '../../assets/colors';
import Header from "../../components/Header";
import "../../styles/Home.css";
import "../../styles/Card.css";
import NewArrival from '../../components/kpi/Effectif/NewArrival';
import EffectifNumber from '../../components/kpi/EffectifNumber';



const EffectifPage = () => {


    return (
        <div style={{ backgroundColor: colors.background }} className='container-home'>
            <Sidebar />
            <div className="container-right">
                <Header />
                <div className='line-container-10'>
                <h5>Vue d'ensemble</h5>
                
            </div>
            <div className='cards-container'>
            <EffectifNumber/>
            <NewArrival/>
                
            </div>
   
        </div> 
        </div>
    );
};

export default EffectifPage;

