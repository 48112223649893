import React from "react";
import '../../styles/Card.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleUp, faArrowUp, faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
const  KPICard = ({ kpi, text, icon, onClick }) => {
  return (
    <div className="kpi-card" onClick={onClick}>
      <div className="kpi-card-kpi">{kpi}</div>
      <div className="kpi-card-subtext">{text}</div>
      
    </div>
  );
};
export default KPICard;