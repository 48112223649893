import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import "../../styles/Home.css";
import "../../styles/Card.css";
import Tableau from "../../components/recrutement/Tableau";

const Callibration = () => {
    const { reviewId } = useParams();

    const [chartDataCountByPerformance, setChartDataCountByPerformance] = useState({});
    const [chartDataMedianAndStdDev, setChartDataMedianAndStdDev] = useState({});
    const [loadingCountByPerformance, setLoadingCountByPerformance] = useState(true);
    const [loadingMedianAndStdDev, setLoadingMedianAndStdDev] = useState(true);
    const [warningManagers, setWarningManagers] = useState([ { 'name': '','median': '', 'stddev': '' }]);
    const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

    useEffect(() => {
        const fetchDataCountByPerformance = async () => {
            if (!reviewId) return;
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const token = localStorage.getItem('token');

                const responseCountByPerformance = await fetch(`${apiUrl}/reviews_by_person/count_by_performance/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${token}`,
                    }
                });

                if (!responseCountByPerformance.ok) {
                    throw new Error('Network response was not ok');
                }

                const resultCountByPerformance = await responseCountByPerformance.json();

                prepareChartDataCountByPerformance(resultCountByPerformance);

            } catch (error) {
                console.error('There was an error fetching the data:', error);
            } finally {
                setLoadingCountByPerformance(false);
            }
        };

        const fetchDataMedianAndStdDev = async () => {
            try {
                const apiUrl = process.env.REACT_APP_API_URL;
                const token = localStorage.getItem('token');

                const responseMedianAndStdDev = await fetch(`${apiUrl}/reviews_by_person/${reviewId}/median_and_stddev_by_reviewer/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${token}`,
                    }
                });

                if (!responseMedianAndStdDev.ok) {
                    throw new Error('Network response was not ok');
                }

                const resultMedianAndStdDev = await responseMedianAndStdDev.json();

                prepareChartDataMedianAndStdDev(resultMedianAndStdDev);

            } catch (error) {
                console.error('There was an error fetching the data:', error);
            } finally {
                setLoadingMedianAndStdDev(false);
            }
        };

        fetchDataCountByPerformance();
        fetchDataMedianAndStdDev();
    }, [reviewId]);

    const prepareChartDataCountByPerformance = (data) => {
        const scores = Object.keys(data);
        const counts = Object.values(data);

        setChartDataCountByPerformance({
            labels: scores,
            datasets: [
                {
                    data: counts,
                    backgroundColor: 'rgba(75, 192, 192, 0.6)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1,
                    type: 'bar',
                },
                {
                    data: counts,
                    type: 'line',
                    fill: false,
                    borderColor: 'rgba(255, 99, 132, 1)',
                    borderWidth: 2,
                    tension: 0.4,
                },
            ],
        });
    };

    const prepareChartDataMedianAndStdDev = (data) => {
        const reviewers = data.map(item => {
            let reviewerName = item.perf_reviewer__last_name;
            if (item.threshold_deviation_warning) {
                reviewerName += ' ⚠️';
            }
            return reviewerName;
        });
        const median = data.map(item => item.median_performance || 0);
        const stddev = data.map(item => item.stddev_performance || 0);

        const warningManagersList = data.map(item => ({
            'name': item.perf_reviewer__last_name,
            'median': item.median_performance,
            'stddev': item.stddev_performance,
        }));

        setWarningManagers(warningManagersList);

        setChartDataMedianAndStdDev({
            labels: reviewers,
            datasets: [
                {
                    label: 'Median',
                    data: median,
                    backgroundColor: 'rgba(153, 102, 255, 0.6)',
                    borderColor: 'rgba(153, 102, 255, 1)',
                    borderWidth: 1,
                },
                {
                    label: 'Standard Deviation',
                    data: stddev,
                    backgroundColor: 'rgba(255, 159, 64, 0.6)',
                    borderColor: 'rgba(255, 159, 64, 1)',
                    borderWidth: 1,
                },
            ],
        });
    };

    const columns = [
        { key: 'name', label: 'Name' },
        { key: 'median', label: 'Median' },
        { key: 'stddev', label: 'Standard Deviation' },
    ];

   



    console.log('Warning Managers:', warningManagers);

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
        setWarningManagers((prevManagers) =>
            [...prevManagers].sort((a, b) => {
                if (a[key] < b[key]) {
                    return direction === 'asc' ? -1 : 1;
                }
                if (a[key] > b[key]) {
                    return direction === 'asc' ? 1 : -1;
                }
                return 0;
            })
        );
    };

    return (
        <div>
        <div className='cards-container'>
            <div className='card-half-full'>
                <div className='chart-container'>
                    {loadingCountByPerformance ? (
                        <p>Chargement des données de performance...</p>
                    ) : (
                        <Bar
                            data={chartDataCountByPerformance}
                            options={{
                                scales: {
                                    y: {
                                        beginAtZero: true,
                                        title: {
                                            display: true,
                                            text: 'Number of Reviews',
                                        },
                                    },
                                },
                                plugins: {
                                    legend: {
                                        display: true,
                                        position: 'bottom',
                                    },
                                    title: {
                                        display: true,
                                        text: 'Performance Reviews Distribution',
                                    },
                                },
                            }}
                        />
                    )}
                </div>
            </div>
            <div className='card-half-full'>
                <div className='chart-container'>
                    {loadingMedianAndStdDev ? (
                        <p>Chargement des données de médiane et d\'écart type...</p>
                    ) : (
                        <Bar
                            data={chartDataMedianAndStdDev}
                            options={{
                                scales: {
                                    y: {
                                        beginAtZero: true,
                                        title: {
                                            display: true,
                                            text: 'Scores',
                                        },
                                    },
                                },
                                plugins: {
                                    legend: {
                                        display: true,
                                        position: 'bottom',
                                    },
                                    title: {
                                        display: true,
                                        text: 'Median and Standard Deviation by Reviewer',
                                    },
                                },
                            }}
                        />
                    )}
                </div>
            </div>


                

        </div>
        <Tableau
                        data={warningManagers}
                        columns={columns}
                        visibleColumns={columns.map(col => col.key)} 
                        sortConfig={sortConfig}
                        onSort={handleSort}
                        hideFilter={false}  
                        hideSelect={true}
                    />
                    </div>
    );
};

export default Callibration;
