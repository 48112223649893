import React, { useRef } from 'react';
import "../../styles/ScenariHR.css";
import { useNavigate } from 'react-router-dom';

const HCForm = ({
    name,
    budget,
    reuseOpenPositions,
    onInputChange,
    onCheckboxChange,
    onSubmit,
    message,
    onClose,
}) => {
    const modalRef = useRef(null);
    const navigate = useNavigate();

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            onClose();
        }
    };

    return (
        <div className="modal-overlay" onClick={handleClickOutside}>
            <div className="modal-container" ref={modalRef}>
                <button className="modal-close" onClick={onClose}>
                    &times;
                </button>
                <h5>Create a New Scenario</h5>
                {message && <p className="form-message">{message}</p>}
                <form className="hc-form" onSubmit={onSubmit}>
                    <div className="hc-form-line">
                        <label>Scenario Name:</label>
                        <input
                            type="text"
                            name="name"
                            value={name}
                            onChange={onInputChange}
                            required
                        />
                    </div>
                    <div className="hc-form-line">
                        <label>Budget:</label>
                        <input
                            type="number"
                            step="0.01"
                            name="budget"
                            value={budget}
                            onChange={onInputChange}
                            required
                        />
                    </div>
                    <div className="hc-form-line">
                        <label>
                            <input
                                type="checkbox"
                                name="reuseOpenPositions"
                                checked={reuseOpenPositions}
                                onChange={onCheckboxChange}
                            />
                            Reuse Current Open Positions
                        </label>
                    </div>
                    <button type="submit">Create Scenario</button>
                </form>
            </div>
        </div>
    );
};

export default HCForm;
