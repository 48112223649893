import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import colors from '../../assets/colors';
import "../../styles/Home.css";
import "../../styles/Card.css";
import Tableau from "../../components/recrutement/Tableau";
import UpdatePositionForm from "../../components/form/UpdatePositionForm";
import CreatePositionForm from "../../components/form/CreatePositionForm";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleGroup, faCheckCircle, faClipboardList, faBriefcase, faUserCheck, faPlus } from '@fortawesome/free-solid-svg-icons';
import amplitude from 'amplitude-js';

const EquipeStaff = () => {
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [counts, setCounts] = useState({});
    const [selectedPost, setSelectedPost] = useState(null);
    const [updateInfo, setUpdateInfo] = useState({ status: '', salary_min: '', salary_max: '', arrival_month: '' });
    const [openPositions, setOpenPositions] = useState([]);
    const [managers, setManagers] = useState([]);
    const [error, setError] = useState(null);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [newPositionInfo, setNewPositionInfo] = useState({ job: '', salary_min: '', salary_max: '', arrival_month: '', location: '', contact_manager: '' });
    const [teamName, setTeamName] = useState('');

    const { id } = useParams();
    const id_team= id;
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = localStorage.getItem('token');

    // Colonnes par défaut
    const defaultVisibleColumns = ['job', 'team', 'manager_name', 'status', 'salary_max'];

    // Charger les colonnes visibles depuis localStorage ou utiliser les colonnes par défaut
    const loadVisibleColumns = () => {
        try {
            const storedColumnsDiff = localStorage.getItem('visibleColumns_/staffingplan/team');

            if (storedColumnsDiff) {
                const differences = JSON.parse(storedColumnsDiff);

                if (Array.isArray(differences)) {
                    // Si 'differences' est un tableau, nous assumons que ce sont les colonnes à ajouter
                    return differences;
                } else if (differences.added && differences.removed) {
                    // Gérer le cas où 'differences' est un objet avec 'added' et 'removed'
                    return defaultVisibleColumns
                        .filter(col => !differences.removed.includes(col))
                        .concat(differences.added);
                }
            }
        } catch (error) {
            console.error("Error parsing stored visible columns:", error);
        }
        return defaultVisibleColumns; // Retour aux colonnes par défaut en cas d'erreur ou de données inattendues
    };
   

    const [visibleColumns, setVisibleColumns] = useState(loadVisibleColumns());



    const allColumns = [
        { key: 'job', label: 'Job' },
        { key: 'nom_equipe', label: 'Team' },
        { key: 'manager_name', label: 'Manager' },
        { key: 'status', label: 'Status' },
        { key: 'salary_max', label: 'Salary Max' },
        { key: 'salary_min', label: 'Salary Min' },
        { key: 'location', label: 'Location' },
        { key: 'contract', label: 'Contract' },
        { key: 'agency', label: 'Agency Involved' },
        { key: 'priority', label: 'Priority' },
        { key: 'recruiter_name', label: 'Recruiter' },
        { key: 'job_opening_date', label: 'Job Opening Date' },
        { key: 'arrival_month', label: 'Arrival Month' },
        { key: 'exact_arrival_date', label: 'Exact Arrival Date' },
        { key: 'comments', label: 'Comments' },
        { key: 'FORECAST_arrival_month', label: 'FORECAST Arrival Month' },
        { key: 'FORECAST_arrival_month_init', label: 'FORECAST Arrival Month Initial' },
        { key: 'FORECAST_job_opening_date', label: 'FORECAST Opening Date' },
        { key: 'employe_hired_name', label: 'Employe hired' },
        { key: 'future_employee_email', label: 'Future email' },

    ];

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });

        const sorted = [...openPositions].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'asc' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'asc' ? 1 : -1;
            }
            return 0;
        });

        setOpenPositions(sorted);
    };

    const handleRowClick = (item) => {
        setSelectedPost(item);

        setUpdateInfo({ 
            status: item.status, 
            team: item.team,
            salary_min: item.salary_min, 
            salary_max: item.salary_max, 
            arrival_month: item.arrival_month,
            location: item.location,
            contact_manager: item.contact_manager,
            job_opening_date: item.job_opening_date,
            contract: item.contract,
            agency: item.agency,
            priority: item.priority,
            comments: item.comments,
            FORECAST_arrival_month: item.FORECAST_arrival_month,
            
            FORECAST_job_opening_date: item.FORECAST_job_opening_date,
            exact_arrival_date: item.exact_arrival_date,
            employe_hired: item.employe_hired,
            employe_hired_name: item.employe_hired_name,
            future_employee_email: item.future_employee_email,

            recruiter: item.recruiter,
            manager_name: item.manager_name,
            recruiter_name: item.recruiter_name,
            
        });


        // Log the click event in Amplitude
        amplitude.getInstance().logEvent('OpenPositionClicked', {
            job: item.job,
            status: item.status,
            team: item.team,
            salary_max: item.salary_max,
            username: localStorage.getItem('username') || 'unknown',
        });
    };

    const handleBackClick = () => {
        setSelectedPost(null);
        setShowCreateForm(false);
    };

    const handleSelectChange = (e) => {
        const { name, value } = e.target;
        setUpdateInfo(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const cleanData = (data) => {
        const cleanedData = { ...data };
        Object.keys(cleanedData).forEach(key => {
            if (cleanedData[key] === '') {
                cleanedData[key] = null;
            }
        });
        return cleanedData;
    };
    const handleDuplicate = async (item) => {
        const createUrl = `${apiUrl}/openposition/`;
        const userData = JSON.parse(localStorage.getItem('userData'));
        const entrepriseId = userData?.entreprise_id || null; 

        // Prepare the data for the new position, copying from the existing item
        const newPositionData = {
            job: item.job,
            team: id_team, // Assuming item.team is an object with an id
            contact_manager: item.contact_manager,
            recruiter: item.recruiter,
            job_opening_date: item.job_opening_date,
            arrival_month: item.arrival_month,
            FORECAST_arrival_month: item.FORECAST_arrival_month,
            FORECAST_arrival_month_init: item.FORECAST_arrival_month_init,
            FORECAST_job_opening_date: item.FORECAST_job_opening_date,
            FORECAST_job_opening_date_init: item.FORECAST_job_opening_date_init,
            entreprise: entrepriseId,
            // Copy other necessary fields
            salary_min: item.salary_min,
            salary_max: item.salary_max,
            location: item.location,
            contract: item.contract,
            agency: item.agency,
            priority: item.priority,
            comments: item.comments,
            exact_arrival_date: item.exact_arrival_date,
            status: item.status,
            employe_hired: item.employe_hired,
            future_employee_email: item.future_employee_email,
            // Exclude fields like 'id' and any others that should not be duplicated
        };

       

        // Clean the data to replace empty strings with null if necessary
        const cleanedData = cleanData(newPositionData);

        try {
            const response = await fetch(createUrl, {
                credentials: 'include',
                method: 'POST',
                headers: {
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(cleanedData),
            });

            if (!response.ok) {
                throw new Error('Failed to duplicate position');
            }

            const createdPost = await response.json();

            // Update the openPositions state to include the new position
            setOpenPositions(prevData => [...prevData, createdPost]);

            // Optionally, you can show a success message or notification here
        } catch (error) {
            console.error('Error duplicating position:', error);
            setError('Failed to duplicate position. Please try again later.');
        }
    };



    

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        if (!selectedPost) return;

        const updateUrl = `${apiUrl}/openposition/${selectedPost.id}/`;


        const updatedData = {
            status: updateInfo.status,
            job: updateInfo.job,
            salary_min: updateInfo.salary_min,
            salary_max: updateInfo.salary_max,
            id: selectedPost.id,
            team: selectedPost.team.id,
            location: updateInfo.location,
            contact_manager: updateInfo.contact_manager,
            arrival_month: updateInfo.arrival_month,
            contract: updateInfo.contract,
            recruiter: updateInfo.recruiter,
            agency: updateInfo.agency,
            priority: updateInfo.priority,
            comments: updateInfo.comments,
            FORECAST_arrival_month: updateInfo.FORECAST_arrival_month,
            FORECAST_job_opening_date: updateInfo.FORECAST_job_opening_date,
            exact_arrival_date: updateInfo.exact_arrival_date,
            employe_hired: updateInfo.employe_hired,
            future_employee_email: updateInfo.future_employee_email,
            
        };


        const cleanedData = cleanData(updatedData);

        try {
            const response = await fetch(updateUrl, {
                credentials: 'include',
                method: 'PUT',
                headers: {
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(cleanedData),
            });

            if (!response.ok) {
                throw new Error('Failed to update data');
            }

            const updatedPost = await response.json();
            setOpenPositions(prevData => prevData.map(item => item.id === updatedPost.id ? updatedPost : item));
            setSelectedPost(null);
        } catch (error) {
            console.error('Error updating position:', error);
            setError('Failed to update data. Please try again later.');
        }
    };

    const handleCreateChange = (e) => {
        const { name, value } = e.target;
        setNewPositionInfo(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleCreateSubmit = async (e) => {
        e.preventDefault();
        const createUrl = `${apiUrl}/openposition/`;
        const userData = JSON.parse(localStorage.getItem('userData'));
        const entrepriseId = userData?.entreprise_id || null; 

        const newPositionData = {
            ...newPositionInfo,
            team: id,  // Use the team ID from the URL
            contact_manager: newPositionInfo.contact_manager,
            recruiter: newPositionInfo.recruiter,
            job_opening_date: newPositionInfo.job_opening_date,
            arrival_month: newPositionInfo.arrival_month,
            FORECAST_arrival_month: newPositionInfo.arrival_month,
            FORECAST_arrival_month_init: newPositionInfo.arrival_month,
            FORECAST_job_opening_date: newPositionInfo.job_opening_date,
            FORECAST_job_opening_date_init: newPositionInfo.job_opening_date,
            entreprise: entrepriseId, 
        };

        try {
            const response = await fetch(createUrl, {
                credentials: 'include',
                method: 'POST',
                headers: {
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newPositionData),
            });

            if (!response.ok) {
                throw new Error('Failed to create new position');
            }

            const createdPost = await response.json();
            setOpenPositions(prevData => [...prevData, createdPost]);
            setNewPositionInfo({ job: '', status: '', salary_min: '', salary_max: '', arrival_month: '', team: '', location: '', contact_manager: '', recruiter: '' });
            setShowCreateForm(false);
        } catch (error) {
            console.error('Error creating position:', error);
            setError('Failed to create new position. Please try again later.');
        }
    };

    const handleDelete = async (id) => {
        const deleteUrl = `${apiUrl}/openposition/${id}/`;

        try {
            const response = await fetch(deleteUrl, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Token ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Failed to delete data');
            }

            setOpenPositions(prevData => prevData.filter(item => item.id !== id));
            setSelectedPost(null); // Close the form after deletion
        } catch (error) {
            console.error('Error deleting position:', error);
            setError('Failed to delete data. Please try again later.');
        }
    };

    const fetchOpenPositions = useCallback(async () => {
        const positionsUrl = `${apiUrl}/openposition/?team=${id}`;

        try {
            const response = await fetch(positionsUrl, {

                method: 'GET',
                headers: {
                    'Authorization': `Token ${token}`,
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            setOpenPositions(data);

            const now = new Date().toISOString();
            const dataToStore = {
                data: data,
                lastUpdated: now
            };
            localStorage.setItem('OpenPositions', JSON.stringify(dataToStore));

            const formattedData = data.map(item => ({
                id: item.id,
                job: item.job,
                team: { id: item.team_id, name: item.nom_equipe },
                nom_equipe: item.nom_equipe,
                location: item.location,
                contact_manager: item.contact_manager,
                recruiter: item.recruiter,
                manager_name: item.manager_name,
                recruiter_name: item.recruiter_name,
                status: item.status,
                arrival_month: item.arrival_month,
                salary_min: item.salary_min,
                salary_max: item.salary_max,
                contract: item.contract,
                agency: item.agency,
                priority: item.priority,
                job_opening_date: item.job_opening_date,
                exact_arrival_date: item.exact_arrival_date,
                comments: item.comments,
                FORECAST_arrival_month: item.FORECAST_arrival_month,
                FORECAST_arrival_month_init: item.FORECAST_arrival_month_init,
                FORECAST_job_opening_date: item.FORECAST_job_opening_date,
                employe_hired_name: item.employe_hired_name,
                future_employee_email: item.future_employee_email,
            }));
            setOpenPositions(formattedData);

            if (data.length > 0) {
                setTeamName(data[0].nom_equipe);
            }

            const uniqueManagers = data
                .filter(item => item.contact_manager)
                .map(item => ({
                    id: item.contact_manager.id,
                    name: item.contact_manager.name
                }));
            setManagers(uniqueManagers);

        } catch (error) {
            console.error('Error fetching positions:', error);
            setError('Failed to load data. Please try again later.');
        }
    }, [apiUrl, id, token]);

    const fetchStatusCounts = useCallback(async () => {
        const statusUrl = `${apiUrl}/openposition/group_by_statut/?team=${id}`;

        try {
            const response = await fetch(statusUrl, {

                method: 'GET',
                headers: {
                    'Authorization': `Token ${token}`,
                },
            });
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            const counts = data.reduce((acc, item) => {
                acc[item.status] = item.number;
                return acc;
            }, {});
            setCounts(counts);
        } catch (error) {
            console.error('Error fetching status counts:', error);
            setError('Failed to load data. Please try again later.');
        }
    }, [apiUrl, id, token]);

    useEffect(() => {
        fetchOpenPositions();
        fetchStatusCounts();
    }, [fetchOpenPositions, fetchStatusCounts, id]);

    const formatDate = (value) => {
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };
    // EquipeStaff.js

const handleStatusChange = async (itemId, newStatus) => {
    const updateUrl = `${apiUrl}/openposition/${itemId}/`;

    const updatedData = {
        status: newStatus,
    };

    try {
        const response = await fetch(updateUrl, {
            credentials: 'include',
            method: 'PATCH', // Utilisez PATCH pour une mise à jour partielle
            headers: {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(updatedData),
        });

        if (!response.ok) {
            throw new Error('Failed to update status');
        }

        const updatedPost = await response.json();
        // Mettez à jour l'état openPositions
        setOpenPositions(prevData => prevData.map(item => item.id === updatedPost.id ? updatedPost : item));
    } catch (error) {
        console.error('Error updating status:', error);
        setError('Failed to update status. Please try again later.');
    }
};


    const getCellColorClass = (key, value) => {
        if (key === 'status') {
            switch (value) {
                case '0.Not Started':
                    return 'status-not-started';
                case '1.Job Opened':
                    return 'status-job-opened';
                case '6.Offer Accepted':
                    return 'status-offer-accepted';
                case '7.Arrived':
                    return 'status-arrived';
                default:
                    return '';
            }
        }
        return '';
    };

    // Update visible columns and save to localStorage
    const handleVisibleColumnsChange = (columns) => {
        setVisibleColumns(columns);

    };

    return (
        <div style={{ backgroundColor: colors.background }} className='container-home'>
            <Sidebar />
            <div className="container-right">
                <Header />
                {showCreateForm ? (
                    <CreatePositionForm
                        newPositionInfo={newPositionInfo}
                        handleCreateChange={handleCreateChange}
                        handleCreateSubmit={handleCreateSubmit}
                        handleBackClick={handleBackClick}
                        setSortedData={setOpenPositions}
                        setShowCreateForm={setShowCreateForm}
                        setNewPositionInfo={setNewPositionInfo}
                        setError={setError}
                        managers={managers} // Pass managers to the form
                    />
                ) : selectedPost ? (
                    <UpdatePositionForm
                        selectedPost={selectedPost}
                        updateInfo={updateInfo}
                        setUpdateInfo={setUpdateInfo} // Ensure this is passed as a prop
                        handleSelectChange={handleSelectChange}
                        handleFormSubmit={handleFormSubmit}
                        handleBackClick={handleBackClick}
                        handleDelete={handleDelete} // Add handleDelete prop
                    />
                ) : (
                    <>
                        <div className='line-container-10'>
                            <h4>Open Positions for the {teamName} Team</h4>
                            <div className='cards-container'>
                                <div className="status-card">
                                    <div className="status-left">
                                        <div className="status-icon status-not-started">
                                            <FontAwesomeIcon icon={faClipboardList} />
                                        </div>
                                        <div className="status-label">Not Started</div>
                                    </div>
                                    <div className="status-count">{counts['0.Not Started'] || 0}</div>
                                </div>

                                <div className="status-card">
                                    <div className="status-left">
                                        <div className="status-icon status-job-opened">
                                            <FontAwesomeIcon icon={faBriefcase} />
                                        </div>
                                        <div className="status-label">Job Opened</div>
                                    </div>
                                    <div className="status-count">{counts['1.Job Opened'] || 0}</div>
                                </div>

                                <div className="status-card">
                                    <div className="status-left">
                                        <div className="status-icon status-offer-accepted">
                                            <FontAwesomeIcon icon={faUserCheck} />
                                        </div>
                                        <div className="status-label">Offer Accepted</div>
                                    </div>
                                    <div className="status-count">{counts['6.Offer Accepted'] || 0}</div>
                                </div>

                                <div className="status-card">
                                    <div className="status-left">
                                        <div className="status-icon status-arrived">
                                            <FontAwesomeIcon icon={faCheckCircle} />
                                        </div>
                                        <div className="status-label">Arrived</div>
                                    </div>
                                    <div className="status-count">{counts['7.Arrived'] || 0}</div>
                                </div>
                            </div>
                            <div>
                                <button onClick={() => setShowCreateForm(true)} type='submit'>
                                    <FontAwesomeIcon icon={faPlus} /> Add Position
                                </button>
                            </div>



                    <Tableau
                        data={openPositions}
                        columns={allColumns}
                        visibleColumns={visibleColumns}
                        setVisibleColumns={handleVisibleColumnsChange}
                        sortConfig={sortConfig}
                        onSort={handleSort}
                        onRowClick={handleRowClick}
                        onDuplicateClick={handleDuplicate}
                        onStatusChange={handleStatusChange} // Ajoutez cette prop
                    />


                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default EquipeStaff;
