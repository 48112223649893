import React, { useState } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import ChartKPICard from '../../../components/cards/ChartKPICard';
import TableComponent from '../../../components/recrutement/chartv2/TableComponent';
import ToggleBtnChart from '../../../components/buttons/ToggleBtnChart';
import { faDashboard, faLineChart, faPeopleGroup } from '@fortawesome/free-solid-svg-icons';

const ChartWithKPIsComponent = ({
  title,
  kpiDataArray,
  chartType = 'line', // 'line' ou 'bar'
  chartData,
  chartOptions,
  historiqueTableData,
  showChangeKpi,
  titletable,
  datatable,
  columnstable,
  showToggle = false, // Nouveau paramètre, par défaut à `false`
}) => {
  const [view, setView] = useState('chart'); // 'chart' ou 'table'
  const ChartComponent = chartType === 'line' ? Line : Bar;

  const handleToggleView = (option) => {
    setView(option === 'Graph' ? 'chart' : 'table');
  };

  return (
    <div className="card-full margin-b-40">
      {/* Toggle Button */}
      {showToggle && (
        <ToggleBtnChart
          optionLeft="Graph" // Affiche le graphique
          optionRight="Employes" // Affiche le tableau
          onToggle={handleToggleView}
          selectedOption={view === 'chart' ? 'Graph' : 'Employes'}
          iconleft={faLineChart}
          iconright={faPeopleGroup}
        />
      )}

      <h3>{view === 'chart' ? title : titletable}</h3>

      {/* KPIs */}
      {view === 'chart' && (
        <div className="display-line">
          {kpiDataArray.map((kpiData, index) => (
            <ChartKPICard
              key={index}
              icon={kpiData.icon}
              color={kpiData.color}
              title={kpiData.title}
              value={kpiData.value}
              change={kpiData.change}
              period={kpiData.period}
              groupOption={kpiData.groupOption}
            />
          ))}
        </div>
      )}

      {/* Chart or Table */}
      <div className="chart-container">
        {view === 'chart' && chartData && (
          <ChartComponent data={chartData} options={chartOptions} />
        )}
        {view === 'table' && datatable && (
          <TableComponent
            title={titletable}
            columns={columnstable}
            data={datatable}
            exportToCSV={() => {
              console.log('Export CSV non implémenté');
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ChartWithKPIsComponent;
