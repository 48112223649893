import React, { useState, useEffect } from 'react';
import InsightCard from '../../cards/InsightCard'; // Assurez-vous du bon chemin d'importation
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';


const InsightSalaryDistribution = () => {
    const [salaryData, setSalaryData] = useState({averageSalaryMen: 0, averageSalaryWomen: 0 });
    const [lastRefreshDate, setLastRefreshDate] = useState('');

    
    const fetchData = async () => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL;
            const token = localStorage.getItem('token');

            const response = await fetch(`${apiUrl}/api/insight/salary-distribution/`, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Erreur lors de la récupération des données de distribution de risque');
            }

            const data = await response.json();
            const now = new Date().toISOString();
            const dataToStore = {
                data: data,
                lastUpdated: now
            };

            localStorage.setItem('InsightSalaryDistribution', JSON.stringify(dataToStore));
            setSalaryData(data);
            setLastRefreshDate(now);
        } catch (error) {
            console.error('Erreur lors de la récupération des données de distribution de risque', error);
        }
    };

    useEffect(() => {
        const storedData = localStorage.getItem('InsightSalaryDistribution');
        if (storedData) {
            const { data, timestamp } = JSON.parse(storedData);
            setSalaryData(data);
            setLastRefreshDate(timestamp);
        } else {
            fetchData();
        }
    }, []);

    const chartData = {
        labels: ['Hommes', 'Femmes'],
        datasets: [{
            label: 'Salaire moyen',
            data: [salaryData.averageSalaryMen, salaryData.averageSalaryWomen],
            backgroundColor: [
                'rgba(75, 192, 192, 0.6)',
                'rgba(255, 206, 86, 0.6)'
            ],
            borderColor: [
                'rgba(75, 192, 192, 1)',
                'rgba(255, 206, 86, 1)'
            ],
            borderWidth: 1
        }]
    };

    const chartOptions = {
        indexAxis: 'y', // Passe le graphique en format horizontal
        scales: {
            x: {
                display: false, // Cache les graduations et le fond de l'axe X
                grid: {
                    display: false, // Cache les lignes de grille sur l'axe X
                }
            },
            y: {
                beginAtZero: true,
                grid: {
                    display: false, // Cache les lignes de grille sur l'axe Y
                }
            }
        },
        responsive: true,
        plugins: {
            legend: {
                display: false // Cache la légende
            }
        },
        elements: {
            bar: {
                borderWidth: 0, // Cache les bordures des barres si nécessaire
            }
        }
    };

    // Préparation du graphique en barres comme un élément React
    const barChart = (
        <Bar data={chartData} options={chartOptions} height={110} width={300} />
    );

    // Mise à jour du subtext pour afficher le nombre d'employés à risque élevé

    const ecartSalarialPourcentage = ((salaryData.averageSalaryMen - salaryData.averageSalaryWomen) / salaryData.averageSalaryWomen) * 100;

    const updatedSubtext = `Écart salarial = ${ecartSalarialPourcentage.toFixed(2)}%`;


    return (
        <InsightCard
            text="Distribution des salaires par sexe"
            subtext={updatedSubtext}
            data={barChart}

        />
    );
};

export default InsightSalaryDistribution;
