import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom'; // Assurez-vous d'importer useNavigate

const ImportComponent = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [isStarted, setIsStarted] = useState(false);
  const navigate = useNavigate(); // Hook pour la navigation
  const stepsDetails = [
    { description: "Collecte des données démographiques", duration: 1900 },
    { description: "Analyse de l'ancienneté des employés", duration: 900 },
    { description: "Évaluation des performances", duration: 800 },
    { description: "Évaluation du potentiel", duration: 500 },
    { description: "Incorporation des retours d'évaluations", duration: 400 },
    { description: "Changements dans l'équipe.", duration: 2100 },
    { description: "Prise en compte des facteurs externes", duration: 800 },
    { description: "Injection des datas historiques", duration: 4000 },
    { description: "Modélisation des probabilités de départ", duration: 900 },
    { description: "Optimisation du modèle et ajustements", duration: 1000 }
  ];
  const totalSteps = stepsDetails.length;

  useEffect(() => {
    let timer;
    if (isStarted && currentStep < totalSteps) {
      timer = setTimeout(() => {
        setCompletedSteps(prev => [...prev, currentStep + 1]);
        setCurrentStep(currentStep + 1);
      }, stepsDetails[currentStep].duration);
    } else if (currentStep === totalSteps) {
      setIsStarted(false);
    }
    return () => clearTimeout(timer);
  }, [currentStep, isStarted]);

  const startProcess = () => {
    if (!isStarted) {
      setIsStarted(true);
      setCurrentStep(0);
      setCompletedSteps([]);
    }
  };

  const goToResults = () => {
    navigate('/insight/risquedepart'); // Naviguer vers la page de résultats
  };

  return (
    <div className='card-full'>
        <h4>Merci pour l'import des données vous pouvez désormais utiliser ces datas prévoir le départ de vos collaborateurs</h4>
      <div>
        {completedSteps.map(step => (
          <div key={step}>{stepsDetails[step - 1].description} <FontAwesomeIcon icon={faCheckCircle} className="success-icon" /></div>
        ))}
        {isStarted && currentStep < totalSteps && (
          <div>
            {stepsDetails[currentStep].description} <FontAwesomeIcon icon={faSpinner} className="spinner-icon" spin />
          </div>
        )}
      </div>
      {currentStep === totalSteps && (
        <>
          <div>Simulation complète du modèle de prévision de turnover.</div>
          <button type="submit" onClick={goToResults}>Découvrir le résultat</button>
        </>
      )}
      {!isStarted && <button type="submit" onClick={startProcess}>Lancer la simulation</button>}
    </div>
  );
};

export default ImportComponent;
