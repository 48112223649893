import React, { useState } from 'react';
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import colors from '../../assets/colors';
import "../../styles/Home.css";
import "../../styles/Tree.css";

const GenerateSandbox = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = localStorage.getItem('token');

  const [companyName, setCompanyName] = useState('');
  const [teamsData, setTeamsData] = useState([
    { team_name: '', num_histories: 0, num_open_positions: 0 },
  ]);
  const [currentStep, setCurrentStep] = useState(1);
  const [error, setError] = useState(null);
  const [allDataLoading, setAllDataLoading] = useState(false);

  const handleGenerate = async () => {
    setAllDataLoading(true);
    setError(null);

    try {
      const response = await fetch(`${apiUrl}/api/sandbox/generate/`, {
        method: 'POST',
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          company_name: companyName,
          teams_data: teamsData,
        }),
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la génération du sandbox');
      }
      const jsonData = await response.json();
      console.log('Sandbox généré avec succès :', jsonData);

      alert('Sandbox généré avec succès.');
      // Réinitialiser les états
      setCompanyName('');
      setTeamsData([{ team_name: '', num_histories: 0, num_open_positions: 0 }]);
      setCurrentStep(1);

    } catch (error) {
      console.error('Erreur lors du fetch des données :', error);
      setError(error);
      alert('Une erreur est survenue lors de la génération du sandbox.');
    } finally {
      setAllDataLoading(false);
    }
  };

  return (
    <div style={{ backgroundColor: colors.background }} className='container-home'>
      <Sidebar />
      <div className="container-right">
        <Header />
        <div style={{ padding: '20px' }}>
          {currentStep === 1 && (
            <div className='card-full'>
              <h4>Étape 1 : Entrez le nom de l'entreprise</h4>
              <label htmlFor="companyName">Nom de l'entreprise</label>
              <input
                id="companyName"
                type="text"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                placeholder="Nom de l'entreprise"
              />
              <button
                onClick={() => {
                  if (companyName.trim() !== '') {
                    setCurrentStep(2);
                  } else {
                    alert("Veuillez entrer le nom de l'entreprise.");
                  }
                }}
              >
                Suivant
              </button>
            </div>
          )}

          {currentStep === 2 && (
            <div className='card-full'>
              <h4>Étape 2 : Entrez les détails des équipes</h4>
              {teamsData.map((team, index) => (
                <div className="line-container-10" key={index} style={{ marginBottom: '20px', borderBottom: '1px solid #ccc', paddingBottom: '10px' }}>
                  <h5>Équipe {index + 1}</h5>
                    <div className='display-line'> 
                  <label htmlFor={`teamName-${index}`}>Nom de l'équipe</label>
                  <input
                    id={`teamName-${index}`}
                    type="text"
                    value={team.team_name}
                    onChange={(e) => {
                      const newTeamsData = [...teamsData];
                      newTeamsData[index].team_name = e.target.value;
                      setTeamsData(newTeamsData);
                    }}
                    placeholder="Nom de l'équipe"
                  />
                  </div>
                  <div className='display-line'> 
                  <label htmlFor={`numHistories-${index}`}>Nombre d'historiques d'emploi</label>
                  <input
                    id={`numHistories-${index}`}
                    type="number"
                    value={team.num_histories}
                    onChange={(e) => {
                      const newTeamsData = [...teamsData];
                      newTeamsData[index].num_histories = parseInt(e.target.value, 10);
                      setTeamsData(newTeamsData);
                    }}
                    placeholder="Nombre d'historiques d'emploi"
                    min="0"
                  />
                </div>
                <div className='display-line'> 
                  <label htmlFor={`numOpenPositions-${index}`}>Nombre de postes ouverts</label>
                  <input
                    id={`numOpenPositions-${index}`}
                    type="number"
                    value={team.num_open_positions}
                    onChange={(e) => {
                      const newTeamsData = [...teamsData];
                      newTeamsData[index].num_open_positions = parseInt(e.target.value, 10);
                      setTeamsData(newTeamsData);
                    }}
                    placeholder="Nombre de postes ouverts"
                    min="0"
                  />
                </div>
                  {/* Bouton pour supprimer une équipe */}
                  {teamsData.length > 1 && (
                    <button 
                    className='btn-secondary'
                      onClick={() => {
                        const newTeamsData = teamsData.filter((_, i) => i !== index);
                        setTeamsData(newTeamsData);
                      }}
                    >
                      Supprimer cette équipe
                    </button>
                  )}
                </div>
              ))}

              {/* Bouton pour ajouter une nouvelle équipe */}
              <button
              className='btn-secondary'
                onClick={() => {
                  setTeamsData([...teamsData, { team_name: '', num_histories: 0, num_open_positions: 0 }]);
                }}
              >
                Ajouter une autre équipe
              </button>

              {/* Bouton pour lancer la génération */}
              <div style={{ marginTop: '20px' }}>
                <button
                type="submit"
                  onClick={() => {
                    // Vérifier que toutes les équipes ont un nom
                    const allTeamsNamed = teamsData.every(team => team.team_name.trim() !== '');
                    if (!allTeamsNamed) {
                      alert("Veuillez entrer le nom de toutes les équipes.");
                      return;
                    }
                    handleGenerate();
                  }}
                  disabled={allDataLoading} // Désactiver le bouton pendant le chargement
                >
                  {allDataLoading ? 'Génération en cours...' : 'Générer le Sandbox'}
                </button>
              </div>
            </div>
          )}

          {error && <p style={{ color: 'red' }}>Erreur : {error.message}</p>}
        </div>
      </div>
    </div>
  );
};

export default GenerateSandbox;
