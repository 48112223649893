import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';

const TableComponent = ({
  title,
  columns,
  data,
  additionalButtons = [],
  itemsPerPage = 10,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  // Calculer le nombre total de pages
  const totalPages = Math.ceil(data.length / itemsPerPage);

  // Récupérer les données de la page actuelle
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentPageData = data.slice(startIndex, startIndex + itemsPerPage);

  // Fonction pour exporter les données en CSV
  const exportToCSV = () => {
    const csvRows = [];
    const header = columns.map((col) => col.label).join(',');
    csvRows.push(header);

    data.forEach((row) => {
      const values = columns.map((col) => {
        const cell = row[col.key];
        return `"${cell || ''}"`; // Échapper les valeurs et gérer les null/undefined
      });
      csvRows.push(values.join(','));
    });

    const csvContent = csvRows.join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${title || 'export'}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <div>
      <div className="table-actions">
        <button onClick={exportToCSV} className="btn-secondary">
          <FontAwesomeIcon icon={faFileCsv} /> Export to CSV
        </button>
        {additionalButtons.map((button, index) => (
          <button
            key={index}
            onClick={button.onClick}
            className={button.className}
          >
            {button.icon && <FontAwesomeIcon icon={button.icon} />} {button.label}
          </button>
        ))}
      </div>

      <table className="table-custom">
        <thead>
          <tr>
            {columns.map((col, index) => (
              <th key={index}>{col.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentPageData.length > 0 ? (
            currentPageData.map((rowData, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((col, colIndex) => (
                  <td key={colIndex}>{rowData[col.key]}</td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length} className="no-data">
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className="table-pagination">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="btn-secondary"
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="btn-secondary"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default TableComponent;
