import React from 'react';

const ReloadData = () => {
  const handleDownload = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const token = localStorage.getItem('token');
      const response = await fetch(`${apiUrl}/api/download-data/`, {
        headers: {
          'Authorization': `Token ${token}`
        }
      });

      if (response.ok) {
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        const fileName = "liste_employes.csv"; // Nom du fichier tel que configuré côté serveur
        link.href = downloadUrl;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else {
        throw new Error('Erreur lors du téléchargement du fichier');
      }
    } catch (error) {
      console.error("Erreur lors du téléchargement du fichier CSV :", error);
      alert("Erreur lors du téléchargement du fichier CSV.");
    }
  };

  return (
    <div>
      <h2>Télécharger la liste des employés</h2>
      <button onClick={handleDownload}>Télécharger le fichier CSV</button>
    </div>
  );
};

export default ReloadData;
